import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import ReceiptIcon from "@mui/icons-material/Receipt"
import TuneIcon from "@mui/icons-material/Tune"
import { Alert, Button, Grid, TextField } from "@mui/material"
import { enqueueSnackbar } from "notistack"

import {
	GetAllCustomerInvoicingParams,
	generateInvoice,
	getAllCustomerInvoicing,
} from "../../../apis/invoicing"
import { BodyPage, ContainerPage } from "../../../components/container/container.styles"
import { HeaderPage } from "../../../components/header/page/header-page"
import { Table } from "../../../components/table/table"
import { invoiceStatusOptions } from "../../../constants/options/invoice-status"
import { pathNames } from "../../../routes/paths"
import { styles } from "../../../styles/common"
import { handleApiExceptions } from "../../../utils/handle-errors"
import { InvoiceActionModal, InvoiceActionModalDataProps } from "./components/action-modal"

type DataTableInvoiceProps = {
	organizationName: string
	consultationQty: string
	organizationId: string
	referenceDate: string
	invoiceId: string
	status: string
	cost: number
}

export function InvoicingPage() {
	const navigate = useNavigate()

	const { t } = useTranslation()

	const [rows, setRows] = useState<DataTableInvoiceProps[]>([])

	const [isSeachingData, setIsSeachingData] = useState<boolean>(true)

	const [isOpenModalFilter, setIsOpenModalFilter] = useState<boolean>(false)
	const [isOpenInvoiceGenModal, setIsOpenInvoiceGenModal] = useState<boolean>(false)

	const [paginationQueries, setPaginationQueries] = useState<GetAllCustomerInvoicingParams>({
		currentPage: 1,
		pageSize: 10,
	})

	const keys = useMemo(
		() => [
			{
				isLink: true,
				value: "organizationName",
				id: "organizationId",
				path: pathNames.organization.edit,
				backPath: pathNames.consumption.invoicing,
			},
			"referenceDate",
			"consultationQty",
			{ isMoney: true, value: "cost", id: "cost" },
			{ isPill: true, value: "status", id: "status" },
		],
		[rows],
	)

	const columns = useMemo(
		() => [
			{
				label: t("columns.customer"),
				id: "organizationName",
			},
			{
				label: t("columns.month_reference"),
				id: "referenceDate",
			},
			{
				label: t("columns.search_amount"),
				id: "consultationQty",
			},
			{
				label: t("columns.billing_value"),
				id: "cost",
			},
			{
				label: t("columns.status"),
				id: "status",
			},
		],
		[rows, t],
	)

	const statusColors = {
		INVOICED: "warning",
		APPROVED_OPERATIONAL: "info",
		APPROVED_FINANCE: "success",
		APPROVED: "success",
	}

	async function tryGetAllCustomerInvoicing() {
		try {
			setIsSeachingData(true)
			const { data } = await getAllCustomerInvoicing(paginationQueries)
			if (data.list.length) {
				const response = data.list.map((invoice) => ({
					consultationQty: new Intl.NumberFormat("pt-BR").format(invoice.monthlyData.total),
					cost: +invoice.monthlyData.price,
					status: invoiceStatusOptions.getLabel(invoice.status),
					statusPillColor: statusColors[invoice.status as keyof typeof statusColors],
					organizationName: invoice.organization.name,
					organizationId: invoice.organizationId,
					referenceDate: `${t(`month.${invoice.month}`)}/${invoice.year}`,
					invoiceId: invoice.id,
				}))
				setPaginationQueries((prevState) => ({ ...prevState, totalItems: data.total }))
				setRows(() => response)
			} else setRows(() => [])
		} catch (error) {
			handleApiExceptions(error)
		} finally {
			setIsSeachingData(false)
		}
	}

	const handleChangeRowsPerPage = (pageSize: number) =>
		setPaginationQueries((prevState) => ({ ...prevState, pageSize }))

	const handleChangePage = (currentPage: number) =>
		setPaginationQueries((prevState) => ({ ...prevState, currentPage: currentPage + 1 }))

	const handleCloseFilterModal = () => setIsOpenModalFilter((isOpen) => !isOpen)

	const handleEditInvoice = ({ invoiceId }: DataTableInvoiceProps) => navigate(invoiceId)

	const handleFilterChanges = (filters: InvoiceActionModalDataProps) => {
		let parsedFilters = {}
		if (filters.organizationId) parsedFilters = { organizationId: filters.organizationId }
		if (filters.date) {
			const month = new Date(filters.date).getMonth() + 1
			const year = new Date(filters.date).getFullYear()
			parsedFilters = {
				...parsedFilters,
				month,
				year,
			}
		}
		setPaginationQueries((prevState) => ({ ...prevState, ...parsedFilters }))
		setIsOpenModalFilter((isOpen) => !isOpen)
	}

	const handleClearFilter = () => {
		setPaginationQueries((prevState) => ({
			...prevState,
			organizationId: undefined,
			month: undefined,
			year: undefined,
		}))
		setIsOpenModalFilter((isOpen) => !isOpen)
	}

	const handleInvoiceGeneration = async (filters: InvoiceActionModalDataProps) => {
		if (!filters.organizationId || !filters.date) {
			enqueueSnackbar("Preencha todos os campos para gerar a fatura", { variant: "error" })
			return
		}

		try {
			setIsSeachingData(true)
			setIsOpenInvoiceGenModal((isOpen) => !isOpen)
			await generateInvoice(
				filters.organizationId,
				new Date(filters.date).getMonth() + 1,
				new Date(filters.date).getFullYear(),
			)
			await tryGetAllCustomerInvoicing()
			enqueueSnackbar("Fatura gerada com sucesso", { variant: "success" })
		} catch (error) {
			enqueueSnackbar("Erro ao gerar fatura", { variant: "error" })
		} finally {
			setIsSeachingData(false)
		}
	}

	useEffect(() => {
		tryGetAllCustomerInvoicing()
	}, [
		paginationQueries.organizationId,
		paginationQueries.currentPage,
		paginationQueries.month,
		paginationQueries.pageSize,
		paginationQueries.year,
	])

	return (
		<ContainerPage>
			<HeaderPage
				title={t("consumption.title.invoicing")}
				isCreateButtonVisible={false}
				searchHide
				rightTitleItem={
					<div>
						<Button
							color="secondary"
							style={{ textTransform: "initial" }}
							onClick={() => setIsOpenInvoiceGenModal((isOpen) => !isOpen)}
							variant={"text"}
							startIcon={<ReceiptIcon />}
						>
							{t("consumption.invoicing.button.generateInvoice")}
						</Button>

						<Button
							color="secondary"
							style={{ textTransform: "initial" }}
							onClick={handleCloseFilterModal}
							variant={"text"}
							startIcon={<TuneIcon />}
						>
							Filtros
						</Button>
					</div>
				}
			/>

			<BodyPage>
				<Table
					page={(paginationQueries.currentPage ?? 0) - 1}
					changeRowsPerPage={handleChangeRowsPerPage}
					rowsPerPage={paginationQueries.pageSize}
					pageCount={paginationQueries.totalItems}
					changePage={handleChangePage}
					isLoading={isSeachingData}
					onEdit={handleEditInvoice}
					onDelete={() => null}
					columns={columns}
					keys={keys}
					rows={rows}
				/>
			</BodyPage>

			<InvoiceActionModal
				title={t("filter.filter_title")}
				onSubmit={handleFilterChanges}
				show={isOpenModalFilter}
				onClose={handleCloseFilterModal}
				LeftSideAction={({ reset }) => (
					<Button
						color="secondary"
						onClick={() => {
							reset({ organizationId: "", date: null! })
							handleClearFilter()
						}}
						sx={{ whiteSpace: "nowrap" }}
					>
						{t("filter.clear")}
					</Button>
				)}
				RightSideAction={() => (
					<>
						<Button color="secondary" onClick={handleCloseFilterModal}>
							{t("filter.cancel")}
						</Button>

						<Button color="secondary" autoFocus type="submit">
							{t("filter.apply")}
						</Button>
					</>
				)}
			/>

			<InvoiceActionModal
				title={t("consumption.invoicing.generationModal.title")}
				onSubmit={handleInvoiceGeneration}
				show={isOpenInvoiceGenModal}
				onClose={() => setIsOpenInvoiceGenModal((isOpen) => !isOpen)}
				LeftSideAction={() => (
					<Button
						color="secondary"
						onClick={() => setIsOpenInvoiceGenModal((isOpen) => !isOpen)}
						sx={{ whiteSpace: "nowrap" }}
					>
						{t("filter.cancel")}
					</Button>
				)}
				RightSideAction={({ form }) => (
					<Button color="secondary" autoFocus type="submit" disabled={!form.watch("confirm")}>
						{t("consumption.invoicing.button.generate")}
					</Button>
				)}
				ExtraContent={({ form }) => (
					<>
						<Grid mt={1}>
							<Alert severity="warning">
								<b>Atenção!</b> Caso a fatura já tenha sido gerada para o mês selecionado e
								modifições tenham sido realizadas, a fatura será <b>totalmente recriada do zero</b>.
							</Alert>
						</Grid>

						<Grid mt={1}>
							<TextField
								style={styles.fullWidth}
								variant="standard"
								label="Digite CONFIRMAR para realizar a ação"
								placeholder="confirmar"
								color="secondary"
								autoComplete="off"
								onChange={(e) => {
									form.setValue("confirm", e.target.value.toLocaleLowerCase() === "confirmar")
								}}
							/>
						</Grid>
					</>
				)}
			/>
		</ContainerPage>
	)
}
