import { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import CategoryIcon from "@mui/icons-material/Category"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"

import { AccordionSummary } from "../../../components/accordion/accordion-summary"
import { DialogHistoric } from "../../../components/dialog-historic/dialog-historic"
import { ProductSelect } from "../../../components/product-select/product-select"
import { OrganizationService } from "../../../models/Organization"
import { Service } from "../../../models/Service"
import { CostRangeTable } from "./cost-range-table"

export interface ServicesSectionProps {
	isHistoricVisible?: boolean
	organizationId?: string
}

export const ServicesSection = ({ isHistoricVisible, organizationId }: ServicesSectionProps) => {
	const {
		formState: { errors },
		setValue,
		getValues,
		watch,
	} = useFormContext()
	watch("services")
	const active = watch("active")

	const [openCostRangeTable, setOpenCostRangeTable] = useState<boolean>(false)
	const [serviceEdit, setServiceEdit] = useState<number>()
	const [products, setProducts] = useState<any>(getValues("services") || [])
	const [showHistoric, setShowHistoric] = useState<boolean>(false)

	const { t } = useTranslation()

	const handleAddProduct = (product: any) => {
		const products = getValues("services") || []
		setValue("services", [...products, product])
	}

	const handleUpdateValue = (data: OrganizationService[]) => {
		data.forEach((item) => {
			const lastElementIndex = item.costRanges.length - 1
			item.costRanges[lastElementIndex].usageEnd = item.unlimited ? Infinity : item.usageLimit
		})
		setValue("services", data)
	}

	const handleDeleteProduct = (product: any) => {
		const objectProducts = getValues("services").map((item: Service & { deleted: boolean }) => {
			if (item.serviceId === product.serviceId) item.deleted = true
			return item
		})
		setValue("services", objectProducts)
	}

	const handleEditAccept = () => {
		setServiceEdit(undefined)
		setOpenCostRangeTable(!openCostRangeTable)
	}

	useEffect(() => {
		setValue("services", products)
	}, [products])

	return (
		<Accordion>
			<AccordionSummary
				id="panel2a"
				title={t("organizations.according.services_resolv")}
				icon={<CategoryIcon style={{ marginRight: 8 }} color={"secondary"} />}
				error={!!errors?.services}
			/>
			<AccordionDetails>
				<ProductSelect
					handleUpdateValue={handleUpdateValue}
					dataProducts={getValues("services") || []}
					handleAddProduct={handleAddProduct}
					handleDeleteProduct={handleDeleteProduct}
					isHistoricVisible={isHistoricVisible}
					onClickHistoric={() => setShowHistoric(true)}
					errors={errors?.services}
					readonly={!active}
					isButtonEditValues={active}
					isEditableUnlimited={active}
					onClickEditValues={(_: OrganizationService, index: number) => {
						setServiceEdit(index)
						setOpenCostRangeTable(!openCostRangeTable)
					}}
				/>
			</AccordionDetails>
			{serviceEdit != undefined && serviceEdit >= 0 && (
				<CostRangeTable
					serviceIndex={serviceEdit as number}
					open={openCostRangeTable}
					handleAccept={handleEditAccept}
				/>
			)}

			<DialogHistoric
				id={organizationId}
				handleClose={() => setShowHistoric(false)}
				open={showHistoric}
			/>
		</Accordion>
	)
}
