import { useTranslation } from "react-i18next"

import { Grid, Typography } from "@mui/material"

import { GraphDatasetProps } from "../../../@types/graphs/dataset"
import { Doughnut } from "../../../components/graphs/doughnut/doughnut"
import { LoadingTable } from "../../../components/loading/loading"
import { Center } from "../../../pages/consumption/invoicing-details/invoicing-details.styles"

interface UsageOverviewGraphsProps {
	isLoading?: boolean
	datasets: {
		consultsByProduct: GraphDatasetProps
		consultsByType: GraphDatasetProps
		consultsByTeam: GraphDatasetProps
	}
}

export function UsageOverviewGraphs({
	isLoading,
	datasets: { consultsByProduct, consultsByType, consultsByTeam },
}: UsageOverviewGraphsProps) {
	const { t } = useTranslation()

	if (!isLoading && !consultsByProduct && !consultsByType) return <></>

	const hasData = ([dataset]: GraphDatasetProps["datasets"]) => dataset.data.length > 0

	if (
		!isLoading &&
		(!hasData(consultsByProduct.datasets) ||
			!hasData(consultsByType.datasets) ||
			!hasData(consultsByTeam.datasets))
	) {
		return (
			<Typography variant="body2" align="center" color={"#626262"} mt={1} mb={2}>
				NENHUM RESULTADO ENCONTRADO
			</Typography>
		)
	}

	return (
		<>
			<Grid container columns={3} mb={4}>
				<Grid item md={3} lg={1}>
					{!isLoading ? (
						<Doughnut
							title={t("consumption.invoicing.overview.consumptionByProduct") as string}
							data={consultsByProduct}
						/>
					) : (
						<Center>
							<LoadingTable />
						</Center>
					)}
				</Grid>

				<Grid item md={3} lg={1}>
					{!isLoading && consultsByType.datasets.length ? (
						<Doughnut
							title={t("consumption.invoicing.overview.consumptionByForm") as string}
							data={consultsByType}
						/>
					) : (
						<Center>
							<LoadingTable />
						</Center>
					)}
				</Grid>

				<Grid item md={3} lg={1}>
					{!isLoading && consultsByTeam.datasets.length ? (
						<Doughnut
							title={t("consumption.invoicing.overview.consumptionByTeam") as string}
							data={consultsByTeam}
						/>
					) : (
						<Center>
							<LoadingTable />
						</Center>
					)}
				</Grid>
			</Grid>
		</>
	)
}
