import { Chip, Divider, Grid, TextField } from "@mui/material"

type DetailsModalDataBlockProps = {
	title: string
	data: { label: string; value: string | number | Date | undefined; width?: number }[]
}

export const DetailsModalDataBlock = ({ title, data }: DetailsModalDataBlockProps) => {
	return (
		<>
			<Grid item xs={12}>
				<Divider textAlign="left">
					<Chip label={title} size="small" color="primary" />
				</Divider>
			</Grid>
			{data.map(({ label, value, width }, index) => (
				<>
					<Grid item xs={12} md={width ?? 3} key={index}>
						<TextField
							color="secondary"
							size="small"
							id="filled-basic"
							label={label}
							variant="filled"
							value={value}
							style={{ width: "100%" }}
							InputProps={{ readOnly: true }}
						/>
					</Grid>
				</>
			))}
		</>
	)
}
