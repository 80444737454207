import { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { Box, Collapse, Grid, IconButton, TableCell, TableRow, Typography } from "@mui/material"

import { searchProject, updateProject } from "../../apis/projects"
import { BodyPage, ContainerPage } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { HeaderPage } from "../../components/header/page/header-page"
import { Loading } from "../../components/loading/loading"
import { rowCustomizations } from "../../components/table/row-customizations"
import { Table } from "../../components/table/table"
import { ControlledTextField } from "../../components/text-field/controlled-text-field"
import { pathNames } from "../../routes/paths"
import { styles } from "../../styles/common"
import { flattenObject } from "../../utils/flattenObject"
import { createErrorMessage } from "../../utils/format"

export const EditProject = () => {
	const [loading, setLoading] = useState<boolean>(false)

	const [dialog, setDialog] = useState<boolean>(false)
	const [dialogTitle, setDialogTitle] = useState<any>("")
	const [dialogDescription, setDialogDescription] = useState<any>("")
	const [services, setServices] = useState<any>([])
	const { customizeRow } = rowCustomizations()
	const [isError, setIsError] = useState<boolean>(false)
	const [rowsExpanded, setRowsExpanded] = useState<any>({})

	const { t } = useTranslation()

	const navigate = useNavigate()

	const { projectId } = useParams()

	const methods = useForm<any>()
	const {
		control,
		getValues,
		formState: { isValid },
		reset,
	} = methods

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line
	}, [])

	const fetchData = async () => {
		setLoading(true)
		try {
			const {
				data: { data },
			} = await searchProject(projectId)
			setServices(data.services)
			reset(data)
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}

	const handleSubmit = async () => {
		const formValues = getValues()

		const projectObject = {
			...formValues,
		}

		setIsError(false)

		if (isValid) {
			setLoading(true)
			try {
				await updateProject(projectId, projectObject)
				setDialogTitle(t("request_messages.edit_success_title", { name: "Projeto" }))
				setDialogDescription(t("request_messages.edit_success_description", { name: "Projeto" }))
			} catch (error: any) {
				setIsError(true)
				setDialogTitle(t("request_messages.500"))
				if (error?.response?.data?.messages) {
					setDialogDescription(createErrorMessage(error?.response?.data?.messages))
				} else {
					setDialogDescription(t("request_messages.400"))
				}
			} finally {
				setDialog(true)
				setLoading(false)
			}
		}
	}

	const columns = [
		{
			label: "",
			id: "expand",
		},
		{
			label: t("columns.name"),
			id: "id",
		},
		{
			label: t("columns.description"),
			id: "description",
		},
		{
			label: t("columns.status"),
			id: "active",
		},
	]

	const keys = [
		{
			isLink: true,
			value: "name",
			path: pathNames.service.edit,
			id: "id",
			backPath: pathNames.project.edit + projectId,
		},
		{ value: "description", maxWidth: "350px" },
		"active",
	]

	const handleAccept = () => {
		setDialog(false)
		if (!isError) {
			navigate(pathNames.project.list)
		}
	}

	if (loading) return <Loading />

	return (
		<ContainerPage>
			<HeaderPage
				onClickSubmit={handleSubmit}
				onClickTopButton={() => navigate(pathNames.project.list)}
				description={t("project.description_edit")}
				isEdit={true}
				disabledButton={!isValid}
				title={t("project.edit_title")}
			/>
			<BodyPage>
				<FormProvider {...methods}>
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }}>
						<Grid item xs={12} lg={3}>
							<ControlledTextField
								id={"name"}
								style={styles.fullWidth}
								label={t("project.name")}
								control={control}
								rules={{ required: true }}
							/>
						</Grid>

						<Grid item xs={12} lg={9}>
							<ControlledTextField
								id={"description"}
								style={styles.fullWidth}
								label={t("project.description")}
								control={control}
								rules={{ required: true }}
							/>
						</Grid>
					</Grid>
				</FormProvider>

				<Grid item xs={12} mt={4}>
					<Typography variant="h5" color={"secondary"} mb={1}>
						Serviços
					</Typography>

					<Table
						rows={services}
						columns={columns}
						keys={keys}
						hidePagination
						RowComponent={({ content, index }) => (
							<>
								<TableRow key={index} sx={{ "& > *": { borderBottom: "unset" } }}>
									{content.serviceLimit.length > 0 ? (
										<TableCell>
											<IconButton
												aria-label="expand row"
												size="small"
												onClick={() =>
													setRowsExpanded({ ...rowsExpanded, [index]: !rowsExpanded[index] })
												}
											>
												{rowsExpanded[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
											</IconButton>
										</TableCell>
									) : (
										<TableCell />
									)}

									{keys.map((key, n) => (
										<TableCell key={n} align={"left"}>
											{customizeRow(content, key as any)}
										</TableCell>
									))}
								</TableRow>

								<TableRow>
									<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
										<Collapse in={rowsExpanded[index]} timeout="auto" unmountOnExit>
											<Box sx={{ margin: 2 }}>
												<Typography variant="h6" gutterBottom component="div" color={"secondary"}>
													Organizações utilizando
												</Typography>
												<Table
													rows={content.serviceLimit.map((i: object) => flattenObject(i))}
													columns={[
														{ label: t("columns.name"), id: "name" },
														{ label: t("columns.usageLimit"), id: "usageLimit" },
														{ label: t("columns.unlimited"), id: "unlimited" },
														{ label: t("columns.status"), id: "active" },
													]}
													keys={[
														{
															isLink: true,
															value: "organization.name",
															path: pathNames.organization.edit,
															backPath: pathNames.project.edit + projectId,
															id: "organization.id",
														},
														"usageLimit",
														{ yesOrNo: true, value: "unlimited", id: "unlimited" },
														{
															isActive: true,
															value: "organization.active",
															id: "organization.active",
														},
													]}
													hidePagination
													isEditVisible={false}
												/>
											</Box>
										</Collapse>
									</TableCell>
								</TableRow>
							</>
						)}
					/>
				</Grid>
			</BodyPage>
			<DialogAlert
				onlyConfirm={true}
				show={dialog}
				accept={handleAccept}
				title={dialogTitle}
				description={dialogDescription}
			/>
		</ContainerPage>
	)
}
