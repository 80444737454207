import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { Download } from "@mui/icons-material"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { Chip, Link, Tooltip } from "@mui/material"

import { Clipboard } from "../table.styles"

export interface IIsDownloadConfig {
	showIcon?: boolean
	textProp?: string
}

type TConfigs = {
	isDownload: IIsDownloadConfig
}
export interface IKeyConfig<T extends keyof TConfigs> {
	id: string
	type?: string
	value: string
	path?: string
	backPath?: string
	isLink?: boolean
	isCopyable?: boolean
	isImage?: boolean
	isBold?: boolean
	isDate?: boolean
	format?: string
	isPill?: boolean
	isDownload?: boolean
	config?: TConfigs[T]
	yesOrNo?: boolean
	isMoney?: boolean
	maxWidth?: string
	isElement?: boolean
	isActive?: boolean
	onClick?: <T = any>(row: T) => WindowProxy | null | Promise<WindowProxy | null>
}

export type IKey<T extends keyof TConfigs> = IKeyConfig<T> | string

export const rowCustomizations = () => {
	const [snackbar, setSnackbar] = useState<boolean>(false)
	const { t } = useTranslation()
	const navigate = useNavigate()

	function getContent(row: any, key: any, raw = false) {
		if (typeof key === "string") return raw ? row[key] : String(row[key])
		return raw ? row[key?.value] : String(row[key?.value])
	}

	interface IRow {
		[key: string]: any
	}

	interface IRowCustomization {
		validate: (key: IKey<any>) => boolean
		render: (row: IRow, key: IKey<any>) => JSX.Element
	}

	const linkRow: IRowCustomization = {
		validate: (key: IKey<any> | string) => {
			if (typeof key === "string") return false
			return key.type === "link" || !!key.isLink
		},
		render: (row: IRow, key: IKey<any>) => {
			let path = ""
			if (typeof key === "object") {
				path = key.path + row[key.id]
				if (key.backPath) path += `?backPath=${key.backPath}`
			}

			const content = getContent(row, key, true)

			if (!content) {
				return <span>-</span>
			}

			return (
				<Link
					color={"secondary"}
					// @ts-ignore: Unreachable code error
					onClick={() => navigate(path)}
					variant="body2"
					style={{ cursor: "pointer" }}
				>
					{content}
				</Link>
			)
		},
	}

	const copyRow: IRowCustomization = {
		validate: (key: IKey<any> | string) => {
			if (typeof key === "string") return false
			return key.type === "copyable" || !!key.isCopyable
		},
		render: (row: IRow, key: IKey<any>) => {
			const clipBoard = (text: string) => {
				setSnackbar(true)
				navigator.clipboard.writeText(text)
			}
			const content = getContent(row, key, true)
			return content ? (
				<Clipboard>
					{content}
					<ContentCopyIcon
						onClick={() => clipBoard(getContent(row, key))}
						style={{ fontSize: 15, cursor: "pointer" }}
					/>
				</Clipboard>
			) : (
				<span>-</span>
			)
		},
	}

	const imageRow: IRowCustomization = {
		validate: (key: IKey<any>) => {
			if (typeof key === "string") {
				return key === "imageUrl"
			} else {
				return key?.type === "image" || !!key?.isImage
			}
		},
		render: (row: IRow, key: IKey<any>) => {
			const addDefaultSrc = (ev: any) => {
				ev.target.src = require("../../../assets/images/image-default.jpeg")
			}
			return (
				<img
					width={50}
					onInvalid={addDefaultSrc}
					onError={addDefaultSrc}
					alt={"organization-logo"}
					src={getContent(row, key)}
				/>
			)
		},
	}

	const boldRow: IRowCustomization = {
		validate: (key: IKey<any> | string) => {
			const defaultKeys = ["user", "email"]
			if (typeof key === "string") {
				return defaultKeys.includes(key)
			} else {
				return key.type === "bold" || !!key.isBold
			}
		},
		render: (row: IRow, key: IKey<any>) => {
			return <b>{getContent(row, key)}</b>
		},
	}

	const dateRow: IRowCustomization = {
		validate: (key: IKey<any> | string) => {
			if (typeof key === "string") return false
			return key.type === "date" || !!key.isDate
		},
		render: (row: IRow, key: IKey<any>) => {
			const format = {
				datetime: (v: string) => new Date(v).toLocaleString("pt-BR"),
				date: (v: string) => new Date(v).toLocaleDateString("pt-BR"),
				time: (v: string) => new Date(v).toLocaleTimeString("pt-BR"),
			}
			const formatSelected = (key as IKeyConfig<any>).format || "datetime"
			const content = getContent(row, key, true)
			// @ts-ignore: Unreachable code error
			return <span>{content ? format[formatSelected](content) : "-"}</span>
		},
	}

	const activeRow: IRowCustomization = {
		validate: (key: IKey<any> | string) => {
			if (typeof key === "string" && key === "active") {
				return true
			}

			if (typeof key === "object") {
				return key.isActive || key.type === "active"
			}

			return false
		},
		render: (row: IRow, key: IKey<any>) => {
			return getContent(row, key, true) ? (
				<Chip label={t("status.active")} color="success" variant="outlined" size="small" />
			) : (
				<Chip label={t("status.inactive")} color="error" variant="outlined" size="small" />
			)
		},
	}

	const pillRow: IRowCustomization = {
		validate: (key: IKey<any> | string) => {
			if (typeof key === "string") return false
			return key.type === "pill" || !!key.isPill
		},
		render: (row: IRow, key: IKey<any>) => {
			const bgc = row[(key as IKeyConfig<any>).id + "PillColor"] || "info"
			const icon = row[(key as IKeyConfig<any>).id + "PillIcon"] || ""
			const tooltip = row[(key as IKeyConfig<any>).id + "Tooltip"] || ""
			return (
				<Tooltip title={tooltip}>
					<Chip size="small" label={getContent(row, key)} color={bgc} icon={icon} />
				</Tooltip>
			)
		},
	}

	const downloadRow: IRowCustomization = {
		validate: (key: IKey<any> | string) => {
			if (typeof key === "string") return false
			return key.type === "download" || !!key.isDownload
		},
		render: (row: IRow, key: IKey<any>) => {
			const url = getContent(row, key, true)
			const config = (key as IKeyConfig<any>)?.config
			const filename = row[(key as IKeyConfig<any>).id]

			let callback = () => window.open(url)
			if (typeof key === "object" && key?.onClick) {
				callback = () => key?.onClick && (key?.onClick(row) as any)
			}

			return url ? (
				<Link
					onClick={callback}
					color={"secondary"}
					variant="body2"
					style={{
						display: "flex",
						alignItems: "center",
						cursor: "pointer",
					}}
				>
					{config?.showIcon && <Download style={{ marginRight: 5 }} />}
					{config?.textProp ? getContent(row, config.textProp) : filename || t("fields.download")}
				</Link>
			) : (
				<span>-</span>
			)
		},
	}

	const yesOrNo: IRowCustomization = {
		validate: (key: IKey<any> | string) => {
			if (typeof key === "string") return false
			return key.type === "yesOrNo" || !!key.yesOrNo
		},
		render: (row: IRow, key: IKey<any>) => {
			return (
				<span>
					{getContent(row, key, true) ? (
						<b>{t("rowCustomization.yes")}</b>
					) : (
						t("rowCustomization.no")
					)}
				</span>
			)
		},
	}

	const isMoney: IRowCustomization = {
		validate: (key: IKey<any> | string) => {
			if (typeof key === "string") return false
			return key.type === "isMoney" || !!key.isMoney
		},
		render: (row: IRow, key: IKey<any>) => {
			const content = getContent(row, key, true)
			if (!content) return <span>R$ 0,00</span>
			return (
				<span>
					{Number(content).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
				</span>
			)
		},
	}

	const isElement: IRowCustomization = {
		validate: (key: IKey<any> | string) => {
			if (typeof key === "string") return false
			return key.type === "element" || !!key.isElement
		},
		render: (row: IRow, key: IKey<any>) => {
			return getContent(row, key, true)
		},
	}

	const rowCustomizations: IRowCustomization[] = [
		linkRow,
		copyRow,
		imageRow,
		boldRow,
		dateRow,
		activeRow,
		pillRow,
		downloadRow,
		yesOrNo,
		isMoney,
		isElement,
	]

	const customizeRow = (row: IRow, key: IKey<any>) => {
		const customization = rowCustomizations.find((customization) => customization.validate(key))
		if (customization) return customization.render(row, key)
		if (
			getContent(row, key) === "" ||
			getContent(row, key, true) === null ||
			getContent(row, key, true) === undefined
		) {
			return "-"
		}

		if (typeof key === "string")
			return (
				<span
					style={{
						whiteSpace: "nowrap",
					}}
				>
					{String(row[key])}
				</span>
			)

		return (
			<span
				style={{
					whiteSpace: "nowrap",
					maxWidth: key?.maxWidth ?? "300px",
					display: "block",
					textOverflow: "ellipsis",
					overflow: "hidden",
				}}
			>
				{String(row[key?.value])}
			</span>
		)
	}

	return {
		customizeRow,
		snackbar,
		setSnackbar,
	}
}
