import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { Alert, Button } from "@mui/material"

import { userAuthentication } from "../../apis/auth"
import { BoxCenter } from "../../components/container/container.styles"
import { pathNames, rootPathByRole } from "../../routes/paths"
import { AuthStorage } from "../../storage/auth-type"
import { clearAllItems, getItem, setItem } from "../../storage/local-storage"
import { RoleEnum } from "../user/roles"
import { Container, ContainerError, Description, Logo, Motion } from "./sign-in.styles"

const SignIn = () => {
	const { t } = useTranslation()

	const [isError, setIsError] = useState<boolean>(false)

	const navigate = useNavigate()

	useEffect(() => {
		buildToken()
		return () => {
			setIsError(false)
		}
	}, [])

	const getAccess = async (authToken: any) => {
		try {
			const {
				data: { data },
			} = await userAuthentication(authToken?.access_token)

			setItem(AuthStorage.userAuthorization, data?.authorizationToken)
			setItem(AuthStorage.userToken, authToken)
			setItem(AuthStorage.userPolicy, data?.userPolicy)
			setItem(AuthStorage.userApiKey, { apiKey: data?.apiKey })

			const dataUser = data
			delete dataUser["userPolicy"]

			setItem(AuthStorage.userInformation, data)

			const role = data?.role as RoleEnum

			navigate(rootPathByRole[role] ?? pathNames.home)

			return
		} catch (error) {
			clearAllItems()
			setIsError(true)
		}
	}

	const getTokenByProvider = () => {
		if (window.location.hash) {
			const access_token = window.location.hash
				.split("&")
				.filter(function (el) {
					if (el.match("access_token") !== null) return true
				})[0]
				.split("=")[1] // eslint-disable-line
			const expires_in = window.location.hash
				.split("&")
				.filter(function (el) {
					if (el.match("expires_in") !== null) return true
				})[0]
				.split("=")[1] // eslint-disable-line

			return { access_token, expires_in }
		} else {
			return null
		}
	}

	const buildToken = async () => {
		const tokenProvider = getTokenByProvider()
		const item = getItem(AuthStorage.userToken)

		if (tokenProvider?.access_token || item) {
			if (tokenProvider) {
				clearAllItems()
				getAccess(tokenProvider)
				return
			}
			if (item) {
				getAccess(item)
				return
			}
			return
		}

		return
	}

	const redirectHome = () => {
		navigate(pathNames.home)
	}

	return (
		<Container>
			<BoxCenter>
				{!isError ? (
					<>
						<Motion src={require("../../assets/images/resolvone-motion.gif")} />
						<Description color={"primary"} variant={"caption"}>
							{t("signin.wait")}
						</Description>
					</>
				) : (
					<ContainerError>
						<Logo />
						<Alert severity={"error"} variant={"standard"}>
							{t("signin.error")}
						</Alert>
						<Button onClick={redirectHome} fullWidth variant={"contained"}>
							{t("signin.button_back")}
						</Button>
					</ContainerError>
				)}
			</BoxCenter>
		</Container>
	)
}

export default SignIn
