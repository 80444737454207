import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { disableAdmins } from "../../apis/admin"
import { listAllAPIKeys } from "../../apis/apiKey"
import { BodyPage, ContainerPage } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { Filter } from "../../components/filter/filter"
import { HeaderPage } from "../../components/header/page/header-page"
import { LoadingTable } from "../../components/loading/loading"
import { Table } from "../../components/table/table"
import { Police } from "../../models/Police"
import { pathNames } from "../../routes/paths"
import { AuthStorage } from "../../storage/auth-type"
import { getItem } from "../../storage/local-storage"
import { flattenObject } from "../../utils/flattenObject"

export const ListAPIKey = () => {
	const [showDialogConfirmation, setShowDialogConfirmation] = useState<boolean>(false)
	const [selectedItems, setSelectedItems] = useState<any>()

	const [showFilter, setShowFilter] = useState<boolean>(false)
	const [active, setActive] = useState<any>("enable")

	const [keyPolices, setKeyPolices] = useState<Police>()

	const [rows, setRows] = useState<[]>([])

	const [currentPage, setCurrentPage] = useState<number>(1)
	const [pageSize, setPageSize] = useState<number>(10)
	const [filter, setFilter] = useState<string>("")
	const [pageCount, setPageCount] = useState<number>(0)

	const [loading, setLoading] = useState<boolean>(true)

	const navigate = useNavigate()

	const { t } = useTranslation()

	const columns = [
		{
			label: t("columns.origin"),
			id: "origin",
		},
		{
			label: t("columns.name"),
			id: "name",
		},
		{
			label: t("columns.description"),
			id: "description",
		},

		{
			label: t("columns.trial"),
			id: "trial",
		},
		{
			label: t("menu.services"),
			id: "servicesCount",
		},
		{
			label: t("columns.status"),
			id: "active",
		},
	]

	const keys = [
		"lead.utm",
		"lead.name",
		{ value: "description", maxWidth: "200px" },
		{ yesOrNo: true, value: "trial", id: "trial" },
		"servicesCount",
		"active",
	]

	useEffect(() => {
		const { keysManagemen } = getItem(AuthStorage.userPolicy)
		setKeyPolices(keysManagemen)
	}, [])

	useEffect(() => {
		listAPIKeys()
		// eslint-disable-next-line
	}, [pageSize, currentPage, filter])

	const listAPIKeys = async (status?: any) => {
		try {
			const {
				data: { data },
			} = await listAllAPIKeys({ currentPage, pageSize, filter, active: status })
			setRows(data?.list.map((item: any) => flattenObject(item)))
			setPageCount(data?.total)
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const selectActiveState = (state: string) => {
		setShowFilter(false)
		setActive(state)
		if (state === "all") {
			listAPIKeys(null)
		} else if (state === "enable") {
			listAPIKeys(true)
		} else if (state === "disable") {
			listAPIKeys(false)
		}
	}

	const handleSelectedDisableItems = (items: any) => {
		setSelectedItems(items)
		setShowDialogConfirmation(true)
	}

	const handleDisableAllItems = async () => {
		setShowDialogConfirmation(false)
		setLoading(true)

		try {
			await disableAdmins(selectedItems)
		} catch (error) {
		} finally {
			setLoading(false)
			listAPIKeys()
		}
	}

	return (
		<ContainerPage>
			<HeaderPage
				searchValue={filter}
				onSearch={(event) => setFilter(event?.target?.value)}
				title={t("menu.api_keys")}
				onOpenFilter={() => setShowFilter(true)}
				buttonTitle={t("user.create_api_key")}
				onClickTopButton={() => navigate(pathNames.apiKey.create)}
				isCreateButtonVisible={keyPolices?.create}
			/>
			<BodyPage>
				{!loading ? (
					<Table
						page={currentPage - 1}
						pageCount={pageCount}
						rowsPerPage={pageSize}
						changeRowsPerPage={(value) => setPageSize(value)}
						changePage={(value) => setCurrentPage(value + 1)}
						onDelete={() => null}
						onEdit={(element: any) => navigate(pathNames.apiKey.edit + element?.id)}
						columns={columns}
						rows={rows}
						keys={keys}
						isEditVisible={keyPolices?.update}
						isDeleteVisible={keyPolices?.delete}
						isReadVisible={keyPolices?.read}
						isSelectable={true}
						onDisableAll={handleSelectedDisableItems}
					/>
				) : (
					<LoadingTable />
				)}
			</BodyPage>
			<Filter
				show={showFilter}
				apply={(e) => selectActiveState(e)}
				cancel={() => setShowFilter(false)}
				value={active}
			/>
			<DialogAlert
				show={showDialogConfirmation}
				description={t("table.modal_disable_label_description")}
				title={t("table.disable")}
				accept={() => handleDisableAllItems()}
				decline={() => setShowDialogConfirmation(false)}
			/>
		</ContainerPage>
	)
}
