import styled from "styled-components"

import { maxDeviceSizeForMediaQueries } from "../../styles/devices-medias"

export const Container = styled.div`
	width: 100%;
	height: 100%;
	margin-left: 280px;
	margin-top: 60px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 32px;
	box-sizing: border-box;
	background-color: ${(props) => props.theme.palette.background.default};
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		margin-left: 0px;
		padding: 16px;
	}
`

export const ContainerTable = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 32px;
	box-sizing: border-box;
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		margin-left: 0px;
		padding: 16px;
	}
`

export const ContainerPage = styled.div`
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(1px);
	z-index: 999;
`
