import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { Grid } from "@mui/material"

import { GraphDatasetProps } from "../../../../@types/graphs/dataset"
import { GeneratedInvoiceService, InvoicingData } from "../../../../apis/invoicing"
import { Sankey } from "../../../../components/graphs/sankey/sankey"
import { Table } from "../../../../components/table/table"
import { mountDatasetGraph } from "../../../../utils/graphs"
import { tablePrintStyles } from "../invoicing-details.styles"

interface InvoicingTeamDistribuitionProps {
	invoicing: InvoicingData | null
	isLoading?: boolean
	isPrinting?: boolean
}

export function InvoicingTeamDistribuition({
	invoicing,
	isLoading,
	isPrinting,
}: InvoicingTeamDistribuitionProps) {
	const { t } = useTranslation()

	const handleValue = (acc: any, [key, value]: any) => {
		const crr = acc[key as keyof typeof acc]
		if (!crr) return { ...acc, [key as keyof typeof acc]: Number(value) }
		return { ...acc, [key as keyof typeof acc]: Number(crr) + Number(value) }
	}

	const parseDataset = ([legend, value, array]: [legend: string, value: string, array: any[]]) => {
		if (!array.length) return {} as GraphDatasetProps
		const products = array.map((f) => [f[legend], f[value] ?? value])
		const data = products.reduce(handleValue, {})
		return mountDatasetGraph(data)
	}

	const consultationData = useMemo(() => {
		const response =
			invoicing?.monthlyData?.services?.map((service: GeneratedInvoiceService) => ({
				consultation_qty: service.total,
				service: service.name,
			})) || []

		return parseDataset(["service", "consultation_qty", response])
	}, [invoicing])

	const consultationFormData = useMemo(() => {
		const data = invoicing?.monthlyData?.totalByProcessingType
		const result = data
			? Object.keys(data).map((key) => ({
					processType: key,
					total: invoicing?.monthlyData?.totalByProcessingType[key],
				}))
			: []
		return parseDataset(["processType", "total", result])
	}, [invoicing])

	const teamsUsage = useMemo(() => {
		const result: any = []
		invoicing?.monthlyData?.services.forEach((service: GeneratedInvoiceService) => {
			result.push(...service.teams.map((team) => [team.name, service.name, team.total]))
		})
		return result
	}, [invoicing])

	const [paginationQueries, setPaginationQueries] = useState({
		rowsPerPage: 5,
		pageNumber: 1,
	})

	const rows = useMemo(() => {
		return teamsUsage.map(([team, service, totalAmount]: string[]) => ({
			team,
			service,
			totalAmount,
		}))
	}, [paginationQueries, invoicing, t])

	const columns = useMemo(
		() => [
			{
				label: t("columns.team"),
				id: "team",
			},
			{
				label: t("columns.service"),
				id: "service",
			},
			{
				label: t("columns.queries_amount"),
				id: "totalAmount",
			},
		],
		[rows],
	)

	const keys = useMemo(() => ["team", "service", "totalAmount"], [rows, t])

	const handleChangeRowsPerPage = (rowsPerPage: number) =>
		setPaginationQueries((prevState) => ({ ...prevState, rowsPerPage }))

	const handleChangePage = (crrPage: number) => {
		setPaginationQueries((prevState) => ({ ...prevState, pageNumber: crrPage + 1 }))
	}

	if (!isLoading && !consultationData.datasets && !consultationFormData.datasets) return <></>
	return (
		<Grid container columns={3} mt={2}>
			{!isPrinting && (
				<Grid item sm={3}>
					<Sankey
						data={teamsUsage}
						tootip={{
							from: "De",
							to: "Para",
							value: "Consumiu",
						}}
					/>
				</Grid>
			)}

			<Table
				rowsPerPageOptions={[5, 10, 25]}
				rowsPerPage={isPrinting ? 100 : paginationQueries.rowsPerPage}
				changeRowsPerPage={handleChangeRowsPerPage}
				page={paginationQueries.pageNumber - 1}
				changePage={handleChangePage}
				onDelete={() => null}
				columns={columns}
				keys={keys}
				rows={rows}
				onEdit={() => null}
				isEditVisible={false}
				pageCount={rows.length}
				titleStyles={isPrinting ? tablePrintStyles : {}}
				cellStyles={isPrinting ? tablePrintStyles : {}}
				tableContainerStyles={isPrinting ? { overflowX: "initial" } : {}}
				hidePagination={isPrinting}
			/>
		</Grid>
	)
}
