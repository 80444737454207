import { AxiosResponse } from "axios"

import { CreateOrganizationData } from "../libs/zod/organization/create-organization"
import { UpdateUserData } from "../libs/zod/user/create-user"
import { APIResponse } from "../models/API"
import axios from "./config/axios"

export const listAllUsers = async ({ currentPage, pageSize, filter, active }: any) =>
	await axios.post("/users/search", { currentPage, pageSize, filter, active })

export const searchSpecificUser = async (
	userId: any,
): Promise<AxiosResponse<APIResponse<CreateOrganizationData>>> =>
	await axios.get(`/users/${userId}`)

export const createUser = async (user: any) => await axios.post("/users", user)

export const updateUser = async (userId: string, userData: UpdateUserData) =>
	await axios.put(`/users/${userId}`, userData)

export const deleteUser = async (userId: string) => await axios.delete(`/users/${userId}`)

export const disableUsers = async (users: Array<string>) =>
	await axios.put(`/users/disable`, { userIds: users })
