import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { zodResolver } from "@hookform/resolvers/zod"
import RestartAltIcon from "@mui/icons-material/RestartAlt"
import {
	Alert,
	Autocomplete,
	DialogContentText,
	FormControlLabel,
	Grid,
	Switch,
	TextField,
	Typography,
} from "@mui/material"

import { createAPIKey, resetApiKey } from "../../apis/apiKey"
import { MultiSelectData } from "../../apis/dashboard"
import { listOptionsOrganizations } from "../../apis/organization"
import { listAllTeams, searchTeamByOrganization } from "../../apis/team"
import { searchSpecificUser, updateUser } from "../../apis/user"
import { BodyPage, FormContainerPage } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { HeaderPage } from "../../components/header/page/header-page"
import { Loading } from "../../components/loading/loading"
import { ControlledTextField } from "../../components/text-field/controlled-text-field"
import { useAuthentication } from "../../hooks/app/useAuthentication"
import { UpdateUserData, updateUserSchema } from "../../libs/zod/user/create-user"
import { OrganizationTeam } from "../../models/Team"
import { pathNames } from "../../routes/paths"
import { styles } from "../../styles/common"
import { createErrorMessage } from "../../utils/format"
import { RoleEnum } from "./roles"

type TRoleOption = {
	id: RoleEnum
	label: string
}

export const EditUsers = () => {
	const { t } = useTranslation()

	const roles = Object.values(RoleEnum).map((role) => ({
		id: role,
		label: t(`roles.${role}`),
	})) as TRoleOption[]

	const [loading, setLoading] = useState<boolean>(false)
	const [organizations, setOrganizations] = useState<MultiSelectData["data"]>([])
	const [dialog, setDialog] = useState<boolean>(false)
	const [dialogTitle, setDialogTitle] = useState<any>("")
	const [dialogDescription, setDialogDescription] = useState<any>("")
	const [isError, setIsError] = useState<boolean>(false)
	const [dialogApiKey, setDialogApiKey] = useState<boolean>(false)

	const [teams, setTeams] = useState<OrganizationTeam[]>([])

	const { isAdmin } = useAuthentication()
	const { userId } = useParams()
	const navigate = useNavigate()

	const methods = useForm<UpdateUserData>({
		resolver: zodResolver(updateUserSchema),
	})
	const {
		control,
		formState: { errors },
		handleSubmit,
		watch,
		reset,
		setValue,
	} = methods

	const watchedOrganizationId = watch("organizationId")
	const watchedTeamId = watch("teamId")
	const apiKey = watch("apiKey.key")

	useEffect(() => {
		fetchData()
	}, [])

	const fetchData = async () => {
		setLoading(true)
		if (isAdmin) {
			await getOrganizations()
			await getTeams()
		}
		try {
			const {
				data: { data },
			} = await searchSpecificUser(userId)
			reset(data)
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}
	const getOrganizations = async () => {
		try {
			const {
				data: { data },
			} = await listOptionsOrganizations()
			setOrganizations(data)
		} catch (error) {
		} finally {
		}
	}

	const getTeams = async () => {
		try {
			const {
				data: { data },
			} = await listAllTeams({ pageSize: 20, currentPage: 1, filter: "" })
			setTeams(data?.list)
		} catch (error) {
		} finally {
		}
	}

	const getTeamsByOrganization = async (organizationId: string) => {
		try {
			const {
				data: { data },
			} = await searchTeamByOrganization(organizationId)
			setTeams(data)
			if (!data.some((team) => team.id === watchedTeamId)) {
				setValue("teamId", "")
			}
		} catch (error) {}
	}

	const handleAccept = () => {
		setDialog(false)
		if (!isError) {
			navigate(pathNames.users.user.list)
			methods.reset()
		}
	}

	useEffect(() => {
		if (watchedOrganizationId) {
			setTeams([])
			getTeamsByOrganization(watchedOrganizationId)
		}
	}, [watchedOrganizationId])

	const handleUpdateUserSubmit = async (data: UpdateUserData) => {
		setLoading(true)
		setIsError(false)
		try {
			await updateUser(userId as string, data)
			setDialogTitle(t("request_messages.create_success_title", { name: "Usuário" }))
			setDialogDescription(t("request_messages.success_description", { name: "usuário" }))
		} catch (error: any) {
			setIsError(true)
			setDialogTitle(t("request_messages.500"))
			if (error?.response?.data?.messages) {
				setDialogDescription(createErrorMessage(error?.response?.data?.messages))
			} else {
				setDialogDescription(t("request_messages.400"))
			}
		} finally {
			setDialog(true)
			setLoading(false)
		}
	}

	const sendResetApiKey = async () => {
		setLoading(true)
		setDialogApiKey(false)
		try {
			if (!apiKey) {
				await createAPIKey({
					userId: userId as string,
					trial: false,
					description: "",
					active: true,
					main: true,
				})
			} else {
				await resetApiKey(apiKey)
			}

			const {
				data: { data },
			} = await searchSpecificUser(userId)
			reset(data)
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}

	if (loading) return <Loading />

	return (
		<FormContainerPage onSubmit={handleSubmit(handleUpdateUserSubmit)}>
			<HeaderPage
				onClickTopButton={() => navigate(pathNames.users.user.list)}
				description={t("user.edit_user_description")}
				isEdit={true}
				title={t("user.edit_user_title")}
			/>

			<BodyPage>
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					<Grid item xs={12} md={6} lg={6}>
						<ControlledTextField
							id={"name"}
							style={styles.fullWidth}
							label={t("customer.label_name")}
							control={control}
							rules={{ required: true }}
							errors={errors.name}
							helperText={errors.name?.message}
						/>
					</Grid>
					<Grid item xs={8} md={4} lg={4}>
						<Controller
							control={control}
							name={"role"}
							render={({ field: { onChange, value } }) => (
								<Autocomplete
									disablePortal
									size={"small"}
									options={roles}
									value={roles.find((role) => (role?.id as unknown as RoleEnum) === value)}
									getOptionLabel={(option) => option.label || ""}
									onChange={(_, option) => onChange(option?.id)}
									renderInput={(params) => (
										<TextField
											{...params}
											variant={"filled"}
											color={"secondary"}
											label={t("columns.role")}
											error={!!errors.role}
											helperText={errors.role?.message}
										/>
									)}
								/>
							)}
						/>
					</Grid>

					<Grid style={styles.active} item xs={4} md={2}>
						<Controller
							control={control}
							name="active"
							render={({ field: { value, ...fieldValues } }) => (
								<FormControlLabel
									control={<Switch {...fieldValues} color={"secondary"} checked={value} />}
									label={
										<Typography color={"secondary"}>
											{t(`status.${`${value ? "active" : "inactive"}`}`)}
										</Typography>
									}
								/>
							)}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<ControlledTextField
							id={"email"}
							style={styles.textFieldMarginTop}
							label={t("customer.label_email")}
							control={control}
							rules={{ required: true }}
							errors={errors.email}
							helperText={errors.email?.message}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<ControlledTextField
							id={"phone"}
							style={styles.textFieldMarginTop}
							label={t("customer.label_phone")}
							control={control}
							rules={{ required: true }}
							mask={"(99) 99999-9999"}
							errors={errors.phone}
							helperText={errors.phone?.message}
						/>
					</Grid>

					<Grid item xs={12}>
						<ControlledTextField
							id={"apiKey.key"}
							style={styles.textFieldMarginTop}
							label={t("profile.apiKey")}
							control={control}
							rules={{ required: true }}
							readOnly={true}
							endIcon={<RestartAltIcon />}
							handleClickEndIcon={() => setDialogApiKey(true)}
						/>
					</Grid>

					{isAdmin && (
						<Grid item xs={12}>
							{organizations.length > 0 && (
								<Controller
									control={control}
									name={"organizationId"}
									render={({ field: { onChange, value } }) => (
										<Autocomplete
											disablePortal
											size={"small"}
											options={organizations}
											value={organizations.find((organization) => organization.id === value)}
											getOptionLabel={(option: any) => option.name || ""}
											onChange={(event, option) => onChange(option?.id || null)}
											renderInput={(params) => (
												<TextField
													{...params}
													variant={"filled"}
													color={"secondary"}
													label={t("customer.label_organization")}
													error={!!errors.organizationId}
													helperText={errors.organizationId?.message}
												/>
											)}
										/>
									)}
								/>
							)}
						</Grid>
					)}

					{watchedOrganizationId && (
						<Grid item xs={12}>
							<Controller
								control={control}
								name={"teamId"}
								render={({ field: { onChange, value } }) => (
									<Autocomplete
										disablePortal
										size={"small"}
										disabled={!teams.length}
										options={teams}
										value={teams.find((team) => team?.id === value)}
										getOptionLabel={(option) => option.name || ""}
										onChange={(event, option) => onChange(option?.id || null)}
										renderInput={(params) => (
											<TextField
												{...params}
												variant={"filled"}
												color={"secondary"}
												label={t("customer.label_team")}
												error={!!errors.teamId || !teams.length}
												helperText={
													!teams.length ? t("user.label_team_empty") : errors.teamId?.message
												}
											/>
										)}
									/>
								)}
							/>
						</Grid>
					)}
				</Grid>
				<Grid item xs={12} style={{ marginTop: 16 }}>
					<Alert severity={"info"} title="Importante">
						O usuário cadastrado irá receber um e-mail na sua caixa de entrada com o código de
						confirmação e o link para resetar a senha.
					</Alert>
				</Grid>
			</BodyPage>

			<DialogAlert
				show={dialogApiKey}
				title={t("profile.dialog_reset_title")}
				description={t("profile.dialog_reset_description")}
				accept={sendResetApiKey}
				decline={() => setDialogApiKey(false)}
			/>

			<DialogAlert
				props={!isError ? <DialogContent /> : null}
				onlyConfirm={true}
				show={dialog}
				accept={handleAccept}
				title={dialogTitle}
				description={dialogDescription}
			/>
		</FormContainerPage>
	)
}

const DialogContent = () => {
	const { t } = useTranslation()

	return (
		<>
			<br />
			<DialogContentText>
				<b>{t("modal.description_email_steps_1")}</b>
			</DialogContentText>
			<DialogContentText>
				<b>{t("modal.description_email_steps_2")}</b>
			</DialogContentText>
			<br />
			<DialogContentText>{t("modal.description_email_steps_3")}</DialogContentText>
			<img alt="email-example" width={400} src={require("../../assets/images/email-example.png")} />
		</>
	)
}
