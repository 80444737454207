import { SyntheticEvent } from "react"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { Autocomplete, Button, Grid, TextField, Typography } from "@mui/material"
import { DataGrid, GridToolbarContainer, GridValidRowModel } from "@mui/x-data-grid"

export const ExecutionOptionsProps = {}

export const ExecutionOptions = () => {
	const { t } = useTranslation()
	const { watch, setValue } = useFormContext<any>()

	const executionOptions = watch("executionOptions")

	const handleRowUpdate = (
		newRow: GridValidRowModel,
	): GridValidRowModel | Promise<GridValidRowModel> => {
		const updatedRow = { ...newRow, isNew: false }
		setValue(
			"executionOptions.options",
			executionOptions.options.map((row: any) => (row.id === newRow.id ? updatedRow : row)),
		)
		return updatedRow
	}

	function EditToolbar() {
		const changeActiveForAll = (active = true) => {
			const updatedOptions = executionOptions.options.map((option: any) => {
				option.active = active
				return option
			})
			setValue("executionOptions.options", updatedOptions)
		}

		return (
			<GridToolbarContainer>
				<Button color="secondary" onClick={() => changeActiveForAll(true)}>
					Ativar todos
				</Button>

				<Button color="secondary" onClick={() => changeActiveForAll(false)}>
					Desativar todos
				</Button>
			</GridToolbarContainer>
		)
	}

	return (
		<>
			{/* {fields.length > 0 && (
				<>
					<ContainerAddiction>
						<Grid margin={"0 0 8px 0"}>
							<Typography color={"secondary"} variant={"h6"}>
								{t("service.executionOptions")}
								<span style={{ fontSize: "13px", opacity: "0.75" }}> ({fields.length})</span>
							</Typography>

							<Typography color="textPrimary" variant={"body2"}>
								{t("service.executionOptions_description")}
							</Typography>
						</Grid>
					</ContainerAddiction>
					<ContainerAddiction>
						<FormGroup>
							<Grid
								container
								columnSpacing={{ xs: 1, sm: 2, md: 3 }}
								style={{
									borderRadius: "4px",
									marginTop: "8px",
									marginBottom: "8px",
									backgroundColor: "#fAfAfA",
									padding: "4px 12px",
									maxHeight: "350px",
									overflowY: "auto",
								}}
							>
								{fields.map((field: any, index: number) => (
									<Grid item xs={4} key={field.key}>
										<FormControlLabel
											control={
												<Checkbox
													{...register(`executionOptions.${index}.active`)}
													checked={watch(`executionOptions.${index}.active`)}
												/>
											}
											label={
												<span>
													{field.title} -{" "}
													{
														<span style={{ color: "grey", fontSize: "13px" }}>
															{field.description}
														</span>
													}
												</span>
											}
										/>
									</Grid>
								))}
							</Grid>
						</FormGroup>
					</ContainerAddiction>
				</>
			)} */}

			{executionOptions?.type === "data-grid" && (
				<div style={{ height: "550px", width: "100%", marginBottom: "60px" }}>
					<Typography color={"secondary"} variant={"h6"}>
						{executionOptions?.title}
						<span style={{ fontSize: "13px", opacity: "0.75" }}>
							{" "}
							({executionOptions?.options.length})
						</span>
					</Typography>

					<Typography color="textPrimary" variant={"body2"} style={{ marginBottom: "16px" }}>
						{executionOptions?.description}
					</Typography>
					<DataGrid
						editMode="row"
						rowSelection={false}
						processRowUpdate={handleRowUpdate}
						rows={executionOptions?.options}
						columns={executionOptions?.columns}
						slots={{
							toolbar: EditToolbar,
						}}
					/>
				</div>
			)}

			{executionOptions?.type === "selection" && (
				<div style={{ width: "100%", marginBottom: "2rem" }}>
					<Typography color={"secondary"} variant={"h6"}>
						{executionOptions?.title}
						<span style={{ fontSize: "13px", opacity: "0.75" }}>
							{" "}
							({executionOptions?.options.length})
						</span>
					</Typography>

					<Typography color="textPrimary" variant={"body2"} style={{ marginBottom: "16px" }}>
						{executionOptions?.description}
					</Typography>

					<Grid container>
						<Grid item xs={4}>
							<Autocomplete
								disablePortal
								size={"small"}
								options={executionOptions?.options}
								getOptionLabel={(option: any) => option?.title || ""}
								onChange={(_: SyntheticEvent, o: any) => {
									setValue(
										"executionOptions.options",
										executionOptions?.options.map((option: any) => ({
											...option,
											selected: option?.id === o?.id,
										})),
									)
								}}
								defaultValue={executionOptions?.options[0]}
								value={executionOptions?.options?.find((option: any) => option.selected)}
								renderInput={(params) => (
									<TextField
										{...params}
										variant={"filled"}
										color={"secondary"}
										label={"Selecione uma opção"}
									/>
								)}
							/>
						</Grid>
					</Grid>
				</div>
			)}
		</>
	)
}
