import { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import RestartAltIcon from "@mui/icons-material/RestartAlt"
import { Alert, AlertTitle, Divider, Grid } from "@mui/material"

import { resetApiKey } from "../../apis/apiKey"
import { getSelfTeam } from "../../apis/team"
import { BodyPage, ContainerPageFull } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { HeaderPage } from "../../components/header/page/header-page"
import { ProductSelect } from "../../components/product-select/product-select"
import { SnackBar } from "../../components/snackbar/snackbar"
import { ControlledTextField } from "../../components/text-field/controlled-text-field"
import { useAuthentication } from "../../hooks/app/useAuthentication"
import { styles } from "../../styles/common"

export const Profile = () => {
	const [products, setProducts] = useState<any>([])
	const [dialogApiKey, setDialogApiKey] = useState<boolean>(false)
	const [snackbar, setSnackbar] = useState<boolean>(false)
	const [snackbarMessage, setSnackbarMessage] = useState<any>()
	const [snackbarType, setSnackbarType] = useState<"success" | "error">("success")

	const { t } = useTranslation()

	const methods = useForm<any>()
	const { control, reset } = methods

	const { isAdmin, user } = useAuthentication()
	useEffect(() => {
		reset({
			...user,
			role: t(`roles.${user?.role}`),
		})
		fetchData()
	}, [])
	const fetchData = async () => {
		try {
			const {
				data: { data },
			} = await getSelfTeam()
			setProducts(data?.services)
		} catch (error) {}
	}

	const sendResetApiKey = async () => {
		setDialogApiKey(false)
		try {
			await resetApiKey(user.apiKey)
			setSnackbarMessage(t("profile.dialog_reset_success"))
			setSnackbarType("success")
		} catch (error) {
			setSnackbarMessage(t("request_messages.500"))
			setSnackbarType("error")
		} finally {
			setSnackbar(true)
		}
	}

	return (
		<ContainerPageFull>
			<HeaderPage
				isCreateButtonVisible={false}
				title={t("profile.title")}
				isEdit={false}
				searchHide={true}
				description={t("profile.description")}
			/>
			<BodyPage>
				<FormProvider {...methods}>
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid item xs={6}>
							<ControlledTextField
								id={"userName"}
								style={styles.fullWidth}
								label={t("profile.email")}
								control={control}
								rules={{ required: true }}
								readOnly={true}
							/>
						</Grid>
						<Grid item xs={6}>
							<ControlledTextField
								id={"role"}
								style={styles.fullWidth}
								label={t("profile.role")}
								control={control}
								rules={{ required: true }}
								readOnly={true}
							/>
						</Grid>

						<Grid item xs={12}>
							<ControlledTextField
								id={"apiKey"}
								style={styles.textFieldMarginTop}
								label={t("profile.apiKey")}
								control={control}
								rules={{ required: true }}
								readOnly={true}
								endIcon={<RestartAltIcon />}
								handleClickEndIcon={() => setDialogApiKey(true)}
							/>
						</Grid>

						{isAdmin && (
							<Grid item xs={12}>
								<ControlledTextField
									id={"organizationName"}
									readOnly={true}
									style={styles.textFieldMarginTop}
									label={t("profile.organization")}
									control={control}
									rules={{ required: true }}
								/>
							</Grid>
						)}
					</Grid>
					<Divider style={styles.divider} />
					<ProductSelect
						handleUpdateValue={() => null}
						readonly={true}
						onClickHistoric={() => null}
						isHistoricVisible={false}
						dataProducts={products}
						handleAddProduct={() => null}
						handleDeleteProduct={() => null}
					/>
				</FormProvider>

				<Grid item xs={12} pb={4}>
					<Alert style={styles.divider} severity={"warning"}>
						<AlertTitle>{t("profile.alert_title")}</AlertTitle>
						{t("profile.alert_description")}
					</Alert>
				</Grid>
			</BodyPage>
			<DialogAlert
				show={dialogApiKey}
				title={t("profile.dialog_reset_title")}
				description={t("profile.dialog_reset_description")}
				accept={sendResetApiKey}
				decline={() => setDialogApiKey(false)}
			/>
			<SnackBar
				open={snackbar}
				handleClose={() => setSnackbar(false)}
				type={snackbarType}
				message={snackbarMessage}
			/>
		</ContainerPageFull>
	)
}
