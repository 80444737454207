import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { Autocomplete, Divider, Grid, TextField } from "@mui/material"

import { listOptionsOrganizations } from "../../apis/organization"
import { createTeam } from "../../apis/team"
import { BodyPage, ContainerPage } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { HeaderPage } from "../../components/header/page/header-page"
import { Loading } from "../../components/loading/loading"
import { ProductSelect } from "../../components/product-select/product-select"
import { ControlledTextField } from "../../components/text-field/controlled-text-field"
import { useAuthentication } from "../../hooks/app/useAuthentication"
import { pathNames } from "../../routes/paths"
import { AuthStorage } from "../../storage/auth-type"
import { getItem } from "../../storage/local-storage"
import { styles } from "../../styles/common"
import { createErrorMessage } from "../../utils/format"

export const CreateTeam = () => {
	const [loading, setLoading] = useState<boolean>(false)

	const [dialog, setDialog] = useState<boolean>(false)
	const [dialogTitle, setDialogTitle] = useState<any>("")
	const [dialogDescription, setDialogDescription] = useState<any>("")
	const [isError, setIsError] = useState<boolean>(false)

	const [organizations, setOrganizations] = useState<[]>([])
	const { organizationId } = getItem(AuthStorage.userInformation)
	const [organizationSelected, setOrganizationSelected] = useState<any>({
		id: organizationId,
	})

	const [products, setProducts] = useState<any>([])

	const methods = useForm()
	const {
		control,
		getValues,
		formState: { isValid },
		watch,
	} = methods

	const navigate = useNavigate()

	const { t } = useTranslation()

	const { isAdmin } = useAuthentication()

	useEffect(() => {
		getOrganizations()
	}, [])

	const getOrganizations = async () => {
		if (!isAdmin) return
		try {
			const {
				data: { data },
			} = await listOptionsOrganizations()
			setOrganizations(data)
		} catch (error) {
		} finally {
		}
	}

	const handleSubmit = async () => {
		const formValues = getValues()

		if (isValid) {
			const objTeam = {
				...formValues,
				organizationId: organizationSelected?.id,
				services: products,
			}

			if (!isAdmin && organizationId) objTeam.organizationId = organizationId

			setLoading(true)
			setIsError(false)
			try {
				await createTeam(objTeam)

				setDialogTitle(t("request_messages.create_success_title", { name: "Time" }))
				setDialogDescription(t("request_messages.success_description", { name: "time" }))
				setProducts([])
			} catch (error: any) {
				setIsError(true)
				setDialogTitle(t("request_messages.500"))
				if (error?.response?.data?.messages) {
					setDialogDescription(createErrorMessage(error?.response?.data?.messages))
				} else {
					setDialogDescription(t("request_messages.400"))
				}
			} finally {
				setDialog(true)
				setLoading(false)
			}
		}
	}

	const handleAutoComplete = (event: any, option: any) => {
		setOrganizationSelected(option)
	}

	const handleAddProduct = (product: any) => {
		setProducts([...products, product])
	}

	const handleDeleteProduct = (product: any) => {
		const objectProducts = products.filter(function (obj: any) {
			return obj.id !== product?.id
		})
		setProducts(objectProducts)
	}

	const handleUpdateValue = (data: any) => {
		setProducts(data)
	}

	const handleAccept = () => {
		setDialog(false)
		if (!isError) {
			navigate(pathNames.team.list)
			methods.reset()
		}
	}

	if (loading) return <Loading />
	return (
		<ContainerPage>
			<HeaderPage
				disabledButton={!isValid}
				onClickSubmit={handleSubmit}
				onClickTopButton={() => navigate(pathNames.team.list)}
				description={t("team.description_create_team")}
				isEdit={true}
				title={t("team.title_create_team")}
			/>
			<BodyPage>
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					<Grid item xs={6}>
						<ControlledTextField
							id={"name"}
							style={{ width: "100%" }}
							label={t("team.label_team")}
							control={control}
							rules={{ required: true }}
						/>
					</Grid>
					<Grid item xs={6}>
						<ControlledTextField
							id={"description"}
							style={{ width: "100%" }}
							label={t("team.label_description")}
							control={control}
							rules={{ required: true }}
						/>
					</Grid>
					{isAdmin && (
						<Grid item xs={12}>
							{organizations.length > 0 && (
								<Autocomplete
									style={styles.textFieldMarginTop}
									disablePortal
									size={"small"}
									options={organizations}
									defaultValue={organizationSelected}
									getOptionLabel={(option: any) => option.name || ""}
									onChange={handleAutoComplete}
									renderInput={(params) => (
										<TextField
											{...params}
											variant={"filled"}
											color={"secondary"}
											label={t("customer.label_organization")}
										/>
									)}
								/>
							)}
							{/* <FormControl
                style={{ width: '100%', margin: 0 }} variant="filled" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel color={'secondary'} id="demo-simple-select-filled-label">{t('team.label_organization')}</InputLabel>
                <Select
                  color={'secondary'}
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  style={{ height: 50 }}
                  onChange={handleSelect}
                  value={organizationSelected}
                >
                  {organizations.map((element: any) => (
                    <MenuItem value={element?.id}>{element?.name}</MenuItem>
                  ))}
                </Select>
              </FormControl> */}
						</Grid>
					)}
					<Grid item xs={12}>
						<Divider light style={styles.divider} />
					</Grid>
				</Grid>
				<ProductSelect
					organizationId={organizationSelected?.id}
					isHistoricVisible={false}
					dataProducts={products}
					handleUpdateValue={handleUpdateValue}
					handleAddProduct={handleAddProduct}
					handleDeleteProduct={handleDeleteProduct}
					isEditableUnlimited={false}
				/>
			</BodyPage>
			<DialogAlert
				onlyConfirm={true}
				show={dialog}
				accept={handleAccept}
				title={dialogTitle}
				description={dialogDescription}
			/>
		</ContainerPage>
	)
}
