import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { Grid, Typography } from "@mui/material"

import { Table } from "../../../components/table/table"
import { tablePrintStyles } from "../../../pages/consumption/invoicing-details/invoicing-details.styles"
import { LoadingTable } from "../../loading/loading"
import { Sankey } from "../sankey/sankey"

export type TeamDistribuitionDataset = {
	team: string
	service: string
	totalAmount: string | number
}

export type TeamDistribuitionGraphProps = {
	datasets: TeamDistribuitionDataset[]
	isPrinting?: boolean
	isLoading?: boolean
}

export function TeamDistribuitionGraph({
	isPrinting,
	datasets,
	isLoading,
}: TeamDistribuitionGraphProps) {
	const { t } = useTranslation()

	const [paginationQueries, setPaginationQueries] = useState({
		rowsPerPage: 5,
		pageNumber: 1,
	})

	const graphData = useMemo(() => {
		return datasets.map(({ team, service, totalAmount }) => [team, service, +totalAmount])
	}, [datasets])

	const columns = useMemo(
		() => [
			{
				label: t("columns.team"),
				id: "team",
			},
			{
				label: t("columns.service"),
				id: "service",
			},
			{
				label: t("columns.queries_amount"),
				id: "totalAmount",
			},
		],
		[],
	)

	const keys = useMemo(() => ["team", "service", "totalAmount"], [datasets, t])

	const handleChangeRowsPerPage = (rowsPerPage: number) =>
		setPaginationQueries((prevState) => ({ ...prevState, rowsPerPage }))

	const handleChangePage = (crrPage: number) => {
		setPaginationQueries((prevState) => ({ ...prevState, pageNumber: crrPage + 1 }))
	}

	if (isLoading) return <LoadingTable />

	if (!datasets || !datasets.length)
		return (
			<Typography variant="body2" align="center" color={"#626262"} mt={1}>
				NENHUM RESULTADO ENCONTRADO
			</Typography>
		)

	return (
		<Grid container columns={3} mt={2}>
			{!isPrinting && (
				<Grid item sm={3}>
					<Sankey
						data={graphData as any}
						tootip={{
							from: "De",
							to: "Para",
							value: "Consumiu",
						}}
					/>
				</Grid>
			)}

			<Table
				rowsPerPageOptions={[5, 10, 25]}
				rowsPerPage={isPrinting ? 100 : paginationQueries.rowsPerPage}
				changeRowsPerPage={handleChangeRowsPerPage}
				page={paginationQueries.pageNumber - 1}
				changePage={handleChangePage}
				onDelete={() => null}
				columns={columns}
				keys={keys}
				rows={datasets}
				onEdit={() => null}
				isEditVisible={false}
				pageCount={datasets.length}
				titleStyles={isPrinting ? tablePrintStyles : {}}
				cellStyles={isPrinting ? tablePrintStyles : {}}
				tableContainerStyles={isPrinting ? { overflowX: "initial" } : {}}
				hidePagination={isPrinting}
			/>
		</Grid>
	)
}
