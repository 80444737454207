import { CSVReportOption } from "../components/header/page/header-page"
import axios from "./config/axios"

export type DailyProductConsumption = {
	data: {
		serviceName: string
		usageData: {
			[K: string]: number
		}[]
	}[]
	success: boolean
}

export type ClientUsageData = {
	[key: string]: {
		requestType: string
		processingType: string
		usageLimit: number
	}
}

export type OrganizationClientConsumptionEvolution = {
	data: {
		clientName: string
		usageDate: string
		usageData: ClientUsageData
	}[]
}

export type MultiSelectData = {
	data: {
		id: string
		name: string
	}[]
}

export type AnalyticsByOrganizationUsersResult = {
	data: Array<{
		id: string
		userName: string
		usedLimit: number
		processingType: string
		email: string
	}>
}

export type AnalyticsByOrganizationTeamsServicesResult = {
	data: {
		teamName: string
		totalUsage: number
		services: Array<{
			serviceName: string
			teamName: string
			usedLimit: number
		}>
	}[]
}

export type SearchProcessingTypeConsumptionResult = {
	data: {
		processingType: string
		usedLimit: number
	}[]
}

export const searchConsumption = async (filter: any, date: any) =>
	await axios.get(`/dashboard/${filter}/${date}`)

export const searchConsumptionAdmin = async (date: any, organizationId: string) =>
	await axios.get(`/dashboard/byOrganizationServices/${date}?filterId=${organizationId}`)

export const searchRequestsByProduct = async (
	organizationIds: string[],
	startDate: string,
	endDate: string,
	services: (string | undefined)[],
) =>
	axios.get(
		`/dashboard/byOrganizationProducts/${organizationIds.join(
			",",
		)}?startDate=${startDate}&endDate=${endDate}${
			services?.length ? `&services=${services.join(",")}` : ""
		}`,
	)

export const searchProcessingTypeConsumption = async (
	organizationIds: string[],
	startDate: string,
	endDate: string,
	services: (string | undefined)[],
) => {
	const { data } = await axios.get<SearchProcessingTypeConsumptionResult>(
		`/dashboard/byOrganizationProcessingTypes/${organizationIds.join(
			",",
		)}?startDate=${startDate}&endDate=${endDate}${
			services?.length ? `&services=${services.join(",")}` : ""
		}`,
	)

	return data
}

export const searchDailyOrganizationProductConsumption = async (
	organizationIds: string[],
	startDate: string,
	endDate: string,
	services: (string | undefined)[],
) =>
	axios.get<DailyProductConsumption>(
		`/dashboard/byOrganizationProductConsumption/${organizationIds.join(
			",",
		)}?startDate=${startDate}&endDate=${endDate}${
			services?.length ? `&services=${services.join(",")}` : ""
		}`,
	)

export const findOrganizationConsumptionByRequestType = async (
	organizationIds: string[],
	startDate: string,
	endDate: string,
	services: (string | undefined)[],
) =>
	axios.get<DailyProductConsumption>(
		`/dashboard/findOrganizationConsumptionByRequestType/${organizationIds.join(
			",",
		)}?startDate=${startDate}&endDate=${endDate}${
			services?.length ? `&services=${services.join(",")}` : ""
		}`,
	)

export const searchOrganizationEvolutiveClientConsumption = async (
	organizationIds: string[],
	year: string,
	yearEnd: string,
	services: (string | undefined)[],
) =>
	axios.get<OrganizationClientConsumptionEvolution>(
		`/dashboard/byOrganizationEvolutiveClientConsumption/${organizationIds.join(
			",",
		)}?year=${year}&yearEnd=${yearEnd}${services?.length ? `&services=${services.join(",")}` : ""}`,
	)

export const searchClassifiedOrganizationConsumption = async (
	organizationIds: string[],
	startDate: string,
	endDate: string,
	services: (string | undefined)[],
) =>
	axios.get<DailyProductConsumption>(
		`/dashboard/byOrganizationClassiefiedConsumption/${organizationIds.join(
			",",
		)}?startDate=${startDate}&endDate=${endDate}${
			services?.length ? `&services=${services.join(",")}` : ""
		}`,
	)

export const searchServiceNamesByOrganization = async (organizationIds: string[]) =>
	axios.get<MultiSelectData>(`/dashboard/serviceNamesByOrganization/${organizationIds.join(",")}`)

export const downloadReport = async (
	organizationIds: string[],
	monthAndYear: string,
	localeStartDate: string,
	localeEndDate: string,
	services: (string | undefined)[],
) =>
	await axios.get(
		`/report/summary/${organizationIds.join(
			",",
		)}?monthYear=${monthAndYear}&startDate=${localeStartDate}&endDate=${localeEndDate}${
			services?.length ? "&services=" + services.join(",") : ""
		}`,
		{
			responseType: "blob",
		},
	)

export const downloadCSVReport = async (
	organizationIds: string[],
	monthAndYear: string,
	localeStartDate: string,
	localeEndDate: string,
	services: (string | undefined)[],
	reportType: CSVReportOption,
) =>
	await axios.get(
		`/report/csvSummary/${reportType}/${organizationIds.join(",")}?monthYear=${monthAndYear}&startDate=${localeStartDate}&endDate=${localeEndDate}${
			services?.length ? "&services=" + services.join(",") : ""
		}`,
		{
			responseType: "text",
		},
	)

export const getAnalyticsByOrganizationTeamsServices = async (
	startDate: string,
	endDate: string,
	organizationId?: string,
): Promise<AnalyticsByOrganizationTeamsServicesResult> => {
	const { data } = await axios.get<AnalyticsByOrganizationTeamsServicesResult>(
		`/dashboard/byOrganizationTeamsServices?startDate=${startDate}&endDate=${endDate}&organizationId=${organizationId}`,
	)

	return data
}

export const getAnalyticsByOrganizationUsers = async (
	startDate: string,
	endDate: string,
	organizationId?: string,
): Promise<AnalyticsByOrganizationUsersResult> => {
	const { data } = await axios.get<AnalyticsByOrganizationUsersResult>(
		`/dashboard/byOrganizationUsers?startDate=${startDate}&endDate=${endDate}&organizationId=${organizationId}`,
	)

	return data
}
