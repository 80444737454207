import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"

import { Delete } from "@mui/icons-material"
import EditIcon from "@mui/icons-material/Edit"
import VisibilityIcon from "@mui/icons-material/Visibility"
import {
	Button,
	Checkbox,
	IconButton,
	Snackbar,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	Table as TableMUI,
	TablePagination,
	TableRow,
	Toolbar,
	Typography,
} from "@mui/material"
import { alpha } from "@mui/material/styles"

import { GlobalContext } from "../../contexts/global-provider"
import { NotFound } from "../erros/not-found"
import { LoadingTable } from "../loading/loading"
import { rowCustomizations } from "./row-customizations/index"

type DataColumn = {
	label: string
	id: string
}

type TableProps = {
	columns: DataColumn[]
	rows: any[]
	keys: any[]
	onDelete?: (team: any) => void
	onEdit?: (team: any) => void
	isDeleteVisible?: boolean
	isEditVisible?: boolean
	isReadVisible?: boolean
	page?: number
	changePage?: (page: number) => void
	changeRowsPerPage?: (value: number) => void
	rowsPerPage?: number
	onClickLink?: (id: string, value: string) => void
	isSelectable?: boolean
	onDisableAll?: (selected: Array<string>) => void
	hidePagination?: boolean
	pageCount?: number
	errors?: any[]
	isActionVisible?: boolean
	handleClickOpen?: (row: any) => void
	isLoading?: boolean
	rowsPerPageOptions?: number[]
	titleStyles?: any
	cellStyles?: any
	tableContainerStyles?: any
	RowComponent?: React.ElementType<{ content: any; index: number }>
}

export const Table = ({
	columns,
	rows,
	keys,
	onEdit = () => {},
	isEditVisible = true,
	isReadVisible = true,
	page = 1,
	rowsPerPage = 10,
	changePage = () => {},
	changeRowsPerPage = () => {},
	isSelectable = false,
	onDisableAll,
	hidePagination = false,
	pageCount = rows?.length,
	isActionVisible,
	handleClickOpen,
	onDelete = () => {},
	isDeleteVisible,
	isLoading,
	rowsPerPageOptions = [10, 25, 100],
	titleStyles = {},
	cellStyles = {},
	tableContainerStyles = {},
	onClickLink,
	RowComponent,
}: TableProps) => {
	const { light } = useContext(GlobalContext)
	const { customizeRow, setSnackbar, snackbar } = rowCustomizations()

	// const [snackbar, setSnackbar] = useState<boolean>(false);

	const [selected, setSelected] = useState<any>([])

	const { t } = useTranslation()

	const handleChangePage = (event: unknown, newPage: number) => {
		changePage(newPage)
	}

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		changeRowsPerPage(+event.target.value)
		changePage(0)
	}

	const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
		const selectedIndex = selected.indexOf(id)
		let newSelected: readonly string[] = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			)
		}

		setSelected(newSelected)
	}
	const isSelected = (id: string) => selected.indexOf(id) !== -1

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = rows?.map((n) => n.id)
			setSelected(newSelected)
			return
		}
		setSelected([])
	}

	const handleDisableAll = () => {
		// @ts-ignore: Unreachable code error
		onDisableAll(selected)
	}

	if (isLoading) return <LoadingTable />
	if (rows?.length === 0) return <NotFound />

	return (
		<>
			{isSelectable && selected.length > 0 && (
				<ActionsSelect onClickDisable={handleDisableAll} numSelected={selected.length} />
			)}
			<TableContainer sx={{ ...styles.tableContainer, ...tableContainerStyles }}>
				<TableMUI stickyHeader aria-label="sticky table">
					<TableHead
						style={{
							minHeight: "20%",
						}}
					>
						<TableRow>
							{isSelectable && (
								<TableCell
									align={"left"}
									style={{
										fontWeight: "bold",
										color: light ? "#484848" : "white",
									}}
								>
									<Checkbox
										color={"secondary"}
										checked={rows?.length > 0 && selected.length === rows.length}
										onChange={handleSelectAllClick}
										inputProps={{
											"aria-label": "select all desserts",
										}}
									/>
								</TableCell>
							)}
							{columns.map((column, index) => (
								<TableCell
									key={index}
									align={"left"}
									style={{
										fontWeight: "bold",
										color: light ? "#484848" : "white",
										...titleStyles,
										wordWrap: "break-word",
										overflowWrap: "break-word",
										wordBreak: "break-word",
										whiteSpace: "normal",
										overflow: "hidden",
									}}
								>
									<span style={{ whiteSpace: "nowrap" }}>{column.label}</span>
								</TableCell>
							))}
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					{isReadVisible && (
						<TableBody>
							{rows?.map((row, index) => {
								const isItemSelected = isSelected(row.id)
								const labelId = `enhanced-table-checkbox-${index}`
								const rowStyles: { [key: string]: string | number } = {}
								if (row?.active === false) {
									rowStyles["opacity"] = 0.7
								}

								if (onClickLink) rowStyles["cursor"] = "pointer"

								if (RowComponent) {
									return <RowComponent key={index} index={index} content={row} />
								}

								return (
									<TableRow
										style={rowStyles}
										key={index}
										hover={!!onClickLink}
										onClick={() => onClickLink && onClickLink(row.id, row.name)}
									>
										{isSelectable && (
											<TableCell onClick={(event) => handleClick(event, row.id)}>
												<Checkbox
													color={"secondary"}
													checked={isItemSelected}
													inputProps={{
														"aria-labelledby": labelId,
													}}
												/>
											</TableCell>
										)}

										{keys.map((key, n) => (
											<TableCell
												key={n}
												align={"left"}
												style={{
													...cellStyles,
												}}
											>
												{customizeRow(row, key)}
											</TableCell>
										))}

										<TableCell align="right">
											{isEditVisible && (
												<IconButton onClick={() => onEdit(row)} color="secondary" component="label">
													<EditIcon />
												</IconButton>
											)}

											{isActionVisible && handleClickOpen ? (
												<div>
													<IconButton onClick={() => handleClickOpen(row)}>
														<VisibilityIcon />
													</IconButton>
												</div>
											) : null}

											{isDeleteVisible && (
												<IconButton onClick={() => onDelete(row)} color="error" component="label">
													<Delete />
												</IconButton>
											)}
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					)}
				</TableMUI>
			</TableContainer>
			{!hidePagination && (
				<TablePagination
					style={styles.tablePagination}
					rowsPerPageOptions={rowsPerPageOptions}
					component="div"
					count={pageCount}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage={t("table.rows_per_page")}
					labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t("table.of")} ${count}`}
				/>
			)}
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={snackbar}
				onClose={() => setSnackbar(false)}
				autoHideDuration={2000}
				message={t("common.copy")}
			/>
		</>
	)
}

interface ActionSelectProps {
	numSelected: number
	onClickDisable: () => void
}

const ActionsSelect = ({ numSelected, onClickDisable }: ActionSelectProps) => {
	const { t } = useTranslation()

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
				}),
			}}
		>
			<Typography sx={{ flex: "1 1 100%" }} variant="subtitle1">
				{numSelected} {t("table.selected")}
			</Typography>
			<Button onClick={onClickDisable} color={"error"}>
				{t("table.disable")}
			</Button>
		</Toolbar>
	)
}

const styles = {
	tablePagination: {
		alignItems: "center",
		display: "flex",
		justifyContent: "flex-start",
		height: "10%",
	},
	tableCell: { fontWeight: "bold", color: "#484848" },
	tableContainer: { maxHeight: "90%", minHeight: "90%" },
}
