import { useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import InputMask from "react-input-mask"

import CorporateFareIcon from "@mui/icons-material/CorporateFare"
import {
	Accordion,
	Autocomplete,
	Divider,
	FormControlLabel,
	Grid,
	Switch,
	TextField,
	Typography,
} from "@mui/material"
import AccordionDetails from "@mui/material/AccordionDetails"

import { getAddressByZipCode } from "../../../apis/address"
import { AccordionSummary } from "../../../components/accordion/accordion-summary"
import { FileUpload } from "../../../components/file-upload/file-upload"
import { ControlledTextField } from "../../../components/text-field/controlled-text-field"
import { getFeatureFlag } from "../../../config/feature-flags/config"
import { Flags } from "../../../config/feature-flags/flags"
import { CreateOrganizationData, Segment } from "../../../libs/zod/organization/create-organization"
import { styles } from "../../../styles/common"
import { extractNumbersFromStringAndJoin } from "../../../utils/format"

interface OrganizationDataSectionProps {
	segments: Segment[]
}

export const OrganizationDataSection = ({ segments }: OrganizationDataSectionProps) => {
	const [isZipCodeInvalid, setIsZipCodeInvalid] = useState<boolean>(false)
	const [helperTextZipCode, setHelperTextZipCode] = useState<any>("")

	const {
		formState: { errors },
		setValue,
		control,
		register,
		clearErrors,
		watch,
	} = useFormContext<CreateOrganizationData>()

	const { t } = useTranslation()

	const handleCEPChange = (e: any) => {
		const cepNumber = e.target.value
		const cepFormatted = extractNumbersFromStringAndJoin(cepNumber)
		if (cepFormatted.length === 8) {
			getAddressInformation(cepFormatted)
			setValue("address.zipCode", cepFormatted)
		}
		if (cepFormatted.length < 8) {
			setValue("address.street", "")
			setValue("address.complement", "")
			setValue("address.neighborhood", "")
			setValue("address.city", "")
			setValue("address.state", "")
			setHelperTextZipCode("")
			setIsZipCodeInvalid(false)
			setValue("address.zipCode", cepFormatted)
		}
	}

	const getAddressInformation = async (cep: string) => {
		try {
			const { data } = await getAddressByZipCode(cep)

			if (data?.erro) {
				setIsZipCodeInvalid(true)
				setHelperTextZipCode(t("organizations.invalid_ZipCode"))
			} else {
				const { logradouro, complemento, localidade, bairro, uf } = data
				setValue("address.street", logradouro)
				setValue("address.complement", complemento)
				setValue("address.neighborhood", bairro)
				setValue("address.city", localidade)
				setValue("address.state", uf)

				clearErrors("address.street")
				clearErrors("address.complement")
				clearErrors("address.neighborhood")
				clearErrors("address.city")
				clearErrors("address.state")
			}
		} catch (error) {
			console.log(error)
		}
	}

	const hasError = () => {
		return !!(
			errors.name ||
			errors.cnpj ||
			errors.segment ||
			errors.imageUrl ||
			errors.address?.country ||
			errors.address?.zipCode ||
			errors.address?.street ||
			errors.address?.number ||
			errors.address?.complement ||
			errors.address?.neighborhood ||
			errors.address?.city ||
			errors.address?.state
		)
	}

	const active = watch("active")

	return (
		<Accordion>
			<AccordionSummary
				id="panel1a"
				error={hasError()}
				title={t("organizations.according.organization_information")}
				icon={<CorporateFareIcon style={{ marginRight: 8 }} color={"secondary"} />}
			/>
			<AccordionDetails>
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					<Grid item xs={6}>
						<ControlledTextField
							id={"name"}
							style={styles.fullWidth}
							label={t("organizations.label_name_organization")}
							control={control}
							disabled={!active}
							errors={errors.name}
							helperText={(errors.name?.message as string) ?? null}
						/>
					</Grid>
					<Grid item xs={4}>
						<Controller
							control={control}
							name="cnpj"
							render={({ field: { value, onChange } }) => (
								<InputMask
									value={value}
									mask={"99.999.999/9999-99"}
									alwaysShowMask
									disabled={!active}
									onChange={onChange}
								>
									<TextField
										helperText={errors.cnpj?.message as string}
										error={!!errors.cnpj?.message}
										label={"CNPJ"}
										placeholder={t("organizations.CNPJ") || ""}
										color="secondary"
										size="small"
										variant="filled"
										style={{ lineHeight: 50, width: "100%" }}
									/>
								</InputMask>
							)}
						/>
					</Grid>

					<Grid style={styles.active} item xs={2}>
						<Controller
							control={control}
							name="active"
							render={({ field: { value, ...fieldValues } }) => (
								<FormControlLabel
									control={<Switch {...fieldValues} color={"secondary"} checked={value} />}
									label={
										<Typography color={"secondary"}>{`${value ? "Ativo" : "Inativo"}`}</Typography>
									}
								/>
							)}
						/>
					</Grid>

					<Grid item xs={6}>
						<Controller
							control={control}
							name="segment"
							render={({ field: { onChange, value } }) => (
								<Autocomplete
									disablePortal
									id={"segment"}
									options={segments}
									style={styles.textFieldMarginTop}
									disabled={!active}
									size={"small"}
									onChange={(_, data) => onChange(data ? data?.key : null)}
									getOptionLabel={(option: any) => option?.value}
									value={value ? segments.find((segment) => segment?.key === value) ?? null : null}
									renderInput={(params) => (
										<TextField
											{...params}
											variant={"filled"}
											name={"segment"}
											color={"secondary"}
											label={t("organizations.segment")}
										/>
									)}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={6}>
						{getFeatureFlag(Flags.isUploadLogoActivated) ? (
							<FileUpload title={t("organizations.upload_file")} />
						) : (
							<ControlledTextField
								id={"imageUrl"}
								label={t("organizations.label_organization_logo")}
								control={control}
								disabled={!active}
								style={styles.textFieldMarginTop}
								errors={errors.imageUrl}
								helperText={(errors.imageUrl?.message as string) ?? null}
							/>
						)}
					</Grid>
				</Grid>
				<Divider style={styles.divider} />
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					<Grid item xs={6}>
						<ControlledTextField
							id={"address.country"}
							label={t("organizations.label_country")}
							control={control}
							readOnly
							disabled={!active}
							style={styles.fullWidth}
							errors={!!errors?.address?.country?.message as boolean}
							helperText={(errors?.address?.country?.message as string) ?? null}
						/>
					</Grid>
					<Grid item xs={6}>
						<Controller
							control={control}
							{...register("address.zipCode")}
							rules={{ required: true }}
							render={({ field: { value } }) => (
								<InputMask
									value={value}
									mask={"99999-999"}
									alwaysShowMask
									disabled={!active}
									onChange={handleCEPChange}
								>
									<TextField
										helperText={helperTextZipCode}
										error={isZipCodeInvalid}
										label={"CEP"}
										placeholder={t("organizations.label_cep") || ""}
										color="secondary"
										size="small"
										variant="filled"
										style={{ lineHeight: 50, width: "100%" }}
									/>
								</InputMask>
							)}
						/>
					</Grid>

					<Grid item xs={6}>
						<ControlledTextField
							id={"address.street"}
							label={t("organizations.label_address")}
							control={control}
							disabled={!active}
							rules={{ required: true }}
							style={styles.textFieldMarginTop}
							errors={!!errors?.address?.street?.message as boolean}
							helperText={(errors?.address?.street?.message as string) ?? null}
						/>
					</Grid>
					<Grid item xs={2}>
						<ControlledTextField
							id={"address.number"}
							label={t("organizations.label_address_number")}
							control={control}
							disabled={!active}
							rules={{ required: true }}
							style={styles.textFieldMarginTop}
							errors={!!errors?.address?.number?.message as boolean}
							helperText={(errors?.address?.number?.message as string) ?? null}
						/>
					</Grid>
					<Grid item xs={4}>
						<ControlledTextField
							id={"address.complement"}
							label={t("organizations.label_address_complement")}
							control={control}
							rules={{ required: true }}
							disabled={!active}
							style={styles.textFieldMarginTop}
							errors={!!errors?.address?.complement?.message as boolean}
							helperText={(errors?.address?.complement?.message as string) ?? null}
						/>
					</Grid>
					<Grid item xs={4}>
						<ControlledTextField
							id={"address.neighborhood"}
							label={t("organizations.label_address_neighborhood")}
							control={control}
							disabled={!active}
							rules={{ required: true }}
							style={styles.textFieldMarginTop}
							errors={!!errors?.address?.neighborhood?.message as boolean}
							helperText={(errors?.address?.neighborhood?.message as string) ?? null}
						/>
					</Grid>
					<Grid item xs={4}>
						<ControlledTextField
							id={"address.city"}
							label={t("organizations.label_address_city")}
							control={control}
							disabled={!active}
							rules={{ required: true }}
							style={styles.textFieldMarginTop}
							errors={!!errors?.address?.city?.message as boolean}
							helperText={(errors?.address?.city?.message as string) ?? null}
						/>
					</Grid>
					<Grid item xs={4}>
						<ControlledTextField
							id={"address.state"}
							label={t("organizations.label_address_state")}
							control={control}
							disabled={!active}
							rules={{ required: true }}
							style={styles.textFieldMarginTop}
							errors={!!errors?.address?.state?.message as boolean}
							helperText={(errors?.address?.state?.message as string) ?? null}
						/>
					</Grid>
				</Grid>
			</AccordionDetails>
		</Accordion>
	)
}
