import * as React from "react"
import { useTranslation } from "react-i18next"

import { Divider } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

type FilterRootProps = {
	children?: React.ReactNode
	ActionButtons?: React.ElementType
	isOpen: boolean
	onClose: () => void
}

export const FilterRoot = ({ children, ActionButtons, isOpen, onClose }: FilterRootProps) => {
	const { t } = useTranslation()

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth={"sm"}
			fullWidth={true}
		>
			<DialogTitle id="alert-dialog-title">{t("filter.filter_title")}</DialogTitle>
			<Divider />
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{children}</DialogContentText>
			</DialogContent>
			<DialogActions>{ActionButtons && <ActionButtons />}</DialogActions>
		</Dialog>
	)
}
