import { useMemo } from "react"
import { useFieldArray, useFormContext } from "react-hook-form"

import { Typography } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { enqueueSnackbar } from "notistack"

import { IpsListToolbar } from "./toolbar/ips-list-toolbar"

export type IPsListItem = {
	id: string
	ip: string
	active: boolean
}

export type IPsListProps = {
	ips: IPsListItem[]
	setIps: (ips: IPsListItem[]) => void
}

export const IPsList = ({ ips, setIps }: IPsListProps) => {
	const columns: GridColDef<IPsListItem>[] = [
		{
			field: "ip",
			headerName: "Endereço de IP",
			flex: 1,
			type: "string",
			editable: false,
			renderCell: ({ value, row }) => (
				<Typography style={{ color: row.active ? "inherit" : "rgba(0, 0, 0, 0.38)" }}>
					{value}
				</Typography>
			),
		},
		{
			field: "active",
			headerName: "Liberado",
			flex: 1,
			type: "boolean",
			align: "left",
			headerAlign: "left",
			editable: true,
		},
	]
	const { control } = useFormContext()
	const {
		append: appendIpBlock,
		fields: ipBlocks,
		remove: removeIpBlock,
	} = useFieldArray({
		control,
		name: "ipBlocks",
	})
	const {
		append: appendIpLiberation,
		fields: ipLiberations,
		remove: removeIpLiberation,
	} = useFieldArray({
		control,
		name: "ipLiberations",
	})

	const onUpdate = (newRow: IPsListItem) => {
		const alreadyBlocked = ipBlocks.find(({ ip }: any) => ip === newRow.ip)
		const alreadyLiberated = ipLiberations.find(({ ip }: any) => ip === newRow.ip)
		const index = ips.findIndex(({ ip }) => ip === newRow.ip)

		if (!newRow.active && !alreadyBlocked && !alreadyLiberated) {
			appendIpBlock({
				id: newRow.ip,
				ip: newRow.ip,
			})
			if (index !== -1) ips[index].active = false
		}

		if (newRow.active && alreadyBlocked) {
			removeIpBlock(ipBlocks.findIndex(({ id }) => id === newRow.ip))
			if (index !== -1) ips[index].active = true
		}

		if (newRow.active && !alreadyBlocked) {
			appendIpLiberation({
				id: newRow.ip,
				ip: newRow.ip,
			})

			if (index !== -1) ips[index].active = true
		}

		if (!newRow.active && alreadyLiberated) {
			removeIpLiberation(ipLiberations.findIndex(({ id }) => id === newRow.ip))
			if (index !== -1) ips[index].active = false
		}

		setIps([...ips])

		return newRow
	}

	const onBlockIpsUpload = (ips: IPsListItem[]) => {
		const blockedIps = ips.filter(
			({ ip }) => !ipBlocks.find(({ ip: blockedIp }: any) => blockedIp === ip),
		).length

		if (blockedIps) {
			ips.forEach(onUpdate)
			enqueueSnackbar(
				`${blockedIps} IP(s) bloqueado(s) com sucesso. Para desbloquear, basta marcar a opção de liberação.`,
				{
					variant: "success",
				},
			)
		} else {
			enqueueSnackbar("Os IPs da lista provida já estão bloqueados ", {
				variant: "warning",
			})
		}
	}

	const ipsBlockedTotal = useMemo(() => ips.filter(({ active }) => !active).length, [ips])

	return (
		<div style={{ height: "450px", width: "100%" }}>
			<Typography color={"secondary"} variant={"h6"}>
				IPs
				<span style={{ marginLeft: "4px", fontSize: "13px", opacity: "0.75" }}>
					(Total: {ips.length} | Liberados: {ips.length - ipsBlockedTotal} | Bloqueados:{" "}
					{ipsBlockedTotal})
				</span>
			</Typography>

			<Typography color="textPrimary" variant={"body2"} style={{ marginBottom: "16px" }}>
				Lista com todos os IPs e sua situação de liberação. Para bloquear um IP, basta desmarcar a
				opção de liberação.
			</Typography>
			<DataGrid
				editMode="row"
				rowSelection={false}
				processRowUpdate={onUpdate}
				rows={ips}
				columns={columns}
				slots={{
					toolbar: IpsListToolbar,
				}}
				slotProps={{
					toolbar: {
						onFileUpload: onBlockIpsUpload,
					},
				}}
			/>
		</div>
	)
}
