import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { CheckCircle, Error, Loop, Pending } from "@mui/icons-material"

import { searchObitoDatabaseUpdates } from "../../../apis/services"
import { BodyPage, ContainerPage } from "../../../components/container/container.styles"
import { HeaderPage } from "../../../components/header/page/header-page"
import { LoadingTable } from "../../../components/loading/loading"
import { IKey, IKeyConfig } from "../../../components/table/row-customizations"
import { Table } from "../../../components/table/table"
import { Police } from "../../../models/Police"
import { pathNames } from "../../../routes/paths"
import { AuthStorage } from "../../../storage/auth-type"
import { getItem } from "../../../storage/local-storage"

export const ListObitoDatabaseUpdate = () => {
	const [showFilter, setShowFilter] = useState<boolean>(false)
	const [active, setActive] = useState<any>("enable")

	const [customerPolices, setCustomersPolices] = useState<Police>()

	const [rows, setRows] = useState<[]>([])
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [pageSize, setPageSize] = useState<number>(10)
	const [pageCount, setPageCount] = useState<number>(0)
	const [filter, setFilter] = useState<string>("")

	const [loading, setLoading] = useState<boolean>(true)

	const navigate = useNavigate()

	const { t } = useTranslation()

	const columns = [
		{
			label: t("columns.name"),
			id: "fileName",
		},
		{
			label: t("columns.description"),
			id: "description",
		},
		{
			label: t("columns.sent"),
			id: "sent",
		},
		{
			label: t("columns.imported"),
			id: "imported",
		},
		{
			label: t("columns.updated"),
			id: "updated",
		},
		{
			label: t("columns.linked"),
			id: "linked",
		},
		{
			label: t("columns.rejected"),
			id: "rejected",
		},
		{
			label: t("columns.start_date"),
			id: "processingStart",
		},
		{
			label: t("columns.end_date"),
			id: "processingEnd",
		},
		{
			label: t("columns.status"),
			id: "status",
		},
	]

	const keys: IKey<any>[] = [
		{
			isDownload: true,
			value: "fileUrl",
			id: "fileUrl",
			config: { textProp: "fileName" },
		} as IKeyConfig<"isDownload">,
		"description",
		"sent",
		"imported",
		"updated",
		"linked",
		"rejected",
		{ isDate: true, value: "processingStart", id: "processingStart" },
		{ isDate: true, value: "processingEnd", id: "processingEnd" },
		{ isPill: true, value: "status", id: "status" },
	]

	useEffect(() => {
		const { services } = getItem(AuthStorage.userPolicy)
		setCustomersPolices(services)
	}, [t])

	useEffect(() => {
		listUpdates()
		// eslint-disable-next-line
	}, [pageSize, currentPage, filter])

	const listUpdates = async (status?: any) => {
		try {
			setLoading(true)
			const {
				data: { data },
			} = await searchObitoDatabaseUpdates({
				currentPage,
				pageSize,
				filter,
				admin: true,
				active: status,
			})

			const statusStyles = {
				"Não processado": {
					color: "warning",
					icon: <Pending />,
				},
				"Em processamento": {
					color: "info",
					icon: <Loop />,
				},
				Processado: {
					color: "success",
					icon: <CheckCircle />,
				},
				"Erro no processamento": {
					color: "error",
					icon: <Error />,
				},
			}
			const list = data?.list.map((item: any) => ({
				...item,
				// Remove o prefixo do nome do arquivo
				fileName: item.fileName.replace(/^[^_]+_/, ""),
				fileUrl: `/${item.fileName}`,
				description: item.description || "-",
				statusPillIcon: statusStyles[item.status as keyof typeof statusStyles]?.icon,
				statusPillColor: statusStyles[item?.status as keyof typeof statusStyles]?.color,
			}))

			setRows(list)
			setPageCount(data?.total)
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	// const selectActiveState = (state: string) => {
	// 	setShowFilter(false)
	// 	setActive(state)
	// 	if (state === "all") {
	// 		listServices(null)
	// 	} else if (state === "enable") {
	// 		listServices(true)
	// 	} else if (state === "disable") {
	// 		listServices(false)
	// 	}
	// }

	return (
		<ContainerPage>
			<HeaderPage
				title={t("menu.update_obito_database")}
				searchValue={filter}
				onSearch={(event) => setFilter(event?.target?.value)}
				isCreateButtonVisible={customerPolices?.create}
				onOpenFilter={() => setShowFilter(true)}
				buttonTitle={t("update_obito_database.create_button")}
				onClickTopButton={() => navigate(pathNames.services.obitoDatabaseUpdate.create)}
			/>
			<BodyPage>
				{!loading ? (
					<Table
						page={currentPage - 1}
						rowsPerPage={pageSize}
						pageCount={pageCount}
						changeRowsPerPage={(value) => setPageSize(value)}
						changePage={(value) => setCurrentPage(value + 1)}
						onDelete={() => null}
						onEdit={() => null}
						columns={columns}
						rows={rows}
						keys={keys}
						isEditVisible={false}
					/>
				) : (
					<LoadingTable />
				)}
			</BodyPage>
			{/* <Filter
				show={showFilter}
				apply={(e) => selectActiveState(e)}
				cancel={() => setShowFilter(false)}
				value={active}
			/> */}
		</ContainerPage>
	)
}
