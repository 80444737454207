import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { CloudDownload, Search as SearchIcon } from "@mui/icons-material"
import { Box, Button, Grid } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"

import {
	downloadAuditLogsAsCSV,
	getAuditLogDetails,
	LogDetailsResponse,
	LogResponse,
	searchAuditLogs,
} from "../../apis/audit-log"
import { BodyPage, ContainerPage } from "../../components/container/container.styles"
import { HeaderPage } from "../../components/header/page/header-page"
import { HeaderPageTopButton } from "../../components/header/page/header-page-top-button"
import { Loading } from "../../components/loading/loading"
import { MultiSelectionField } from "../../components/multi-selection-field/multi-selection-field"
import { Table } from "../../components/table/table"
import { useAuthentication } from "../../hooks/app/useAuthentication"
import { createBlobAndDownload } from "../../utils/fileDownload"
import { flattenObject } from "../../utils/flattenObject"
import { Modal } from "./components/modal/modal"
import { detailsTableColumns, detailsTableKeys, listTableColumns, listTableKeys } from "./tables"

type NormalizedDetailRow = {
	rawKey: string
	item: JSX.Element
	oldValue: JSX.Element | string | object
	newValue: JSX.Element | string | object
}

type MultiSelectionItem = {
	label: string
	value: string
}

export const AuditLogsPage = () => {
	const [rows, setRows] = useState<LogResponse[]>([])
	const [total, setTotal] = useState<number>(0)
	const [loading, setLoading] = useState<boolean>(false)
	const [currentLog, setCurrentLog] = useState<NormalizedDetailRow[] | null>()
	const [currentLogTitle, setCurrentLogTitle] = useState<string>("")
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [pageSize, setPageSize] = useState<number>(10)
	const [startDate, setStartDate] = useState<Dayjs>(dayjs().subtract(7, "day"))
	const [endDate, setEndDate] = useState<Dayjs>(dayjs())
	const [operations, setOperations] = useState<string[]>([])
	const [contexts, setContexts] = useState<string[]>([])

	const { t } = useTranslation()
	const { isAdmin } = useAuthentication()

	const getValues = (options: MultiSelectionItem[], selected: string[]) => {
		return options.filter((op) => selected.some((f) => f === op.label)).map((op) => op.value)
	}

	const fetchData = async (params?: { resetPage: boolean }) => {
		setLoading(true)
		try {
			const operationsValues = getValues(operationOptions, operations)
			const contextValues = getValues(contextOptions, contexts)

			const {
				data: { data: logData },
			} = await searchAuditLogs({
				startDate: startDate.toDate().toLocaleDateString("en-US", {
					month: "2-digit",
					day: "2-digit",
					year: "numeric",
				}),
				endDate: endDate.toDate().toLocaleDateString("en-US", {
					month: "2-digit",
					day: "2-digit",
					year: "numeric",
				}),
				currentPage: params?.resetPage ? 1 : currentPage,
				pageSize,
				operations: operationsValues,
				tables: contextValues,
			})

			const formattedData = logData?.list.map((log) => ({
				...log,
				context: log.tableName,
				organizationId: log.organization?.id,
				organizationName: log.organization?.name,
				authorName: log.author?.email,
				authorId: log.author?.id,
			}))

			setRows(formattedData)
			setTotal(logData.total)
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}

	const isISODate = (date: string) => {
		if (!date || typeof date !== "string") return false
		return date.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)
	}

	const isURL = (url: string) => {
		if (!url || typeof url !== "string") return false
		return /^https?/.test(url)
	}

	const translateOrValue = (keys: string[], value: string) => {
		if (isURL(value)) return value
		if (isISODate(value)) return dayjs(value).format("DD/MM/YYYY HH:mm:ss")
		const key = keys.join(".")
		const translated = t(`audit_logs.${key}`)
		return translated === `audit_logs.${key}` ? value : translated
	}

	const onClickDetails = async ({ id }: any) => {
		try {
			const { data } = await getAuditLogDetails(id)
			const emphasisWrapper = (value: string) => <b>{value}</b>
			const opaqueWrapper = (value: string) => <span style={{ opacity: 0.5 }}>{value}</span>
			setCurrentLogTitle(`${data.operation} - ${data.tableName}`)
			const recordFlattened = flattenObject(data.record)
			const oldRecordFlattened = flattenObject(data.oldRecord)
			const allKeys = new Set([...Object.keys(recordFlattened), ...Object.keys(oldRecordFlattened)])
			const rows = Array.from(allKeys)
				.map((key: string) => {
					const oldValue = !recordFlattened
						? ""
						: oldRecordFlattened[key as keyof LogDetailsResponse["oldRecord"]]
					const newValue = !recordFlattened
						? ""
						: recordFlattened[key as keyof LogDetailsResponse["record"]]

					const traslatedOldValue = translateOrValue(["item", oldValue], oldValue)
					const traslatedNewValue = translateOrValue(["item", newValue], newValue)

					let translatedKey = ""
					const keyParts = key.split(".")
					keyParts.forEach((part, index) => {
						const isLastPart = index === keyParts.length - 1
						const arrayIndex = part.match(/\[(\d+)\]/)
						if (arrayIndex) {
							translatedKey += `Item ${+arrayIndex[1] + 1}`
						} else {
							translatedKey += translateOrValue(["item", part], part)
						}
						if (!isLastPart) translatedKey += " ➤ "
					})

					if (key.toLocaleLowerCase().includes("id")) return null
					if (!traslatedOldValue && !traslatedNewValue) return null

					const hasDiff = oldValue !== newValue

					return {
						rawKey: translatedKey,
						item: hasDiff ? emphasisWrapper(translatedKey) : opaqueWrapper(translatedKey),
						oldValue: hasDiff
							? emphasisWrapper(traslatedOldValue)
							: opaqueWrapper(traslatedOldValue),
						newValue: hasDiff
							? emphasisWrapper(traslatedNewValue)
							: opaqueWrapper(traslatedNewValue),
					} as NormalizedDetailRow
				})
				.filter((row) => row !== null)
				.sort((a: NormalizedDetailRow | null, b: NormalizedDetailRow | null) => {
					if (a == null || b == null) return 0
					return a.rawKey.localeCompare(b.rawKey)
				})

			setCurrentLog(rows as NormalizedDetailRow[])
		} catch (error) {
			console.error("🚀 ~ onClickDetails ~ error:", error)
		}
	}

	const handleChangePageSize = (pageSize: number) => {
		setPageSize(pageSize)
	}

	const handleChangePage = (page: number) => {
		setCurrentPage(page + 1)
	}

	const handleClose = () => setCurrentLog(null)

	useEffect(() => {
		fetchData()
	}, [currentPage, pageSize])

	useEffect(() => {
		fetchData()
	}, [])

	const operationOptions = [
		{
			label: "Atualização",
			value: "UPDATE",
		},
		{
			label: "Exclusão",
			value: "DELETE",
		},
		{
			label: "Inclusão",
			value: "INSERT",
		},
	]
	const contextOptions = [
		{ label: "Campos de consulta de serviço", value: "service_batch_fields", adminOnly: true },
		{ label: "Chaves de API", value: "apiKeys" },
		{ label: "Dados de lead", value: "lead_data", adminOnly: true },
		{ label: "Endereços", value: "addresses" },
		{ label: "Equipes", value: "teams" },
		{ label: "Faixas de custo de serviço", value: "service_cost_range" },
		{ label: "Faixas de IP", value: "organization_ip_ranges" },
		{ label: "Faturas", value: "invoices", adminOnly: true },
		{ label: "Limites de serviço", value: "service_limits" },
		{ label: "Organizações", value: "organizations" },
		{ label: "Políticas de acesso", value: "policies", adminOnly: true },
		{ label: "Projetos", value: "projects", adminOnly: true },
		{ label: "Serviços", value: "services", adminOnly: true },
		{ label: "Usuários", value: "users" },
	]

	const onCSVExport = async () => {
		try {
			const operationsValues = getValues(operationOptions, operations)
			const contextValues = getValues(contextOptions, contexts)
			const csvContent = await downloadAuditLogsAsCSV({
				startDate: startDate.toDate().toLocaleDateString("en-US", {
					month: "2-digit",
					day: "2-digit",
					year: "numeric",
				}),
				endDate: endDate.toDate().toLocaleDateString("en-US", {
					month: "2-digit",
					day: "2-digit",
					year: "numeric",
				}),
				currentPage,
				pageSize,
				operations: operationsValues,
				tables: contextValues,
			})

			const fileName = `audit-logs-${startDate.format("DD-MM-YYYY")}_${endDate.format("DD-MM-YYYY")}.csv`
			createBlobAndDownload([csvContent], fileName)
		} catch (error) {}
	}

	if (loading) return <Loading />

	return (
		<ContainerPage
			style={{
				overflow: "auto",
			}}
		>
			<Box>
				<HeaderPage
					title={t("menu.logs")}
					onClickTopButton={() => null}
					isChartVisible
					hasEndDate
					onChangeEndDate={() => null}
					defaultStartDate={dayjs().subtract(7, "day")}
					showDownloadButton
					topButton={
						<HeaderPageTopButton onClick={onCSVExport} icon={<CloudDownload />}>
							{t("headerPage.export_as_file")}
						</HeaderPageTopButton>
					}
				>
					<Grid container spacing={1} columns={12} mt={0.5}>
						<Grid item xs={6} sm={2} md={2}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									sx={{ width: "100%" }}
									onChange={(date) => setStartDate(dayjs(date))}
									defaultValue={startDate}
									views={["year", "month", "day"]}
									label={t("headerPage.start_date")}
									format="DD/MM/YYYY"
									openTo="year"
								/>
							</LocalizationProvider>
						</Grid>

						<Grid item xs={6} sm={2} md={2}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									sx={{ width: "100%" }}
									onChange={(date) => setEndDate(dayjs(date))}
									defaultValue={endDate}
									views={["year", "month", "day"]}
									label={t("headerPage.end_date")}
									format="DD/MM/YYYY"
									openTo="year"
									className="full-width"
								/>
							</LocalizationProvider>
						</Grid>

						<Grid item xs={6} sm={2} md={2}>
							<MultiSelectionField
								id="operations-field"
								options={operationOptions.map((op) => op.label)}
								optionsSelected={operations}
								label={t("audit_logs.table.operation")}
								onChange={(value) => setOperations(value)}
								checkedValidation={(item) => operations.some((f) => f === item)}
							/>
						</Grid>

						<Grid item xs={6} sm={2} md={2}>
							<MultiSelectionField
								id="contexts-field"
								options={contextOptions
									.filter((op) => (isAdmin ? true : !op.adminOnly))
									.map((op) => op.label)}
								optionsSelected={contexts}
								label={t("audit_logs.table.context")}
								onChange={(value) => setContexts(value)}
								checkedValidation={(item) => contexts.some((f) => f === item)}
							/>
						</Grid>
						<Grid item xs={6} sm={2} md={2}>
							<Button
								color="secondary"
								style={{
									textTransform: "initial",
									height: "100%",
									marginLeft: "0.5rem",
								}}
								variant={"contained"}
								onClick={() => fetchData({ resetPage: true })}
								disabled={loading}
							>
								<SearchIcon sx={{ marginRight: "0.5rem" }} />
								{t("headerPage.search")}
							</Button>
						</Grid>
					</Grid>
				</HeaderPage>
			</Box>
			<BodyPage>
				<Table
					page={currentPage - 1}
					rowsPerPage={pageSize}
					changeRowsPerPage={handleChangePageSize}
					changePage={handleChangePage}
					onDelete={() => null}
					columns={listTableColumns}
					rows={rows}
					keys={listTableKeys}
					onEdit={() => {}}
					pageCount={total}
					isActionVisible
					handleClickOpen={onClickDetails}
					isEditVisible={false}
				/>

				{currentLog ? (
					<Modal onClose={handleClose} title={currentLogTitle}>
						<Table
							columns={detailsTableColumns}
							rows={currentLog}
							keys={detailsTableKeys}
							changeRowsPerPage={() => null}
							changePage={() => null}
							onDelete={() => null}
							onEdit={() => {}}
							isEditVisible={false}
							hidePagination
						/>
					</Modal>
				) : null}
			</BodyPage>
		</ContainerPage>
	)
}
