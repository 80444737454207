import { useEffect, useState } from "react"
import { BrowserRouter, Route, Routes as RoutesDom } from "react-router-dom"

import { NotistackProvider } from "../contexts/notistack-context"
import { useAuthentication } from "../hooks/app/useAuthentication"
import { CreateAPIKey } from "../pages/api-key/create-api-key"
import { EditAPIKey } from "../pages/api-key/edit-api-key"
import { ListAPIKey } from "../pages/api-key/list-api-key"
import { AuditLogsPage } from "../pages/audit-logs/audit-logs-page"
import { DataExtraction } from "../pages/consumption/data-extraction/data-extraction"
import { InvoiceDetailsPage } from "../pages/consumption/invoicing-details/invoice-details"
import { InvoicingPage } from "../pages/consumption/invoicing/list-invoicing"
import { Dashboard } from "../pages/dashboard/dashboard"
import { Documentation } from "../pages/docs/documentation"
import Home from "../pages/home/home"
import { CreateIP } from "../pages/ip-liberation/create-ip"
import { EditIp } from "../pages/ip-liberation/edit-ip"
import { ListIps } from "../pages/ip-liberation/list-ip"
import SignIn from "../pages/login/sign-in"
import { SignOut } from "../pages/login/sign-out"
import { CreateOrganization } from "../pages/organization/create-organization"
import { EditOrganization } from "../pages/organization/edit-organization"
import { ListOrganization } from "../pages/organization/list-organization"
import { Profile } from "../pages/profile/profile"
import { ProfileOrganization } from "../pages/profile/profile-organization"
import { CreateProject } from "../pages/project/create-project"
import { EditProject } from "../pages/project/edit-project"
import { ListProject } from "../pages/project/list-project"
import { CreateAdvancedSearch } from "../pages/service/advancedSearch/advancedSearch"
import { CreateServiceBatchQuery } from "../pages/service/batch/create-service-batch"
import { ListBatchServiceQuery } from "../pages/service/batch/list-service-batch"
import { CreateService } from "../pages/service/create-service"
import { EditService } from "../pages/service/edit-service"
import { ListService } from "../pages/service/list-service"
import { CreateObitoDatabaseUpdate } from "../pages/service/obitoDatabaseUpdate/create"
import { ListObitoDatabaseUpdate } from "../pages/service/obitoDatabaseUpdate/list"
import { Services } from "../pages/service/services"
import { CreateTeam } from "../pages/team/create-team"
import { EditTeam } from "../pages/team/edit-team"
import ListTeam from "../pages/team/list-team"
import { CreateUser } from "../pages/user/create-user"
import { EditUsers } from "../pages/user/edit-user"
import { ListUsers } from "../pages/user/list-user"
import LabRoute from "./lab-route"
import { pathNames } from "./paths"
import PrivateRoute from "./private-route"
import { PublicRoute } from "./public-route"

const Routes = () => {
	const [isAuthenticated, setIsAuthenticated] = useState(false)

	const { isAuthenticated: isAuth } = useAuthentication()

	useEffect(() => {
		setIsAuthenticated(isAuth)
	}, [])

	return (
		<BrowserRouter>
			<RoutesDom>
				<Route element={<NotistackProvider />}>
					<Route element={<PublicRoute />}>
						<Route path={pathNames.home} element={<Home />} />
						<Route path={pathNames.auth.signOut} element={<SignOut />} />
					</Route>

					{/* Auth's Routes */}
					<Route
						path={pathNames.auth.signIn}
						element={
							<PublicRoute showFooter={false} showHeader={false}>
								<SignIn />
							</PublicRoute>
						}
					/>

					<Route
						path={pathNames.auth.signOut}
						element={
							<PublicRoute>
								<SignOut />
							</PublicRoute>
						}
					/>

					{/* Playground's Routes  */}
					<Route path={pathNames.lab.root} element={<LabRoute />} />

					{/* Service's Routes */}
					<Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
						<Route path={pathNames.service.list} element={<ListService />} />
						<Route path={pathNames.service.create} element={<CreateService />} />
						<Route path={`${pathNames.service.edit}:serviceId`} element={<EditService />} />
						<Route
							path={`${pathNames.services.obitoDatabaseUpdate.list}`}
							element={<ListObitoDatabaseUpdate />}
						/>
						<Route
							path={`${pathNames.services.obitoDatabaseUpdate.create}`}
							element={<CreateObitoDatabaseUpdate />}
						/>

						{/* Project's Routes */}
						<Route path={pathNames.project.list} element={<ListProject />} />
						<Route path={pathNames.project.create} element={<CreateProject />} />
						<Route path={`${pathNames.project.edit}:projectId`} element={<EditProject />} />

						{/* Team's Routes */}
						<Route path={pathNames.team.list} element={<ListTeam />} />
						<Route path={pathNames.team.create} element={<CreateTeam />} />
						<Route path={`${pathNames.team.edit}:teamId`} element={<EditTeam />} />

						{/* API Keys Routes */}
						<Route path={pathNames.apiKey.list} element={<ListAPIKey />} />
						<Route path={pathNames.apiKey.create} element={<CreateAPIKey />} />
						<Route path={`${pathNames.apiKey.edit}:apiKeyId`} element={<EditAPIKey />} />

						{/* User's Routes */}
						<Route path={pathNames.users.user.list} element={<ListUsers />} />
						<Route path={pathNames.users.user.create} element={<CreateUser />} />
						<Route path={`${pathNames.users.user.edit}:userId`} element={<EditUsers />} />

						{/* Logs Routes */}
						<Route path={pathNames.users.logs} element={<AuditLogsPage />} />

						{/* Organization's Routes */}
						<Route path={pathNames.organization.list} element={<ListOrganization />} />
						<Route path={pathNames.organization.create} element={<CreateOrganization />} />
						<Route
							path={`${pathNames.organization.edit}:organizationId`}
							element={<EditOrganization />}
						/>

						{/* Dashboard Route */}
						<Route path={pathNames.consumption.dashboard} element={<Dashboard />} />
						<Route path={pathNames.consumption.dataExtraction} element={<DataExtraction />} />
						<Route path={pathNames.consumption.invoicing} element={<InvoicingPage />} />
						<Route path={pathNames.consumption.invoicingDetails} element={<InvoiceDetailsPage />} />

						{/* Service Route */}
						<Route path={pathNames.services.list} element={<Services />} />
						<Route path={pathNames.services.advancedSearch} element={<CreateAdvancedSearch />} />
						<Route path={pathNames.queries.listBatch} element={<ListBatchServiceQuery />} />
						<Route path={pathNames.queries.createBatch} element={<CreateServiceBatchQuery />} />

						{/* Ip Liberation Route */}
						<Route path={pathNames.settings.ip_liberation.list} element={<ListIps />} />
						<Route path={pathNames.settings.ip_liberation.create} element={<CreateIP />} />
						<Route path={`${pathNames.settings.ip_liberation.edit}:ipId`} element={<EditIp />} />
					</Route>

					{/* Profile Route */}
					<Route element={<PrivateRoute isAuthenticated={isAuthenticated} showMenu={false} />}>
						<Route path={pathNames.profile} element={<Profile />} />
						<Route path={pathNames.profileOrganization} element={<ProfileOrganization />} />
					</Route>

					<Route
						element={
							<PrivateRoute isAuthenticated={isAuthenticated} showMenu={false} isStandalone />
						}
					>
						<Route path={pathNames.docs} element={<Documentation />} />
					</Route>
				</Route>
			</RoutesDom>
		</BrowserRouter>
	)
}

export default Routes
