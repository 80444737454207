import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { Download as DownloadIcon } from "@mui/icons-material"
import { Alert, DialogContentText, Grid, Link } from "@mui/material"
import { download, generateCsv, mkConfig } from "export-to-csv"

import { createObitoDatabaseUpdate } from "../../../apis/services"
import { BodyPage, ContainerPage } from "../../../components/container/container.styles"
import DialogAlert from "../../../components/dialog/dialog"
import { Dropzone } from "../../../components/dropzone/dropzone"
import { HeaderPage } from "../../../components/header/page/header-page"
import { HelpButton } from "../../../components/help-button/help-button"
import { Loading } from "../../../components/loading/loading"
import { Table } from "../../../components/table/table"
import { useDialog } from "../../../hooks/app/dialog/use-dialog"
import { pathNames } from "../../../routes/paths"
import exampleRow from "./importing_example.json"

export const CreateObitoDatabaseUpdate = () => {
	const [loading, setLoading] = useState<boolean>(false)

	const [file, setFile] = useState<File | null>(null)

	const {
		dialogTitle,
		dialogDescription,
		dialog,
		setDialog,
		setDialogTitle,
		setDialogDescription,
	} = useDialog()

	// const polices = getItem(AuthStorage.userPolicy)

	const { t } = useTranslation()

	const navigate = useNavigate()

	const handleAccept = () => {
		setDialog(false)
	}

	if (loading) return <Loading />

	const handleFileDrop = (files: any) => {
		setFile(files[0])
	}

	const openModalOfFileStructureInstructions = (errorMsg: any) => {
		setDialog(true)
		setDialogTitle(
			errorMsg
				? t("batch_consult.invalid_file_format.title")
				: t("batch_consult.invalid_file_format.instructions_title"),
		)
		setDialogDescription(t("batch_consult.invalid_file_format.description"))
	}

	const handleSubmit = async () => {
		if (!file) return
		setLoading(true)

		try {
			const result = await createObitoDatabaseUpdate(file)
			if (result) {
				navigate(pathNames.services.obitoDatabaseUpdate.list)
			}
			setLoading(false)
		} catch (error) {
			setDialog(true)
			setDialogTitle(t("batch_consult.submition_error.title"))
			setDialogDescription(t("batch_consult.submition_error.description"))
			setLoading(false)
		}
	}

	return (
		<ContainerPage>
			<HeaderPage
				title={t("menu.update_obito_database")}
				description={t("update_obito_database.description")}
				actionButtonTitle={t("update_obito_database.actionButtonTitle")}
				disabledButton={!file}
				onClickSubmit={handleSubmit}
				onClickTopButton={() => navigate(pathNames.services.obitoDatabaseUpdate.list)}
				isEdit={true}
			/>
			<BodyPage>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-end",
						fontSize: "30px",
					}}
				>
					<HelpButton
						onClick={() => openModalOfFileStructureInstructions(false)}
						fontSize="inherit"
					/>
				</div>
				<Dropzone
					styles={{ marginTop: 4 }}
					disabled={loading}
					onDrop={handleFileDrop}
					extensions={["text/csv"]}
					onDropRejected={openModalOfFileStructureInstructions}
					maxFiles={1}
				/>
				<Grid item xs={12} style={{ marginTop: 16 }}>
					<Alert severity={"info"} title="Importante">
						Ao finalizar o processamento do arquivo, você receberá um e-mail com um resumo da
						operação.
					</Alert>
				</Grid>
			</BodyPage>

			<DialogAlert
				onlyConfirm={true}
				show={dialog}
				accept={handleAccept}
				title={dialogTitle}
				description={dialogDescription}
			>
				<DialogContent />
			</DialogAlert>
		</ContainerPage>
	)
}

const DialogContent = () => {
	const { t } = useTranslation()

	const csvConfig = mkConfig({
		useKeysAsHeaders: true,
		filename: `exemplo_atualizacao_base.csv`,
	})

	const columns = Object.keys(exampleRow).map((k: string) => ({
		label: k,
		id: k,
	}))

	const csv = generateCsv(csvConfig)([exampleRow])

	return (
		<>
			<DialogContentText>
				<p>{t("batch_consult.invalid_file_format.helper_text")}</p>
				<Alert severity="warning">{t("update_obito_database.example_alert_text")}</Alert>
				<Table
					rows={[exampleRow]}
					keys={columns.map((o: any) => o.id)}
					hidePagination={true}
					columns={columns}
					onDelete={() => null}
					onEdit={() => null}
					onClickLink={() => null}
					changePage={() => null}
					page={0}
					isEditVisible={false}
					isSelectable={false}
					changeRowsPerPage={() => null}
				/>
			</DialogContentText>
			<Link
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					cursor: "pointer",
					marginTop: 32,
				}}
				onClick={() => download(csvConfig)(csv)}
			>
				<DownloadIcon />
				{t("update_obito_database.download_example")}
			</Link>
		</>
	)
}
