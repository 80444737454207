import { useEffect, useState } from "react"

import { CloudDownload } from "@mui/icons-material"
import dayjs, { Dayjs } from "dayjs"
import { t } from "i18next"
import { enqueueSnackbar } from "notistack"

import { GraphDatasetProps } from "../../@types/graphs/dataset"
import {
	AnalyticsByOrganizationTeamsServicesResult,
	AnalyticsByOrganizationUsersResult,
	getAnalyticsByOrganizationTeamsServices,
	getAnalyticsByOrganizationUsers,
	searchProcessingTypeConsumption,
	SearchProcessingTypeConsumptionResult,
} from "../../apis/dashboard"
import { listOrganizationsWithClientUsage } from "../../apis/organization"
import { ContainerPage } from "../../components/container/container.styles"
import { TeamDistribuitionGraph } from "../../components/graphs/team-distribuition/team-distribuition-graph"
import { UsageOverviewGraphs } from "../../components/graphs/usage-overview/usage-overview-graphs"
import { UserDistributionGraph } from "../../components/graphs/user-distribution/user-distribution-graph"
import { HeaderPage } from "../../components/header/page/header-page"
import { HeaderPageTopButton } from "../../components/header/page/header-page-top-button"
import { useAuthentication } from "../../hooks/app/useAuthentication"
import { generateColors } from "../../utils/color"
import {
	GraphSectionContainer,
	GraphSectionTitle,
	GraphTitleDivider,
} from "../consumption/invoicing-details/invoicing-details.styles"
import { MonthlyEvolution } from "../consumption/invoicing-details/invoicing-monthly-evolution/invoicing-monthly-evolution"
import {
	DashboardFilters,
	DateEventChange,
	OrganizationOption,
} from "./components/dashboard-filters"

type Reports = {
	byOrganizationTeams?: any
	consultsByTeam?: GraphDatasetProps
	consultsByType?: GraphDatasetProps
	consultsByProduct?: GraphDatasetProps
	byOrganizationUsers?: GraphDatasetProps
}

export const Dashboard = () => {
	const { isAdmin, user } = useAuthentication()

	const [isLoading, setLoading] = useState(false)
	const [selectedOrganization, setSelectedOrganization] = useState<OrganizationOption | null>({
		id: user.organizationId,
		name: user.organizationName,
	})
	const [organizations, setOrganizations] = useState<OrganizationOption[]>([])
	const [startDate, setStartDate] = useState<Dayjs>(dayjs(new Date()).add(-1, "month"))
	const [endDate, setEndDate] = useState<Dayjs>(dayjs(new Date()))

	const [reports, setReports] = useState<Reports>({})

	user

	const generateBaseDataset = (): GraphDatasetProps => ({
		labels: [],
		datasets: [
			{
				label: "",
				data: [],
				backgroundColor: [],
			},
		],
	})

	const normalizebyOrganizationTeams = (data: AnalyticsByOrganizationTeamsServicesResult) => {
		const services = data.data
			.map((item) => item.services)
			.flat()
			.map((item) => ({
				team: item.teamName,
				service: item.serviceName,
				totalAmount: item.usedLimit,
			}))

		return services
	}

	const normalizeConsultsByTeam = (data: AnalyticsByOrganizationTeamsServicesResult) => {
		const colors = generateColors(data.data.length)
		const dataset = generateBaseDataset()
		data.data.forEach((item, index) => {
			dataset.labels.push(item.teamName)
			dataset.datasets[0].data.push(+item.totalUsage)
			dataset.datasets[0].backgroundColor.push(colors[index])
		})
		return dataset
	}

	const normalizeConsultsByProduct = (data: AnalyticsByOrganizationTeamsServicesResult) => {
		const colors = generateColors(10)
		const dataset = generateBaseDataset()
		const services = data.data.map((item) => item.services).flat()
		services.forEach((service, index) => {
			dataset.labels.push(service.serviceName)
			dataset.datasets[0].data.push(+service.usedLimit)
			dataset.datasets[0].backgroundColor.push(colors[index])
		})
		return dataset
	}

	const normalizeConsultsByType = (data: SearchProcessingTypeConsumptionResult) => {
		const colors = generateColors(data.data.length)
		const dataset = generateBaseDataset()
		data.data.forEach((item, index) => {
			dataset.labels.push(item.processingType)
			dataset.datasets[0].data.push(+item.usedLimit)
			dataset.datasets[0].backgroundColor.push(colors[index])
		})
		return dataset
	}

	const normalizeUserDistribution = (data: AnalyticsByOrganizationUsersResult) => {
		const colors = generateColors(data.data.length)
		const dataset = generateBaseDataset()
		const labels = new Set(data.data.map((item) => `${item.userName} (${item.email})`))
		dataset.labels = Array.from(labels).map((item) => item.split(" ")) as any
		const processingTypes = Array.from(new Set(data.data.map((item) => item.processingType)))
		processingTypes.forEach((type, index) => {
			if (!dataset.datasets[index]) {
				dataset.datasets.push({
					label: type,
					data: [],
					backgroundColor: [],
				})
			}
			data.data
				.filter((item) => item.processingType === type)
				.forEach((item) => {
					dataset.datasets[index].label = type
					dataset.datasets[index].data.push(+item.usedLimit)
					dataset.datasets[index].backgroundColor.push(colors[index])
				})
		})
		return dataset
	}

	const tryFetchReports = async () => {
		if (!selectedOrganization || !startDate || !endDate) return
		try {
			setLoading(true)
			const byOrganizationTeams = await getAnalyticsByOrganizationTeamsServices(
				startDate?.format("YYYY-MM-DD"),
				endDate?.format("YYYY-MM-DD"),
				selectedOrganization?.id,
			)

			const byOrganizationProcessingTypes = await searchProcessingTypeConsumption(
				[selectedOrganization?.id],
				startDate?.format("YYYY-MM-DD"),
				endDate?.format("YYYY-MM-DD"),
				[],
			)

			const byOrganizationUsers = await getAnalyticsByOrganizationUsers(
				startDate?.format("YYYY-MM-DD"),
				endDate?.format("YYYY-MM-DD"),
				selectedOrganization?.id,
			)

			setReports({
				byOrganizationTeams: normalizebyOrganizationTeams(byOrganizationTeams),
				consultsByTeam: normalizeConsultsByTeam(byOrganizationTeams),
				consultsByType: normalizeConsultsByType(byOrganizationProcessingTypes),
				consultsByProduct: normalizeConsultsByProduct(byOrganizationTeams),
				byOrganizationUsers: normalizeUserDistribution(byOrganizationUsers),
			})
		} catch (error) {
			enqueueSnackbar(t("consumption.error.fetch"), { variant: "error" })
		} finally {
			setLoading(false)
		}
	}

	const tryFetchOrganization = async () => {
		try {
			const {
				data: { data },
			} = await listOrganizationsWithClientUsage()
			setOrganizations(data)
		} catch (error) {
			enqueueSnackbar(t("organization.error.fetch"), { variant: "error" })
		}
	}

	const handleExport = () => {}

	const handleStartDateChange = (e: DateEventChange) => {
		setStartDate(e as Dayjs)
	}

	const handleEndDateChange = (e: DateEventChange) => {
		setEndDate(e as Dayjs)
	}

	useEffect(() => {
		if (isAdmin) tryFetchOrganization()
		tryFetchReports()
	}, [])

	return (
		<ContainerPage
			style={{
				overflow: "auto",
			}}
		>
			<HeaderPage
				title={t("consumption.title.dashboard")}
				customTitleStyles={{ marginBottom: "1rem" }}
				showDownloadButton
				topButton={
					<div>
						<HeaderPageTopButton onClick={handleExport} icon={<CloudDownload />}>
							{t("headerPage.export_as_file")}
						</HeaderPageTopButton>
					</div>
				}
			>
				<DashboardFilters
					onOrganizationChange={setSelectedOrganization}
					onStartDateChange={handleStartDateChange}
					onEndDateChange={handleEndDateChange}
					organizationSelected={selectedOrganization}
					organizations={organizations}
					onDataSearch={tryFetchReports}
					defaultEndDate={endDate}
					defaultStartDate={startDate}
				/>
			</HeaderPage>

			{reports.consultsByProduct && reports.consultsByType && reports.consultsByTeam && (
				<GraphSectionContainer>
					<GraphSectionTitle variant="h5" align="left">
						{t("consumption.invoicing.overview.title")}
					</GraphSectionTitle>
					<GraphTitleDivider />

					<UsageOverviewGraphs
						isLoading={isLoading}
						datasets={{
							consultsByProduct: reports.consultsByProduct,
							consultsByType: reports.consultsByType,
							consultsByTeam: reports.consultsByTeam,
						}}
					/>
				</GraphSectionContainer>
			)}

			{reports?.byOrganizationTeams && (
				<GraphSectionContainer>
					<GraphSectionTitle variant="h5" align="left">
						{t("consumption.invoicing.teamDistribuition.title")}
					</GraphSectionTitle>

					<GraphTitleDivider />
					<TeamDistribuitionGraph datasets={reports?.byOrganizationTeams} isLoading={isLoading} />
				</GraphSectionContainer>
			)}

			<GraphSectionContainer>
				<GraphSectionTitle variant="h5" align="left">
					{t("consumption.invoicing.userDistribution.title")}
				</GraphSectionTitle>

				<GraphTitleDivider />
				<UserDistributionGraph
					labels={reports.byOrganizationUsers?.labels ?? []}
					datasets={reports.byOrganizationUsers?.datasets ?? []}
				/>
			</GraphSectionContainer>

			{selectedOrganization && (
				<GraphSectionContainer mt="40px" mb="40px">
					<GraphSectionTitle variant="h5" align="left">
						{t("consumption.invoicing.monthlyEvolution.title")}
					</GraphSectionTitle>
					<GraphTitleDivider />
					<MonthlyEvolution
						queryInvoicing={{
							orgazizationId: [selectedOrganization?.id],
							startDate: startDate?.format("YYYY-MM-DD"),
							endDate: endDate?.format("YYYY-MM-DD"),
							invoicingId: "",
						}}
						month={endDate.month() + 1}
						year={endDate.year()}
					/>
				</GraphSectionContainer>
			)}
		</ContainerPage>
	)
}
