import CloseIcon from "@mui/icons-material/Close"
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, styled } from "@mui/material"

import { AdvancedSearchResponse } from "../../../../../apis/services"
import { DetailsModalDataBlock } from "./DataBlock"

type DetailsModalProps = {
	isOpen: boolean
	data: AdvancedSearchResponse
	handleClose: () => void
}

export function DetailsModal({ isOpen, data, handleClose }: DetailsModalProps) {
	const BootstrapDialog = styled(Dialog)(({ theme }) => ({
		"& .MuiDialogContent-root": {
			padding: theme.spacing(2),
		},
		"& .MuiDialogActions-root": {
			padding: theme.spacing(1),
		},
	}))

	return (
		<>
			<BootstrapDialog
				fullScreen
				maxWidth="lg"
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={isOpen}
			>
				<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
					Detalhes do óbito
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent dividers>
					<Grid container spacing={2}>
						<DetailsModalDataBlock
							title="Informações pessoais"
							data={[
								{ label: "CPF", value: data.cpf, width: 2 },
								{ label: "Data de nascimento", value: data.dataNascimento, width: 2 },
								{ label: "Nome", value: data.nomeFalecido, width: 4 },
								{ label: "Nome da mãe", value: data.nomeMaeFalecido, width: 4 },
							]}
						/>
						<DetailsModalDataBlock
							title="Informações do óbito"
							data={[
								{ label: "ID Base", value: data.baseId },
								{ label: "Data do óbito", value: data.dataObito },
								{ label: "Data de lavratura", value: data.dataLavrat },
								{ label: "Livro", value: data.livro },
								{ label: "Folha", value: data.folha },
								{ label: "Termo", value: data.termo },
								{ label: "Data de cadastro", value: data.dataCadastro },
							]}
						/>

						<DetailsModalDataBlock
							title="Informações do cartório"
							data={[
								{ label: "CNPJ", value: data.cartorioId },
								{ label: "Nome do cartório", value: data.cartorioNome },
								{ label: "UF", value: data.uf },
								{ label: "Cidade", value: data.cidade },
								{ label: "CEP", value: data.cep },
								{ label: "Bairro", value: data.bairro },
								{ label: "Endereço do cartório", value: data.cartorioEndereco },
								{ label: "DDD", value: data.ddd },
								{ label: "Ramal", value: data.ramal },
								{ label: "Telefone 1", value: data.tel1 },
								{ label: "Telefone 2", value: data.tel2 },
								{ label: "E-mail", value: data.email },
							]}
						/>
					</Grid>
				</DialogContent>
			</BootstrapDialog>
		</>
	)
}
