import { AdvancedSearch } from "../libs/zod/advanced-search/advancedSearch.schema"
import { AuthStorage } from "../storage/auth-type"
import { getItem } from "../storage/local-storage"
import axiosInstance from "./config/axios"

export interface CreateBatchConsultPayload {
	file: Blob
	serviceId: string
	organizationId?: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreateBatchConsultResponse {}

export interface AdvancedSearchResponse {
	nomeFalecido: string
	cpf: string
	dataNascimento: string
	nomeMaeFalecido: string
	dataObito: string
	dataLavrat: string
	livro: string
	folha: string
	termo: string
	cartorioId: string
	dataCadastro: string
	cartorioNome: string
	cartorioEndereco: string
	cidade: string
	cep: string
	bairro: string
	uf: string
	ddd: string
	tel1: string
	tel2: string
	ramal: string
	email: string
	nomeDoTitular: string
	nomeDoSubstituto: string
	encerramento: string

	baseId?: number
	id?: number
}

export type PagingParams = {
	page?: number
	pageSize?: number
}

export type PagingResponse<T> = {
	currentPage: number
	pageSize: number
	total: number
	list: T[]
}

export const listAllServices = async ({ currentPage, pageSize, filter = "", active = true }: any) =>
	await axiosInstance.post("/services/search", {
		currentPage,
		pageSize,
		filter,
		active,
	})

export const createBatchConsult = async ({
	file,
	serviceId,
	organizationId,
}: CreateBatchConsultPayload): Promise<CreateBatchConsultResponse> => {
	const formData = new FormData()
	formData.append("file", file)
	if (organizationId) formData.append("organizationId", organizationId)
	const { data } = await axiosInstance.post(`/services/${serviceId}/batchConsult`, formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	})
	return data
}

export const listAllBatchConsultings = async ({
	currentPage,
	pageSize,
	filter = "",
	active = true,
	status = [],
	organizationId,
}: any) =>
	await axiosInstance.post("/services/batchConsultSearch", {
		currentPage,
		pageSize,
		filter,
		active,
		status,
		organizationId,
	})
export const createService = async (service: any) => await axiosInstance.post("/services", service)

export const updateService = async (serviceId: any, serviceData: any) =>
	await axiosInstance.put(`/services/${serviceId}`, serviceData)

export const searchService = async (serviceId: any) =>
	await axiosInstance.get(`/services/${serviceId}`)

export const listServicesToSelect = async () => await axiosInstance.get("/services")

export const advancedSearch = async ({
	page,
	pageSize,
	...params
}: AdvancedSearch & PagingParams) => {
	const queryParams = []
	if (page !== undefined) queryParams.push(`page=${page}`)
	if (pageSize !== undefined) queryParams.push(`pageSize=${pageSize}`)
	const queryString = queryParams.length ? `?${queryParams.join("&")}` : ""
	return axiosInstance.post<PagingResponse<AdvancedSearchResponse>>(
		`/services/advancedSearch${queryString}`,
		{ ...params },
	)
}

export const deleteDeath = async (id: number) => axiosInstance.delete(`/services/deleteObito/${id}`)

export const searchObitoDatabaseUpdates = async ({
	currentPage,
	pageSize,
	filter = "",
	active = true,
}: any) =>
	await axiosInstance.post("/services/search/obitoDatabaseUpdates", {
		currentPage,
		pageSize,
		filter,
		active,
	})

export const createObitoDatabaseUpdate = async (file: Blob) => {
	const formData = new FormData()
	formData.append("file", file)
	const { data } = await axiosInstance.post("/services/obitoDatabaseUpdate", formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	})
	return data
}

export const getBatchConsultFileSignedURL = async (
	batchId: string,
	fileType: string,
): Promise<{ data: { signedUrl: string } }> => {
	const userToken = getItem(AuthStorage.userToken)
	const { data } = await axiosInstance.get(
		`/files/download/serviceBatch/${batchId}/${fileType}?authToken=${userToken.access_token}&noRedirection=tru`,
	)
	return data
}
