import { ReactElement } from "react"
import { Outlet } from "react-router-dom"

import { Footer } from "../components/footer/footer"
import Header from "../components/header/header"

type PublicRouteProps = {
	children?: ReactElement
	showFooter?: boolean
	showHeader?: boolean
}

export const PublicRoute = ({
	children,
	showFooter = true,
	showHeader = true,
}: PublicRouteProps) => {
	return (
		<>
			{showHeader && <Header isLogged={false} />}
			{children ?? <Outlet />}
			{showFooter && <Footer />}
		</>
	)
}
