import { z } from "zod"

import { isValidIP } from "../../../utils/ip"
import { UnrefinedCreateIPLiberationSchema, ipLiberationRefiner } from "./create-ip-liberation"

export const UnrefinedEditIPLiberationSchema = z
	.object({
		active: z.boolean(),
		id: z.string().uuid(),
		ipBlocks: z
			.array(
				z.object({
					id: z.string(),
					ip: z.string().refine((value) => isValidIP(value), {
						message: "O IP não é válido",
					}),
				}),
			)
			.default([]),
		ipLiberations: z
			.array(
				z.object({
					id: z.string(),
					ip: z.string().refine((value) => isValidIP(value), {
						message: "O IP não é válido",
					}),
				}),
			)
			.default([]),
	})
	.merge(UnrefinedCreateIPLiberationSchema)

export const EditIPLiberationSchema = ipLiberationRefiner(UnrefinedEditIPLiberationSchema)

export type EditIPLiberationData = z.infer<
	typeof EditIPLiberationSchema & typeof UnrefinedEditIPLiberationSchema
>
