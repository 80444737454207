import { useEffect, useState } from "react"

import { useTheme } from "@mui/material"
import { enqueueSnackbar } from "notistack"
import { RedocStandalone } from "redoc"

import { getSwaggerDefinition } from "../../apis/docs"
import Header from "../../components/header/header"

export const Documentation = () => {
	const [spec, setSpec] = useState<object>()

	const {
		palette: {
			background: { default: backgroundColor },
			primary: { main },
			text: { primary: textColor },
		},
	} = useTheme()

	useEffect(() => {
		fetchSpec()
	}, [])

	const fetchSpec = async () => {
		try {
			const spec = await getSwaggerDefinition()
			setSpec(spec)
		} catch (error) {
			enqueueSnackbar("Erro ao buscar a documentação", { variant: "error" })
		}
	}

	return (
		<>
			<Header isLogged />

			<div style={{ marginTop: 60 }}>
				<RedocStandalone
					spec={spec}
					options={{
						theme: {
							colors: {
								primary: { main },
							},
							sidebar: {
								backgroundColor,
								textColor,
								activeTextColor: main,

								arrow: {
									color: main,
								},
							},
							rightPanel: {
								backgroundColor: main,
							},
						},
						labels: {
							requestSamples: "Exemplos de requisição",
							responseSamples: "Exemplos de resposta",
							responses: "Respostas",
							default: "Padrão",
							example: "Exemplo",
							noResultsFound: "Nenhum resultado encontrado",
						},
						hideDownloadButton: true,
					}}
				/>
			</div>
		</>
	)
}
