import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import {
	Article,
	CheckCircle,
	Error,
	Inventory,
	Loop,
	ManageSearch,
	Pending,
} from "@mui/icons-material"

import { Autocomplete, Button, TextField } from "@mui/material"
import { listOptionsOrganizations } from "../../../apis/organization"
import { listAllBatchConsultings } from "../../../apis/services"
import { disableUsers } from "../../../apis/user"
import { BodyPage, ContainerPage } from "../../../components/container/container.styles"
import DialogAlert from "../../../components/dialog/dialog"
import { FilterRoot } from "../../../components/filter/filter-root"
import { HeaderPage } from "../../../components/header/page/header-page"
import { LoadingTable } from "../../../components/loading/loading"
import { Table } from "../../../components/table/table"
import { useAuthentication } from "../../../hooks/app/useAuthentication"
import { pathNames } from "../../../routes/paths"
import { styles } from "../../../styles/common"
import MultiSelect, { MultiSelectData } from "../../consumption/multiselect"
import { BatchFileType, downloadFile } from "./download-file-trigger"

type FormattedListItem = {
	id: string
	status: string
	user: string
	service: string
	totalAmount: string
	totalSuccess: string
	totalFailed: string
	organization: string
	organizationId: string
	statusPillColor: string
	hit_rate: string
	statusPillIcon: React.ReactNode
	statusTooltip: string
	originalFileName: string
	originalFileUrl?: string
	resultFileName?: string
	resultFileUrl?: string
}

export const ListBatchServiceQuery = () => {
	const [showDialogConfirmation, setShowDialogConfirmation] = useState<boolean>(false)
	const [selectedItems, setSelectedItems] = useState<any>()

	const [showFilter, setShowFilter] = useState<boolean>(false)

	const [rows, setRows] = useState<FormattedListItem[]>([])
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [pageSize, setPageSize] = useState<number>(10)
	const [filter, setFilter] = useState<string>("")
	const [pageCount, setPageCount] = useState<number>(0)
	const [loading, setLoading] = useState<boolean>(true)
	const [statusSelected, setStatusSelected] = useState<Array<any>>([])
	const [organizations, setOrganizations] = useState<MultiSelectData[]>([])
	const [organizationSelected, setOrganizationSelected] = useState<MultiSelectData | null>(null)

	const navigate = useNavigate()

	const { t } = useTranslation()
	const { isAdmin, policies } = useAuthentication()

	const columns = [
		{
			label: t("columns.status"),
			id: "status",
		},
		{
			label: t("columns.originalFile"),
			id: "originalFile",
		},
		{
			label: t("columns.user"),
			id: "user",
		},
		{
			label: t("columns.service"),
			id: "service",
		},
		{
			label: t("columns.queries_amount"),
			id: "totalAmount",
		},
		{
			label: t("columns.valid_queries_amount"),
			id: "totalSuccess",
		},
		{
			label: t("columns.invalid_queries_amount"),
			id: "totalFailed",
		},
		{
			label: t("columns.hit_rate"),
			id: "hit_rate",
		},

		{
			label: t("columns.posting_date"),
			id: "createdAt",
		},
		{
			label: t("columns.finished_date"),
			id: "finishedAt",
		},
		{
			label: t("columns.resultFile"),
			id: "resultFile",
		},
	]

	const keys = [
		{ isPill: true, value: "status", id: "status" },
		{
			isDownload: true,
			value: "originalFileUrl",
			id: "originalFileName",
			onClick: downloadFile(BatchFileType.ORIGINAL),
		},
		"user",
		"service",
		"totalAmount",
		"totalSuccess",
		"totalFailed",
		"hit_rate",
		{ isDate: true, value: "createdAt", id: "createdAt" },
		{ isDate: true, value: "finishedAt", id: "finishedAt" },
		{
			isDownload: true,
			value: "resultFileUrl",
			id: "resultFileName",
			onClick: downloadFile(BatchFileType.RESULT),
		},
	]

	if (isAdmin) {
		columns.unshift({
			label: t("columns.organization"),
			id: "organizationId",
		})

		keys.unshift({
			isLink: true,
			value: "organization",
			id: "organizationId",
			path: pathNames.organization.edit,
			backPath: pathNames.queries.listBatch,
		} as any)

		const SERVICE_KEY_INDEX = 3
		keys[SERVICE_KEY_INDEX] = {
			isLink: true,
			value: "service",
			id: "serviceId",
			path: pathNames.service.edit,
			backPath: pathNames.queries.listBatch,
		} as any
	}

	useEffect(() => {
		listBatchConsults()
		// eslint-disable-next-line
	}, [pageSize, currentPage, filter])

	useEffect(() => {
		if (isAdmin) {
			getOrganizations()
		}
	}, [])

	function getHitRate(totalAmount: number, totalSuccess: number) {
		return totalAmount > 0 ? ((totalSuccess / totalAmount) * 100).toFixed(1) + "%" : "0.0%"
	}

	/**
	 * Format number to pt-BR (ex: from 1000 to 1.000)
	 * @param number - number to be formatted
	 * @returns - formatted number
	 */
	function formatNumber(number: number): string {
		return number.toLocaleString("pt-BR")
	}
	function removePrependMarkFromFileName(fileName: string) {
		return fileName.replace(/^\d+_/, "")
	}
	const listBatchConsults = async () => {
		try {
			const {
				data: { data },
			} = await listAllBatchConsultings({
				currentPage,
				pageSize,
				filter,
				organizationId: organizationSelected?.id,
				status: statusSelected?.map((f) => f.id),
			})

			const status = {
				received: {
					color: "default",
					icon: <Inventory />,
				},
				analyzing: {
					color: "warning",
					icon: <ManageSearch />,
				},
				pending: {
					color: "warning",
					icon: <Pending />,
				},
				in_progress: {
					color: "info",
					icon: <Loop />,
				},
				finished: {
					color: "success",
					icon: <CheckCircle />,
				},
				aborted: {
					color: "error",
					icon: <Error />,
				},
				generating_file: {
					color: "info",
					icon: <Article />,
				},
			}

			const formatedList: FormattedListItem[] = data?.list.map((item: any) => ({
				...item,
				user: item?.createdByUser?.email,
				service: item?.service?.name,
				totalAmount: formatNumber(item?.totalAmount),
				totalSuccess: formatNumber(item?.totalSuccess),
				totalFailed: formatNumber(item?.totalFailed),
				organization: item?.organization?.name,
				organizationId: item?.organization?.id,
				status: t("service_query.status." + item?.status),
				statusPillColor: status[item?.status as keyof typeof status]?.color,
				hit_rate: getHitRate(item?.totalAmount, item?.totalSuccess),
				statusPillIcon: status[item?.status as keyof typeof status]?.icon,
				statusTooltip: item?.observation,
				originalFileName: removePrependMarkFromFileName(item.originalFileName),
				originalFileUrl: item.originalFileUrl
					? `${process.env.REACT_APP_SERVICE_URL}/files/download/serviceBatch/${item.id}/original`
					: undefined,
				resultFileName: undefined,
				resultFileUrl: item.resultFileUrl
					? `${process.env.REACT_APP_SERVICE_URL}/files/download/serviceBatch/${item.id}/result`
					: undefined,
			}))

			setRows(formatedList)
			setPageCount(data?.total)
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const redirectLink = (key: string, id: string) => {
		if (key === "organization") {
			navigate(pathNames.organization.edit + id)
		} else if (key === "team") {
			navigate(pathNames.team.edit + id)
		}
	}

	const handleSelectedDisableItems = (items: any) => {
		setSelectedItems(items)
		setShowDialogConfirmation(true)
	}

	const handleDisableAllItems = async () => {
		setShowDialogConfirmation(false)
		setLoading(true)

		try {
			await disableUsers(selectedItems)
		} catch (error) {
		} finally {
			setLoading(false)
			listBatchConsults()
		}
	}

	const getOrganizations = async () => {
		try {
			const {
				data: { data },
			} = await listOptionsOrganizations()
			setOrganizations(data)
		} catch (error) {
			console.error(error)
		}
	}

	const statusOptions = [
		{
			name: t("service_query.status.received"),
			id: "received",
		},
		{
			name: t("service_query.status.analyzing"),
			id: "analyzing",
		},
		{
			name: t("service_query.status.pending"),
			id: "pending",
		},
		{
			name: t("service_query.status.in_progress"),
			id: "in_progress",
		},
		{
			name: t("service_query.status.finished"),
			id: "finished",
		},
		{
			name: t("service_query.status.aborted"),
			id: "aborted",
		},
		{
			name: t("service_query.status.generating_file"),
			id: "generating_file",
		},
	]

	return (
		<ContainerPage>
			<HeaderPage
				searchValue={filter}
				onSearch={(event) => setFilter(event?.target?.value)}
				isCreateButtonVisible={policies?.serviceBatchConsulting?.create}
				title={t("menu.batch_service_query")}
				onOpenFilter={() => setShowFilter(true)}
				buttonTitle={t("service_query.new_query")}
				onClickTopButton={() => navigate(pathNames.queries.createBatch)}
			/>
			<BodyPage>
				{!loading ? (
					<Table
						page={currentPage - 1}
						rowsPerPage={pageSize}
						pageCount={pageCount}
						changeRowsPerPage={(value) => setPageSize(value)}
						changePage={(value) => setCurrentPage(value + 1)}
						onDelete={() => null}
						onEdit={() => null}
						onClickLink={redirectLink}
						columns={columns}
						rows={rows}
						keys={keys}
						isEditVisible={false}
						isDeleteVisible={policies?.serviceBatchConsulting?.delete}
						isReadVisible={policies?.serviceBatchConsulting?.read}
						onDisableAll={handleSelectedDisableItems}
					/>
				) : (
					<LoadingTable />
				)}
			</BodyPage>

			<FilterRoot
				isOpen={showFilter}
				onClose={() => setShowFilter(false)}
				ActionButtons={() => (
					<>
						<Button color="secondary" onClick={() => setShowFilter(false)}>
							{t("filter.cancel")}
						</Button>
						<Button
							color="secondary"
							onClick={() => {
								listBatchConsults()
								setShowFilter(false)
							}}
							autoFocus
						>
							{t("filter.apply")}
						</Button>
					</>
				)}
			>
				{organizations.length > 0 && isAdmin && (
					<Autocomplete
						style={{
							...styles.textFieldMarginTop,
							marginBottom: 20,
						}}
						disablePortal
						options={organizations}
						value={organizationSelected}
						getOptionLabel={(option: any) => option.name || ""}
						onChange={(event, value) => {
							setOrganizationSelected(value as any)
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								color={"secondary"}
								label={t("service_query.label_organization")}
							/>
						)}
					/>
				)}

				<MultiSelect
					selectedNames={statusSelected.map((f) => f.name)}
					data={statusOptions}
					title={"Status"}
					onChange={(event) => {
						const {
							target: { value },
						} = event

						setStatusSelected(
							statusOptions
								?.filter((option) => value.includes(option.name))
								?.map((option) => ({ ...option, key: undefined })),
						)
					}}
				/>
			</FilterRoot>

			<DialogAlert
				show={showDialogConfirmation}
				description={t("table.modal_disable_label_description")}
				title={t("table.disable")}
				accept={() => handleDisableAllItems()}
				decline={() => setShowDialogConfirmation(false)}
			/>
		</ContainerPage>
	)
}
