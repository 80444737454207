import DataUsageIcon from "@mui/icons-material/DataUsage"
import { Autocomplete, Button, Grid, TextField } from "@mui/material"
import {
	DatePicker,
	DateValidationError,
	LocalizationProvider,
	PickerChangeHandlerContext,
} from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { t } from "i18next"
import { useAuthentication } from "../../../hooks/app/useAuthentication"
export type DateEventChange = Date | dayjs.Dayjs | null
type DateEventHandler = (
	value: DateEventChange,
	context: PickerChangeHandlerContext<DateValidationError>,
) => void

export type OrganizationOption = {
	id: string
	name: string
}

export interface DashboardFiltersProps {
	onStartDateChange?: DateEventHandler
	onEndDateChange?: DateEventHandler
	onOrganizationChange: (event: OrganizationOption | null) => void
	defaultStartDate?: dayjs.Dayjs
	defaultEndDate?: dayjs.Dayjs
	organizations?: OrganizationOption[]
	organizationSelected: OrganizationOption | null | undefined
	onDataSearch?: () => void
}

export const DashboardFilters = ({
	onStartDateChange,
	onEndDateChange,
	onOrganizationChange,
	onDataSearch,
	defaultStartDate,
	defaultEndDate,
	organizations,
	organizationSelected,
}: DashboardFiltersProps) => {
	const { isAdmin } = useAuthentication()
	return (
		<Grid container spacing={1} columns={12}>
			<Grid item xs={6} sm={2} md={2}>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						sx={{ width: "100%" }}
						onChange={onStartDateChange}
						defaultValue={defaultStartDate || dayjs(new Date())}
						views={["year", "month", "day"]}
						label={t("headerPage.start_date")}
						format="DD/MM/YYYY"
						openTo="year"
					/>
				</LocalizationProvider>
			</Grid>

			<Grid item xs={6} sm={2} md={2}>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						sx={{ width: "100%" }}
						onChange={onEndDateChange}
						defaultValue={defaultEndDate || dayjs(new Date())}
						views={["year", "month", "day"]}
						label={t("headerPage.end_date")}
						format="DD/MM/YYYY"
						openTo="year"
						className="full-width"
					/>
				</LocalizationProvider>
			</Grid>

			{isAdmin && (
				<Grid item xs={12} sm={4} md={4}>
					<Autocomplete
						id={"segment"}
						sx={{ width: "100%" }}
						options={organizations ?? []}
						onChange={(_, data) => onOrganizationChange(data ?? null)}
						getOptionLabel={(option: OrganizationOption) => option?.name}
						value={organizationSelected}
						renderInput={(params) => (
							<TextField
								{...params}
								variant={"outlined"}
								name={"organization"}
								color={"secondary"}
								label={t("dashboard.organization_filter_title")}
							/>
						)}
					/>
				</Grid>
			)}

			<Grid item xs={12} sm={2} md={2}>
				<Button
					color="secondary"
					style={{
						textTransform: "initial",
						height: "100%",
						width: "100%",
					}}
					onClick={onDataSearch}
					endIcon={<DataUsageIcon />}
					disabled={!organizationSelected}
				>
					Ver consumo
				</Button>
			</Grid>
		</Grid>
	)
}
