import { ReactNode } from "react"
import { useTranslation } from "react-i18next"

import AddIcon from "@mui/icons-material/Add"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import TuneIcon from "@mui/icons-material/Tune"
import { Button, Grid, SelectChangeEvent, SxProps, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { DatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import dayjs, { Dayjs } from "dayjs"

import { GroupButton } from "../../button-group/button-group"
import { Row, TextField, TitleHeader } from "./header-page.styles"

export type CSVReportOption =
	| "consumptionMonthByDay"
	| "consumptionYearByMonth"
	| "requestsByProduct"
	| "processingTypeConsumption"
	| "dailyProductConsumption"
	| "evolutiveClientConsumption"
	| "classifiedConsumption"
	| "consumptionSummary"
	| "generalConsumptionSummaryByUserAndOrganization"

type HeaderPageProps = {
	onClickTopButton?: () => void
	onClickSubmit?: () => void
	onSearch?: (event: any) => void
	onOpenFilter?: () => void
	onChangeDate?: (event: any) => void
	onChangeReportType?: (event: SelectChangeEvent) => void
	children?: ReactNode
	isCreateButtonVisible?: boolean
	title: string
	buttonTitle?: any
	description?: any
	isEdit?: boolean
	isGroupButton?: boolean
	hasCreate?: boolean
	disabledButton?: boolean
	searchValue?: string
	isChartVisible?: boolean
	searchHide?: boolean
	searchPlaceholder?: string
	organizations?: Array<any>
	firstLineMultiselect?: boolean
	buttonAttrs?: any
	actionButtonTitle?: any
	hasEndDate?: boolean
	customTitleStyles?: SxProps
	showDownloadButton?: boolean
	defaultStartDate?: Dayjs
	defaultEndDate?: Dayjs
	hideSaveButton?: boolean
	topButton?: ReactNode
	hasFilterHeader?: boolean
	rightTitleItem?: ReactNode
} & (
	| { hasEndDate: true; onChangeEndDate: (event: any) => void }
	| { hasEndDate?: false; onChangeEndDate?: (event: any) => void }
)

export const HeaderPage = ({
	isCreateButtonVisible = true,
	title,
	description = "",
	hasCreate = true,
	isEdit = false,
	buttonTitle,
	actionButtonTitle,
	isGroupButton = false,
	onClickTopButton,
	onClickSubmit,
	onSearch,
	onOpenFilter,
	disabledButton = false,
	searchValue = "",
	isChartVisible = false,
	searchHide = false,
	hasFilterHeader = false,
	searchPlaceholder = "",
	onChangeDate,
	buttonAttrs = {},
	hasEndDate,
	onChangeEndDate,
	customTitleStyles,
	defaultStartDate,
	defaultEndDate,
	hideSaveButton,
	topButton,
	children,
	rightTitleItem,
}: HeaderPageProps) => {
	const { t } = useTranslation()

	return (
		<Box style={{ marginBottom: "1rem" }}>
			<Row>
				<TitleHeader sx={customTitleStyles} color={"secondary"}>
					{title}
				</TitleHeader>
				{rightTitleItem}
				{hasFilterHeader && (
					<div>
						<Button
							color="secondary"
							style={{ textTransform: "initial" }}
							onClick={onOpenFilter}
							variant={"text"}
							startIcon={<TuneIcon />}
						>
							Filtros
						</Button>
					</div>
				)}

				{!isGroupButton && hasCreate
					? isCreateButtonVisible &&
						!topButton && (
							<Button
								color="secondary"
								style={{ textTransform: "initial" }}
								onClick={onClickTopButton}
								variant={!isEdit ? "outlined" : "text"}
								startIcon={!isEdit ? <AddIcon /> : <ArrowBackIcon />}
							>
								{!isEdit ? buttonTitle : t("headerPage.back")}
							</Button>
						)
					: hasCreate && <GroupButton />}
				{topButton}
			</Row>
			<Row>
				{description && (
					<Typography color="textPrimary" variant={"body2"}>
						{description}
					</Typography>
				)}

				{children ? (
					children
				) : !isEdit ? (
					<>
						{!isChartVisible ? (
							!searchHide && (
								<>
									<TextField
										label={searchPlaceholder ?? t("headerPage.search")}
										value={searchValue}
										onChange={onSearch}
										autoComplete="off"
										color="secondary"
										size="small"
										id="filled-basic"
										variant="filled"
									/>
									<div>
										<Button
											color="secondary"
											style={{ textTransform: "initial" }}
											onClick={onOpenFilter}
											variant={"text"}
											startIcon={<TuneIcon />}
										>
											Filtros
										</Button>
									</div>
								</>
							)
						) : (
							<Grid container spacing={1} columns={12}>
								<Grid item xs={6} sm={2} md={2}>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker
											sx={{ width: "100%" }}
											onChange={onChangeDate}
											defaultValue={defaultStartDate || dayjs(new Date())}
											views={["year", "month", "day"]}
											label={hasEndDate ? t("headerPage.start_date") : t("headerPage.date")}
											format="DD/MM/YYYY"
											openTo="year"
										/>
									</LocalizationProvider>
								</Grid>

								{hasEndDate ? (
									<Grid item xs={6} sm={2} md={2}>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DatePicker
												sx={{ width: "100%" }}
												onChange={onChangeEndDate}
												defaultValue={defaultEndDate || dayjs(new Date())}
												views={["year", "month", "day"]}
												label={t("headerPage.end_date")}
												format="DD/MM/YYYY"
												openTo="year"
												className="full-width"
											/>
										</LocalizationProvider>
									</Grid>
								) : null}
							</Grid>
						)}
					</>
				) : !hideSaveButton ? (
					<Button
						disabled={disabledButton}
						type="submit"
						color="secondary"
						style={{ textTransform: "initial", margin: "0 16px" }}
						onClick={onClickSubmit}
						variant={"outlined"}
						{...buttonAttrs}
						hidden={true}
					>
						{actionButtonTitle || t("headerPage.save_information")}
					</Button>
				) : null}
			</Row>
		</Box>
	)
}
