import { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
} from "@mui/material"

import { deleteIp, listAllIpsApi } from "../../apis/ips"
import { BodyPage, ContainerPage } from "../../components/container/container.styles"
import { Filter } from "../../components/filter/filter"
import { HeaderPage } from "../../components/header/page/header-page"
import { LoadingTable } from "../../components/loading/loading"
import { Table } from "../../components/table/table"
import { useAuthentication } from "../../hooks/app/useAuthentication"
import { pathNames } from "../../routes/paths"

export const ListIps = () => {
	const [showFilter, setShowFilter] = useState<boolean>(false)
	const [active, setActive] = useState<any>("enable")

	const [rows, setRows] = useState<[]>([])
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [pageSize, setPageSize] = useState<number>(10)
	const [pageCount, setPageCount] = useState<number>(0)

	const [filter, setFilter] = useState<string>("")
	const [deletionItem, setDeletionItem] = useState<any>(null)

	const [loading, setLoading] = useState<boolean>(true)

	const navigate = useNavigate()
	const { t } = useTranslation()
	const { policies, isAdmin } = useAuthentication()

	const columns = [
		{
			label: t("columns.ipInitial"),
			id: "startIp",
		},
		{
			label: t("columns.ipEnd"),
			id: "endIp",
		},
		{
			label: t("columns.quantity"),
			id: "quantity",
		},
		{
			label: t("columns.status"),
			id: "active",
		},
	]

	const keys: any[] = ["startIp", "endIp", "quantity", "active"]

	if (isAdmin) {
		columns.unshift({
			label: t("columns.organization"),
			id: "organizationName",
		})
		keys.unshift({
			isLink: true,
			value: "organizationName",
			id: "organizationId",
			path: pathNames.organization.edit,
		})
	}

	useEffect(() => {
		listAllIps()
		// eslint-disable-next-line
	}, [pageSize, currentPage, filter])

	const listAllIps = async (status?: any) => {
		try {
			const {
				data: { data },
			} = await listAllIpsApi({ currentPage, pageSize, filter, active: status })
			setRows(
				data?.list.map((item: any) => ({
					...item,
					organizationName: item?.organization?.name,
				})),
			)
			setPageCount(data?.total)
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	const selectActiveState = (state: string) => {
		setShowFilter(false)
		setActive(state)
		if (state === "all") {
			listAllIps(null)
		} else if (state === "enable") {
			listAllIps(true)
		} else if (state === "disable") {
			listAllIps(false)
		}
	}

	const handleDeleteConfimation = async () => {
		try {
			await deleteIp(deletionItem?.id)
		} catch (error) {
			console.error(error)
		} finally {
			setDeletionItem(null)
			listAllIps()
		}
	}

	return (
		<ContainerPage>
			<HeaderPage
				isCreateButtonVisible={policies?.organizationIpRange?.create}
				title={t("menu.ip_liberation")}
				buttonTitle={t("ips.button_title_create")}
				searchValue={filter}
				onOpenFilter={() => setShowFilter(true)}
				onSearch={(event) => setFilter(event?.target?.value)}
				onClickTopButton={() => navigate(pathNames.settings.ip_liberation.create)}
			/>
			<BodyPage>
				{!loading ? (
					<Table
						page={currentPage - 1}
						rowsPerPage={pageSize}
						pageCount={pageCount}
						changeRowsPerPage={(value) => setPageSize(value)}
						changePage={(value) => setCurrentPage(value + 1)}
						onDelete={setDeletionItem}
						onEdit={(element: any) => navigate(pathNames.settings.ip_liberation.edit + element?.id)}
						columns={columns}
						rows={rows}
						keys={keys}
						isEditVisible={policies?.organizationIpRange?.update}
						isDeleteVisible={policies?.organizationIpRange?.delete}
						isReadVisible={policies?.organizationIpRange?.read}
					/>
				) : (
					<LoadingTable />
				)}
			</BodyPage>
			<Filter
				show={showFilter}
				apply={(e) => selectActiveState(e)}
				cancel={() => setShowFilter(false)}
				value={active}
			/>

			<Dialog open={deletionItem} maxWidth={"sm"} fullWidth={true}>
				<DialogTitle id="alert-dialog-title">{t("ips.delete_message_title")}</DialogTitle>
				<Divider />
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<Trans
							i18nKey={"ips.delete_message_description"}
							values={deletionItem}
							components={{ b: <b /> }}
						/>
					</DialogContentText>
				</DialogContent>

				<DialogActions>
					<Button color="secondary" onClick={() => setDeletionItem(null)}>
						{t("filter.cancel")}
					</Button>
					<Button color="secondary" onClick={handleDeleteConfimation} autoFocus>
						{t("filter.apply")}
					</Button>
				</DialogActions>
			</Dialog>
		</ContainerPage>
	)
}
