import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { GeneratedInvoiceServiceCostaRange, InvoicingData } from "../../../../apis/invoicing"
import { Table } from "../../../../components/table/table"
import { DialogIncoingDetails } from "../dialog-edit-invoicing-details/dialog-edit-invoicing-details"
import { tablePrintStyles } from "../invoicing-details.styles"

interface InvoicingMonthlyServiceDetailsProps {
	invoicing: InvoicingData | null
	refreshInvoicingData: () => void
	isPrinting?: boolean
}

export function InvoicingMonthlyServiceDetails({
	invoicing,
	refreshInvoicingData,
	isPrinting,
}: InvoicingMonthlyServiceDetailsProps) {
	const { t } = useTranslation()

	const [paginationQueries, setPaginationQueries] = useState({
		rowsPerPage: 10,
		pageNumber: 1,
	})

	const [invoicingEdit, setinvoicingEdit] = useState<InvoicingData | null>(null)

	const formatCostRange = (costRange?: GeneratedInvoiceServiceCostaRange) => {
		if (!costRange) return "Indeterminada"
		return `${costRange.usageStart} - ${costRange.usageEnd ?? t("service.unlimited")}`
	}

	const rows = useMemo(() => {
		return invoicing?.monthlyData?.services?.map((service) => ({
			id: invoicing.id,
			serviceId: service.id,
			costRanges: service.costRanges,
			name: service.name,
			total: service.total,
			price: service.price,
			costRangeUsed: formatCostRange(service.costRanges.find((costRange) => costRange.selected)),
			rangeCost: service.costRanges.find((costRange) => costRange.selected)?.cost,
			billingType: t(`product_select.tier_prices_billing_type_${service.billingType}`),
			costRangeModel: t(`product_select.tier_prices_type_${service.costRangeModel}`),
		}))
	}, [invoicing])

	const handleChangeRowsPerPage = (rowsPerPage: number) =>
		setPaginationQueries((prevState) => ({ ...prevState, rowsPerPage }))

	const handleChangePage = (crrPage: number) =>
		setPaginationQueries((prevState) => ({ ...prevState, pageNumber: crrPage++ }))

	const keys = useMemo(
		() => [
			"name",
			"billingType",
			"costRangeModel",
			"costRangeUsed",
			{ isMoney: true, value: "rangeCost", id: "rangeCost" },
			"total",
			{ isMoney: true, value: "price", id: "price" },
		],
		[rows, invoicing],
	)

	const columns = useMemo(
		() => [
			{
				label: t("columns.name"),
				id: "name",
			},
			{
				label: t("columns.billingModel"),
				id: "billingType",
			},
			{
				label: t("product_select.tier_prices_type"),
				id: "costRangeModel",
			},
			{
				label: t("consumption.invoicing.costRangeUsed"),
				id: "rangeCost",
			},
			{
				label: t("consumption.invoicing.rangeCost"),
				id: "rangeCost",
			},
			{
				label: t("columns.queries_amount"),
				id: "total",
			},
			{
				label: t("columns.value"),
				id: "price",
			},
		],
		[rows, t],
	)

	function handleInvoiceEdit(invoicingData: any) {
		setinvoicingEdit(invoicingData)
	}

	return (
		<>
			<DialogIncoingDetails
				refreshInvoicingData={refreshInvoicingData}
				setInvoicing={setinvoicingEdit}
				invoicing={invoicingEdit}
			/>
			<Table
				changeRowsPerPage={handleChangeRowsPerPage}
				page={paginationQueries.pageNumber - 1}
				changePage={handleChangePage}
				onDelete={() => null}
				columns={columns}
				keys={keys}
				rows={rows as []}
				onEdit={handleInvoiceEdit}
				isEditVisible={invoicing?.status === "APPROVED_OPERATIONAL"}
				titleStyles={isPrinting ? tablePrintStyles : {}}
				rowsPerPage={isPrinting ? 100 : paginationQueries.rowsPerPage}
				cellStyles={isPrinting ? tablePrintStyles : {}}
				tableContainerStyles={isPrinting ? { overflowX: "initial" } : {}}
				hidePagination={isPrinting}
			/>
		</>
	)
}
