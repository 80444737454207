import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { disableUsers, listAllUsers } from "../../apis/user"
import { BodyPage, ContainerPage } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { Filter } from "../../components/filter/filter"
import { HeaderPage } from "../../components/header/page/header-page"
import { LoadingTable } from "../../components/loading/loading"
import { IKey } from "../../components/table/row-customizations"
import { Table } from "../../components/table/table"
import { useAuthentication } from "../../hooks/app/useAuthentication"
import { pathNames } from "../../routes/paths"

export const ListUsers = () => {
	const [showDialogConfirmation, setShowDialogConfirmation] = useState<boolean>(false)
	const [selectedItems, setSelectedItems] = useState<any>()

	const [showFilter, setShowFilter] = useState<boolean>(false)
	const [stateFilter, setStateFilter] = useState<any>("enable")

	const [active, setActive] = useState<any>(true)

	const [rows, setRows] = useState<[]>([])

	const [currentPage, setCurrentPage] = useState<number>(1)
	const [pageSize, setPageSize] = useState<number>(10)
	const [filter, setFilter] = useState<string>("")
	const [pageCount, setPageCount] = useState<number>(0)

	const [loading, setLoading] = useState<boolean>(true)

	const navigate = useNavigate()

	const { policies, isAdmin } = useAuthentication()

	const { t } = useTranslation()

	const columns = [
		{
			label: t("columns.team"),
			id: "teamId",
		},
		{
			label: t("columns.name"),
			id: "name",
		},
		{
			label: t("columns.email"),
			id: "email",
		},
		{
			label: t("columns.role"),
			id: "role",
		},
		{
			label: t("columns.status"),
			id: "active",
		},
	]

	const keys: IKey<any>[] = [
		{
			isLink: true,
			value: "team",
			id: "teamId",
			path: pathNames.team.edit,
			backPath: pathNames.users.user.list,
		},
		"name",
		"email",
		"role",
		"active",
	]

	if (isAdmin) {
		columns.unshift({
			label: t("columns.organization"),
			id: "organizationId",
		})
		keys.unshift({
			isLink: true,
			value: "organization",
			id: "organizationId",
			path: pathNames.organization.edit,
			backPath: pathNames.queries.listBatch,
		} as any)
	}

	useEffect(() => {
		listUsers()
		// eslint-disable-next-line
	}, [pageSize, currentPage, filter, active])

	const normalizeUser = (user: any) => {
		return {
			...user,
			teamId: user.team?.id,
			team: user.team?.name,
			organizationId: user.organization?.id,
			organization: user.organization?.name,
			role: t(`roles.${user.role}`),
			apiKey: user.apiKey?.key,
		}
	}

	const listUsers = async () => {
		setLoading(true)

		const requestData = { currentPage, pageSize, filter, admin: false, active }

		try {
			const {
				data: { data },
			} = await listAllUsers(requestData)
			setRows(data?.list.map(normalizeUser))
			setPageCount(data?.total)
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	const redirectLink = (key: string, id: string) => {
		if (key === "organization") {
			navigate(pathNames.organization.edit + id)
		} else if (key === "team") {
			navigate(pathNames.team.edit + id)
		}
	}

	const selectActiveState = (state: string) => {
		setShowFilter(false)
		setStateFilter(state)

		if (state === "all") {
			setActive(null)
		} else if (state === "enable") {
			setActive(true)
			listUsers()
		} else if (state === "disable") {
			setActive(false)
			listUsers()
		}
	}

	const handlePageSize = (value: number) => {
		setPageSize(value)
		// listUsers()
	}

	const handleSelectedDisableItems = (items: any) => {
		setSelectedItems(items)
		setShowDialogConfirmation(true)
	}

	const handleDisableAllItems = async () => {
		setShowDialogConfirmation(false)
		setLoading(true)

		try {
			await disableUsers(selectedItems)
		} catch (error) {
		} finally {
			setLoading(false)
			listUsers()
		}
	}

	return (
		<ContainerPage>
			<HeaderPage
				searchValue={filter}
				onOpenFilter={() => setShowFilter(true)}
				onSearch={(event) => setFilter(event?.target?.value)}
				isCreateButtonVisible={policies?.users?.create}
				title={t("menu.users")}
				onClickTopButton={() => navigate(pathNames.users.user.create)}
				buttonTitle={t("customer.create_user_common_title")}
			/>
			<BodyPage>
				{!loading ? (
					<Table
						page={currentPage - 1}
						rowsPerPage={pageSize}
						changeRowsPerPage={(value) => handlePageSize(value)}
						changePage={(value) => setCurrentPage(value + 1)}
						onDelete={() => null}
						onEdit={(element: any) => navigate(pathNames.users.user.edit + element?.id)}
						onClickLink={redirectLink}
						columns={columns}
						rows={rows}
						keys={keys}
						isEditVisible={policies?.users?.update}
						isDeleteVisible={policies?.users?.delete}
						isReadVisible={policies?.users?.read}
						isSelectable={true}
						onDisableAll={handleSelectedDisableItems}
						pageCount={pageCount}
					/>
				) : (
					<LoadingTable />
				)}
			</BodyPage>
			<Filter
				show={showFilter}
				apply={(e) => selectActiveState(e)}
				cancel={() => setShowFilter(false)}
				value={stateFilter}
			/>
			<DialogAlert
				show={showDialogConfirmation}
				description={t("table.modal_disable_label_description")}
				title={t("table.disable")}
				accept={() => handleDisableAllItems()}
				decline={() => setShowDialogConfirmation(false)}
			/>
		</ContainerPage>
	)
}
