import { useCallback, useMemo, useState } from "react"
import { Bar as BarChart } from "react-chartjs-2"

import { Typography } from "@mui/material"

import { mountGraphOptions } from "../../../utils/graphs"
import {
	ContainerLegend,
	GraphContainer,
	GraphContent,
	LegendContent,
	LegendDescription,
	LegendName,
} from "../graph.styles"

export type UserDistributionDataset = {
	label: string
	backgroundColor: string[]
	data: number[]
}

export type UserDistributionGraphProps = {
	children?: React.ReactNode
	datasets: UserDistributionDataset[]
	labels: string[]
}

export const UserDistributionGraph = ({ datasets, labels }: UserDistributionGraphProps) => {
	const [filterLegend, setFilterLegend] = useState<string[]>([])

	const datasetLabels = useMemo(() => datasets.map((d) => d.label), [datasets])

	const handleLegendColor = useCallback(
		(crrIndex: number) => datasets?.[crrIndex].backgroundColor?.[0],
		[datasets],
	)

	const totalGeneral = useMemo(() => {
		if (!datasets || !datasets.length) return 0
		return datasets.reduce((acc, crr) => acc + crr.data.reduce((a, c) => a + c, 0), 0)
	}, [datasets])

	function handleLegendDescriptionText(crrIndex: number) {
		const total = datasets?.[crrIndex].data?.reduce((acc, crr) => acc + crr, 0)
		if (!total) return ""
		const regex = /(\d{1,3})(?=(\d{3})+(?!\d))/g
		return `${total.toString().replace(regex, "$1 ")} (${((total / totalGeneral) * 100).toFixed(
			1,
		)}%)`
	}

	function handleLegendFilter(label: string) {
		setFilterLegend((prev) => {
			if (prev.includes(label)) return prev.filter((f) => f !== label)
			return [...prev, label]
		})
	}

	const filteredDataset = useMemo(() => {
		if (!filterLegend.length) return datasets
		return datasets.filter((d) => filterLegend.includes(d.label))
	}, [datasets, filterLegend])

	if (!datasets || !datasets.length)
		return (
			<Typography variant="body2" align="center" color={"#626262"} mt={1}>
				NENHUM RESULTADO ENCONTRADO
			</Typography>
		)

	return (
		<GraphContainer gap="0px">
			<ContainerLegend>
				{datasetLabels.map((label, index) => (
					<LegendContent
						isInactive={Boolean(filterLegend.length) && !filterLegend.includes(label)}
						onClick={() => handleLegendFilter(label)}
						color={handleLegendColor(index)}
						key={label}
					>
						<LegendName>{label}</LegendName>
						<LegendDescription>{handleLegendDescriptionText(index)}</LegendDescription>
					</LegendContent>
				))}
			</ContainerLegend>

			<GraphContent style={{ height: "500px" }}>
				<BarChart
					options={{
						...mountGraphOptions(),
						indexAxis: "y",
						scales: {
							y: {
								stacked: true,
							},
							x: {
								stacked: true,
							},
						},
						elements: {
							bar: {
								borderWidth: 2,
							},
						},
						responsive: true,
					}}
					data={{
						labels: labels,
						datasets: filteredDataset.map((dataset) => ({
							label: dataset.label,
							data: dataset.data,
							backgroundColor: dataset.backgroundColor,
							borderColor: dataset.backgroundColor,
							borderWidth: 1,
						})),
					}}
				/>
			</GraphContent>
		</GraphContainer>
	)
}
