import { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { useReactToPrint } from "react-to-print"

import { ArcElement, Chart as ChartJS, Legend, RadialLinearScale, Tooltip } from "chart.js"

import { InvoicingData, getByIdCustomerInvoicing } from "../../../apis/invoicing"
import { BodyPage, ContainerPage } from "../../../components/container/container.styles"
import { HeaderPage } from "../../../components/header/page/header-page"
import { handleApiExceptions } from "../../../utils/handle-errors"
import { HeaderActions } from "./header-actions/header-actions"
import { InvoicingClassifications } from "./invoicing-classifications/invoicing-classifications"
import {
	Center,
	GraphSectionContainer,
	GraphSectionTitle,
	GraphTitleDivider,
} from "./invoicing-details.styles"
import { InvoicingMonthlyBatches } from "./invoicing-monthly-batches/invoicing-monthly-batches"
import { InvoicingMonthlyDetails } from "./invoicing-monthly-details/invoicing-monthly-details"
import { MonthlyEvolution } from "./invoicing-monthly-evolution/invoicing-monthly-evolution"
import { InvoicingMonthlyServiceDetails } from "./invoicing-monthly-service-details/invoicing-monthly-service-details"
import { InvoicingSummary } from "./invoicing-summary/invoicing-summary"
import { InvoicingTeamDistribuition } from "./invoicing-team-distribuition/invoicing-team-distribuition"

ChartJS.register(ArcElement, Tooltip, Legend, RadialLinearScale)

export type QueryInvoicingProps = {
	orgazizationId: string[]
	invoicingId: string
	startDate: string
	endDate: string
}

export function InvoiceDetailsPage() {
	const navigate = useNavigate()

	const { t } = useTranslation()

	const { id } = useParams()

	const [invoicing, setInvoicing] = useState<InvoicingData | null>(null)
	const [isPrinting, setIsPrinting] = useState<boolean>(false)

	function handleMonthToString(date: string | undefined) {
		if (!date) return "-"
		const monthParsed = date?.split("/")
		if (!monthParsed?.length) return []
		return monthParsed
	}

	const queryInvoicing: QueryInvoicingProps = useMemo(() => {
		const month = handleMonthToString(`${invoicing?.month}/${invoicing?.year}`)
		const yyyy = month?.[1]?.padStart(2, "0") ?? ""
		const mm = month?.[0]?.padStart(2, "0") ?? 0
		const lastMonthDay = new Date(Number(yyyy), Number(mm), 0).getDate()
		return {
			invoicingId: id,
			orgazizationId: [invoicing?.organizationId],
			startDate: `${mm}/01/${yyyy}`,
			endDate: `${mm}/${lastMonthDay}/${yyyy}`,
		} as QueryInvoicingProps
	}, [id, invoicing])

	const [isLoading, setIsLoading] = useState<boolean>(true)

	async function tryGetAllCustomerInvoicing() {
		if (!queryInvoicing.invoicingId) return
		try {
			setIsLoading(true)
			const { data } = await getByIdCustomerInvoicing(queryInvoicing.invoicingId)
			if (data) setInvoicing(data)
		} catch (error) {
			handleApiExceptions(error)
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		tryGetAllCustomerInvoicing()
	}, [queryInvoicing.invoicingId])

	const ref = useRef<HTMLDivElement>(null)

	const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

	const handlePrint = useReactToPrint({
		content: () => ref.current,
		pageStyle: `
			@media print {
				@page {
					size: A4;
					margin: 20px;
				}

				table {
					max-width: 100% !important;
				}
			}
		`,
		onBeforeGetContent: async () => {
			setIsPrinting(true)
			await sleep(1500)
		},

		onAfterPrint: () => {
			setIsPrinting(false)
		},
	})

	return (
		<ContainerPage
			style={{
				overflowY: isPrinting ? "hidden" : "auto",
			}}
		>
			<HeaderPage
				title={`${t("consumption.title.invoicing")} / ${invoicing?.organization?.name ?? ""}`}
				onClickTopButton={() => navigate(-1)}
				hideSaveButton
				searchHide
				isEdit
			/>
			<BodyPage>
				{isPrinting && (
					<Center
						style={{
							height: "calc(100vh - 157px)",
						}}
					>
						<img src={require("../../../assets/images/printer.gif")}></img>
					</Center>
				)}
				<div
					ref={ref}
					style={{
						width: isPrinting ? "1100px" : "auto",
						overflowY: isPrinting ? "hidden" : "auto",
					}}
				>
					<HeaderActions
						invoicing={invoicing}
						refreshInvoicingData={tryGetAllCustomerInvoicing}
						onPDF={handlePrint}
						isPrinting={isPrinting}
					/>

					<GraphSectionContainer>
						<GraphSectionTitle variant="h5" align="left">
							{t("consumption.invoicing.overview.title")}
						</GraphSectionTitle>
						<GraphTitleDivider />
						<InvoicingSummary invoicing={invoicing} isPrinting={isPrinting} />
					</GraphSectionContainer>

					<GraphSectionContainer>
						<GraphSectionTitle variant="h5" align="left">
							{t("consumption.invoicing.teamDistribuition.title")}
						</GraphSectionTitle>
						<GraphTitleDivider />
						<InvoicingTeamDistribuition invoicing={invoicing} isPrinting={isPrinting} />
					</GraphSectionContainer>

					<GraphSectionContainer mt="40px">
						<GraphSectionTitle variant="h5" align="left">
							{t("consumption.invoicing.classifications.title")}
						</GraphSectionTitle>
						<GraphTitleDivider />
						<InvoicingClassifications invoicing={invoicing} isPrinting={isPrinting} />
					</GraphSectionContainer>

					<GraphSectionContainer mt="40px">
						<GraphSectionTitle variant="h5" align="left">
							{t("consumption.invoicing.consumptionDetailsByUser.title")}
						</GraphSectionTitle>
						<GraphTitleDivider />
						<InvoicingMonthlyDetails
							refreshInvoicingData={tryGetAllCustomerInvoicing}
							invoicing={invoicing}
							isPrinting={isPrinting}
						/>
					</GraphSectionContainer>

					<GraphSectionContainer mt="40px">
						<GraphSectionTitle variant="h5" align="left">
							{t("consumption.invoicing.consumptionDetailsByService.title")}
						</GraphSectionTitle>
						<GraphTitleDivider />
						<InvoicingMonthlyServiceDetails
							refreshInvoicingData={tryGetAllCustomerInvoicing}
							invoicing={invoicing}
							isPrinting={isPrinting}
						/>
					</GraphSectionContainer>

					<GraphSectionContainer mt="40px">
						<GraphSectionTitle variant="h5" align="left">
							{t("consumption.invoicing.consumptionDetailsByBatch.title")}
						</GraphSectionTitle>
						<GraphTitleDivider />
						<InvoicingMonthlyBatches
							refreshInvoicingData={tryGetAllCustomerInvoicing}
							invoicing={invoicing}
							isPrinting={isPrinting}
						/>
					</GraphSectionContainer>

					<GraphSectionContainer mt="40px" mb="40px">
						<GraphSectionTitle variant="h5" align="left">
							{t("consumption.invoicing.monthlyEvolution.title")}
						</GraphSectionTitle>
						<GraphTitleDivider />
						<MonthlyEvolution
							queryInvoicing={queryInvoicing}
							month={invoicing?.month ?? 0}
							year={invoicing?.year ?? 0}
							isPrinting={isPrinting}
						/>
					</GraphSectionContainer>
				</div>
			</BodyPage>
		</ContainerPage>
	)
}
