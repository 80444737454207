import { useEffect, useState } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormControlLabel, Grid, Switch, Typography } from "@mui/material"

import { searchIp, updateIp } from "../../apis/ips"
import { BodyPage, FormContainerPage } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { HeaderPage } from "../../components/header/page/header-page"
import { Loading } from "../../components/loading/loading"
import { ControlledTextField } from "../../components/text-field/controlled-text-field"
import {
	EditIPLiberationData,
	EditIPLiberationSchema,
} from "../../libs/zod/ips-liberation/edit-ip-liberation"
import { pathNames } from "../../routes/paths"
import { styles } from "../../styles/common"
import { createErrorMessage } from "../../utils/format"
import { findIPsInRange, isValidIP } from "../../utils/ip"
import { IPsList, IPsListItem } from "./components/IpsList/ips-list"

export const EditIp = () => {
	const [loading, setLoading] = useState<boolean>(false)

	const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

	const [dialog, setDialog] = useState<boolean>(false)
	const [dialogTitle, setDialogTitle] = useState<string>("")
	const [dialogDescription, setDialogDescription] = useState<string>("")
	const [ipsList, setIpsList] = useState<IPsListItem[]>([])
	const [ipsBlockedOnDB, setIpsBlockedOnDB] = useState<string[]>([])

	const { t } = useTranslation()

	const navigate = useNavigate()

	const { ipId } = useParams()

	const methods = useForm<EditIPLiberationData>({
		resolver: zodResolver(EditIPLiberationSchema),
	})
	const {
		control,
		formState: { errors },
		reset,
		handleSubmit,
		watch,
	} = methods

	useEffect(() => {
		fetchData()
	}, [])

	const fetchData = async () => {
		setLoading(true)
		try {
			const {
				data: { data },
			} = await searchIp(ipId)
			reset({
				...data,
				id: ipId,
				ipBlocks: [],
			})
			setIpsBlockedOnDB(data.ipBlocks)
			handleIPsList(data.startIp, data.endIp)
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}

	const handleIPsList = (startIp: string, endIp: string) => {
		const allIPs = findIPsInRange(startIp, endIp)
		const ipsNormalized = allIPs.map((ip) => {
			return {
				id: ip,
				ip,
				active: !ipsBlockedOnDB.some((ipBlocked) => ipBlocked === ip),
			}
		})
		updateIpsListWithBlocksAndLiberations(ipsNormalized)
	}

	const ipBlocks = watch("ipBlocks")
	const ipLiberations = watch("ipLiberations")
	const startIp = watch("startIp")
	const endIp = watch("endIp")

	const updateIpsListWithBlocksAndLiberations = (ipsList: IPsListItem[]) => {
		const newIpsList = ipsList.map((ip: IPsListItem) => {
			const ipBlock = ipBlocks?.find(({ ip: ipBlockIp }: any) => ipBlockIp === ip.ip)
			if (ipBlock) {
				return {
					...ip,
					active: false,
				}
			}

			const ipLiberation = ipLiberations?.find(
				({ ip: ipLiberationIp }: any) => ipLiberationIp === ip.ip,
			)
			if (ipLiberation) {
				return {
					...ip,
					active: true,
				}
			}
			return ip
		})
		setIpsList(newIpsList)
	}

	useEffect(() => {
		const isValidRange = isValidIP(startIp) && isValidIP(endIp)
		isValidRange ? handleIPsList(startIp, endIp) : setIpsList([])
	}, [startIp, endIp])

	const handleCreateIpSubmit = async (data: EditIPLiberationData) => {
		setLoading(true)
		try {
			const isAlreadyBlockedOnDB = ({ ip }: { ip: string }) =>
				!ipsBlockedOnDB.some((ipBlocked) => ipBlocked === ip)
			const onlyIp = ({ ip }: { ip: string }) => ip
			const ipBlocks = data.ipBlocks.filter(isAlreadyBlockedOnDB).map(onlyIp)
			const ipLiberations = data.ipLiberations.map(onlyIp)
			await updateIp(ipId, { ...data, ipBlocks, ipLiberations })
			setDialogTitle(t("request_messages.edit_success_title", { name: "IP" }) as string)
			setDialogDescription(t("request_messages.edit_success_description", { name: "IP" }) as string)
		} catch (error: any) {
			setDialogTitle(t("request_messages.500") as string)
			if (error?.response?.data?.messages) {
				setDialogDescription(createErrorMessage(error?.response?.data?.messages))
			} else {
				setDialogDescription(t("request_messages.400") as string)
			}
		} finally {
			setDialog(true)
			setLoading(false)
		}
	}

	const handleAccept = () => {
		setDialog(false)
	}

	if (loading) return <Loading />

	return (
		<FormContainerPage onSubmit={handleSubmit(handleCreateIpSubmit)}>
			<HeaderPage
				onClickTopButton={() => navigate(pathNames.settings.ip_liberation.list)}
				description={t("ips.description_edit")}
				isEdit={true}
				title={t("ips.edit_title")}
			/>

			<BodyPage>
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					<Grid item xs={12} md={5}>
						<ControlledTextField
							id={"startIp"}
							style={styles.textFieldMarginTop}
							label={t("ips.startIp")}
							control={control}
							maskPlaceholder="0"
							alwaysShowMask
							errors={errors.startIp}
							rules={{ required: true }}
							helperText={errors.startIp?.message}
						/>
					</Grid>
					<Grid item xs={12} md={5}>
						<ControlledTextField
							id={"endIp"}
							maskPlaceholder="0"
							style={styles.textFieldMarginTop}
							label={t("ips.endIp")}
							control={control}
							errors={errors.endIp}
							rules={{ required: true }}
							helperText={errors.endIp?.message}
						/>
					</Grid>
					<Grid style={styles.active} item xs={2}>
						<Controller
							control={control}
							name="active"
							render={({ field: { value, ...fieldValues } }) => (
								<FormControlLabel
									control={<Switch {...fieldValues} color={"secondary"} checked={value} />}
									label={
										<Typography color={"secondary"}>{`${value ? "Ativo" : "Inativo"}`}</Typography>
									}
								/>
							)}
						/>
					</Grid>

					<Grid item xs={12} mt={3}>
						<FormProvider {...methods}>
							<IPsList ips={ipsList} setIps={setIpsList} />
						</FormProvider>
					</Grid>
				</Grid>
			</BodyPage>
			<DialogAlert
				title={t("ips.disable_message_title")}
				description={t("ips.disable_message_description")}
				show={showDeleteDialog}
				accept={() => {
					setShowDeleteDialog(false)
				}}
				decline={() => {
					setShowDeleteDialog(false)
				}}
			/>
			<DialogAlert
				onlyConfirm={true}
				show={dialog}
				accept={handleAccept}
				title={dialogTitle}
				description={dialogDescription}
			/>
		</FormContainerPage>
	)
}
