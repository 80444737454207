import axiosInstance from "./config/axios"
import { PagingResponse } from "./services"

export type Log = {
	id?: string
	title: string
	description: string
	date: string
	origin: string
	additionalInformation?: string
	apiKeyId: string
	organizationId: string
	userId: string
}

export type TLogSearchParams = {
	startDate: string
	endDate: string
	currentPage: number
	pageSize: number
	filter?: string
	tables?: string[]
	operations?: string[]
}

export const listHistoricLimit = async (id: string) =>
	await axiosInstance.get(`/serviceLimit/${id}/history`)

export const searchAuditLogs = async (params: TLogSearchParams) => {
	return axiosInstance.post<PortalAPIResponse<PagingResponse<LogResponse>>>(
		`/audit-logs/search`,
		params,
	)
}

export const downloadAuditLogsAsCSV = async (params: TLogSearchParams) => {
	const queryParams = new URLSearchParams(
		Object.entries(params).reduce((acc, [key, value]) => {
			if (Array.isArray(value)) {
				value.forEach((item) => {
					acc.append(key, item)
				})
			} else if (value) {
				acc.append(key, String(value))
			}
			return acc
		}, new URLSearchParams()),
	)

	const { data } = await axiosInstance.get<string>(
		`/audit-logs/csv-export?${queryParams.toString()}`,
	)
	return data
}

export const getAuditLogDetails = async (id: string) => {
	const { data } = await axiosInstance.get<PortalAPIResponse<LogDetailsResponse>>(
		`/audit-logs/${id}`,
	)
	return data
}

type DownloadCSVLogsParams = {
	localeStartDate: string
	localeEndDate: string
	organizationId?: string
}
export const downloadCSVLogs = async ({
	localeStartDate,
	localeEndDate,
	organizationId,
}: DownloadCSVLogsParams) =>
	await axiosInstance.post(`logs/exportToCSV`, {
		filters: {
			startDate: localeStartDate,
			endDate: localeEndDate,
			organizationId,
		},
		responseType: "text",
	})

export type PortalAPIResponse<T> = {
	data: T
	success: boolean
}

export type LogResponse = Log & {
	id: string
	operation: string
	tableName: string
	createdAt: string
	organization: {
		id: string
		name: string
	}
	author: {
		id: string
		email: string
	}
}

export type LogDetailsResponse = LogResponse & {
	record: object
	oldRecord: object
}

export interface Organization {
	id: string
	active: boolean
	createdAt: string
	name: string
	cnpj: string
	status: string
	imageUrl: string
	addressId: string
	billingEmail: string
	segment: string
	billingRecorrence: string
	contractExpiresAt: any
}

export interface User {
	id: string
	email: string
	role: string
	active: boolean
	lastAccessAt: any
}
