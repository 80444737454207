import { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { zodResolver } from "@hookform/resolvers/zod"
import DeleteIcon from "@mui/icons-material/Delete"
import VisibilityIcon from "@mui/icons-material/Visibility"
import { Button, Grid, IconButton } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { GridColDef } from "@mui/x-data-grid"
import { format, isValid, parseISO } from "date-fns"

import { enqueueSnackbar } from "notistack"
import {
	AdvancedSearchResponse,
	PagingParams,
	advancedSearch,
	deleteDeath,
} from "../../../apis/services"
import { FormContainerPage } from "../../../components/container/container.styles"
import { DataGrid } from "../../../components/dataGrid/DataGrid"
import { HeaderPage } from "../../../components/header/page/header-page"
import { ControlledTextField } from "../../../components/text-field/controlled-text-field"
import {
	AdvancedSearch,
	advancedSearchSchema,
} from "../../../libs/zod/advanced-search/advancedSearch.schema"
import { Police } from "../../../models/Police"
import { styles } from "../../../styles/common"
import { ConfirmationModal } from "./components/ConfirmationModal"
import { DetailsModal } from "./components/DetailsModal/DetailsModal"

const useStyles = makeStyles({
	root: {
		"& .MuiDataGrid-footerContainer ": {
			justifyContent: "flex-start",
		},
	},
})

const defaultPaginationModel = {
	page: 0,
	pageSize: 10,
}

export const CreateAdvancedSearch = () => {
	const [rows, setRows] = useState<AdvancedSearchResponse[]>([])
	const [loading, setLoading] = useState(true)
	const [customerPolices, setCustomersPolices] = useState<Police>()
	const { t } = useTranslation()
	const [currentSearch, setCurrentSearch] = useState<any>()
	const [paginationModel, setPaginationModel] = useState(defaultPaginationModel)
	const [currentDeath, setCurrentDeath] = useState<AdvancedSearchResponse>()

	const [detailsOpen, setDetailsOpen] = useState(false)
	const [confirmModalOpen, setConfirmModalOpen] = useState(false)
	const classes = useStyles()

	const handleClickOpen = (id: number) => {
		// eslint-disable-next-line
		debugger
		setDetailsOpen(true)
		const death = rows.find((el) => el.baseId === id)
		setCurrentDeath(death)
	}
	const handleClose = () => {
		setDetailsOpen(false)
	}

	const handleClickConfirmModalOpen = (id: number) => {
		setConfirmModalOpen(true)
		const death = rows.find((el) => el.baseId === id)
		setCurrentDeath(death)
	}
	const handleDelete = async () => {
		setLoading(true)
		try {
			if (!currentDeath?.baseId) throw new Error("Falta o ID do Óbito")

			await Promise.all([deleteDeath(currentDeath?.baseId), handleSearch(currentSearch)])

			setConfirmModalOpen(false)
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}
	const handleDeny = () => {
		setConfirmModalOpen(false)
	}

	const columns: GridColDef[] = [
		{
			headerName: t("columns.base_id") as string,
			field: "baseId",
		},
		{
			headerName: t("columns.name") as string,
			field: "nomeFalecido",
			width: 350,
		},
		{
			headerName: t("columns.document") as string,
			field: "cpf",
			width: 150,
		},
		{
			headerName: t("columns.mother") as string,
			field: "nomeMaeFalecido",
			width: 350,
		},
		{
			headerName: t("columns.birth") as string,
			field: "dataNascimento",
		},
		{
			headerName: t("columns.death") as string,
			field: "dataObito",
		},
		{
			headerName: t("columns.farming") as string,
			field: "dataLavrat",
		},
		{
			headerName: t("columns.actions") as string,
			field: "actions",
			width: 100,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<IconButton onClick={() => handleClickOpen(Number(params.id))}>
							<VisibilityIcon />
						</IconButton>
						<IconButton onClick={() => handleClickConfirmModalOpen(Number(params.id))}>
							<DeleteIcon />
						</IconButton>
					</div>
				</>
			),
		},
	]

	const keys = [
		"baseId",
		"nomeFalecido",
		"cpf",
		"nomeMaeFalecido",
		"dataNascimento",
		"dataObito",
		"dataLavrat",
	]

	const methods = useForm({ resolver: zodResolver(advancedSearchSchema), mode: "all" })
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = methods

	const formatDateOrReceiveInvalid = (strDate: string) => {
		return isValid(parseISO(strDate)) ? format(parseISO(strDate), "dd/MM/yyyy") : "Não localizado"
	}

	const handleSearch = async (params?: AdvancedSearch & PagingParams) => {
		setLoading(true)
		try {
			if (!params) return
			const page = params.page || params.page === 0 ? params.page + 1 : 1
			const advancedSearchResponse = await advancedSearch({ ...params, page })

			const formattedData = advancedSearchResponse?.data?.list?.map((item) => {
				const dataNascimento = formatDateOrReceiveInvalid(item.dataNascimento)
				const dataObito = formatDateOrReceiveInvalid(item.dataObito)
				const dataLavrat = formatDateOrReceiveInvalid(item.dataLavrat)
				const dataCadastro = formatDateOrReceiveInvalid(item.dataCadastro)

				return {
					...item,
					dataNascimento,
					dataObito,
					dataLavrat,
					dataCadastro,
				}
			})
			setRows(formattedData)
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		try {
			setLoading(true)
			const search = {
				...currentSearch,
				...paginationModel,
			}
			setCurrentSearch(search)

			handleSearch(search)
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}, [paginationModel])

	const onSubmit = async (data: any) => {
		const search = { ...data, ...defaultPaginationModel }
		setPaginationModel(defaultPaginationModel)
		setCurrentSearch(search)
		handleSearch(search)
	}

	const handleChangePageSize = (pageSize: number) => {
		setPaginationModel((prevState) => ({
			...prevState,
			pageSize,
		}))
	}

	const handleChangePage = (page: number) => {
		setPaginationModel((prevState) => ({
			...prevState,
			page: page,
		}))
	}

	const handleFieldsResert = () => {
		if (!Object.values(methods.getValues()).some(Boolean)) return
		methods.reset()
		enqueueSnackbar(t("service.fields_cleared_success"), { variant: "info" })
	}

	return (
		<FormContainerPage onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={2}>
				<Grid item xs={12} flexGrow={1}>
					<HeaderPage
						title={t("menu.advanced_search")}
						isEdit={true}
						description={t("service.search_description")}
						actionButtonTitle={t("service.button_search")}
						isCreateButtonVisible={false}
						disabledButton={loading}
					>
						<Button
							disabled={loading}
							type="button"
							color="secondary"
							style={{ textTransform: "initial", width: "200px" }}
							variant={"outlined"}
							onClick={handleFieldsResert}
						>
							{t("service.btn_clear")}
						</Button>
						<Button
							disabled={loading}
							type="submit"
							color="secondary"
							style={{ textTransform: "initial", margin: "0 16px" }}
							variant={"outlined"}
							hidden={true}
						>
							{t("service.button_search")}
						</Button>
					</HeaderPage>
				</Grid>
				<Grid item xs={12} flexGrow={1}>
					<FormProvider {...methods}>
						<Grid container rowSpacing={0.5} columnSpacing={{ xs: 0.5, sm: 1, md: 1.5 }}>
							<Grid item xs={12} sm={6} lg={4}>
								<ControlledTextField
									id={"nome"}
									style={styles.textFieldMarginTop}
									label={t("columns.name")}
									control={control}
									errors={errors.nome}
									helperText={errors.nome?.message as unknown as string}
									disabled={loading}
								/>
							</Grid>
							<Grid item xs={12} sm={6} lg={4}>
								<ControlledTextField
									id={"cpf"}
									style={styles.textFieldMarginTop}
									label={t("columns.document")}
									control={control}
									rules={{ required: false }}
									mask={"999.999.999-99"}
									errors={errors.cpf}
									helperText={errors.cpf?.message as unknown as string}
									disabled={loading}
								/>
							</Grid>
							<Grid item xs={12} lg={4}>
								<ControlledTextField
									id={"nomeMae"}
									style={styles.textFieldMarginTop}
									label={t("columns.mother")}
									control={control}
									rules={{ required: false }}
									errors={errors.nomeMae}
									helperText={errors.nomeMae?.message as unknown as string}
									disabled={loading}
								/>
							</Grid>
							<Grid item xs={6} lg={2}>
								<ControlledTextField
									id={"dtnascimento"}
									style={styles.textFieldMarginTop}
									label={t("columns.birth_start")}
									control={control}
									rules={{ required: false }}
									mask="99/99/9999"
									placeholder="dd/mm/yyyy"
									errors={errors.dtnascimento}
									helperText={errors.dtnascimento?.message as unknown as string}
									disabled={loading}
								/>
							</Grid>
							<Grid item xs={6} lg={2}>
								<ControlledTextField
									id={"dtnascimentofim"}
									style={styles.textFieldMarginTop}
									label={t("columns.birth_end")}
									control={control}
									rules={{ required: false }}
									mask="99/99/9999"
									placeholder="dd/mm/yyyy"
									errors={errors.dtnascimentofim}
									helperText={errors.dtnascimentofim?.message as unknown as string}
									disabled={loading}
								/>
							</Grid>
							<Grid item xs={6} lg={2}>
								<ControlledTextField
									id={"dtobitoinicio"}
									style={styles.textFieldMarginTop}
									label={t("columns.death_start")}
									control={control}
									rules={{ required: false }}
									mask="99/99/9999"
									placeholder="dd/mm/yyyy"
									errors={errors.dtobitoinicio}
									helperText={errors.dtobitoinicio?.message as unknown as string}
									disabled={loading}
								/>
							</Grid>
							<Grid item xs={6} lg={2}>
								<ControlledTextField
									id={"dtobitofim"}
									style={styles.textFieldMarginTop}
									label={t("columns.death_end")}
									control={control}
									rules={{ required: false }}
									mask="99/99/9999"
									placeholder="dd/mm/yyyy"
									errors={errors.dtobitofim}
									helperText={errors.dtobitofim?.message as unknown as string}
									disabled={loading}
								/>
							</Grid>
							<Grid item xs={6} lg={2}>
								<ControlledTextField
									id={"dtlavraturainicio"}
									style={styles.textFieldMarginTop}
									label={t("columns.farming_start")}
									control={control}
									rules={{ required: false }}
									mask="99/99/9999"
									placeholder="dd/mm/yyyy"
									errors={errors.dtlavraturainicio}
									helperText={errors.dtlavraturainicio?.message as unknown as string}
									disabled={loading}
								/>
							</Grid>
							<Grid item xs={6} lg={2}>
								<ControlledTextField
									id={"dtlavraturafim"}
									style={styles.textFieldMarginTop}
									label={t("columns.farming_end")}
									control={control}
									rules={{ required: false }}
									mask="99/99/9999"
									placeholder="dd/mm/yyyy"
									errors={errors.dtlavraturafim}
									helperText={errors.dtlavraturafim?.message as unknown as string}
									disabled={loading}
								/>
							</Grid>
						</Grid>
					</FormProvider>
				</Grid>
				<Grid item xs={12} flexGrow={3}>
					<DataGrid
						className={classes.root}
						page={paginationModel.page}
						rowsPerPage={paginationModel.pageSize}
						changeRowsPerPage={handleChangePageSize}
						changePage={handleChangePage}
						columns={columns}
						rows={rows}
						keys={keys}
						isEditVisible={customerPolices?.update}
						isDeleteVisible={customerPolices?.delete}
						isReadVisible={customerPolices?.read}
						isLoading={loading}
						paginationModel={paginationModel}
						setPaginationModel={setPaginationModel}
						localeText={{
							noRowsLabel: t("validations.no_rows"),
						}}
						idKey={"baseId"}
						sx={{ width: "100%", height: "100%", minHeight: "450px" }}
					/>
					{detailsOpen && currentDeath ? (
						<DetailsModal isOpen={detailsOpen} handleClose={handleClose} data={currentDeath} />
					) : null}

					{confirmModalOpen && currentDeath ? (
						<ConfirmationModal
							isOpen={confirmModalOpen}
							handleConfirm={handleDelete}
							handleDeny={handleDeny}
						/>
					) : null}
				</Grid>
			</Grid>
		</FormContainerPage>
	)
}
