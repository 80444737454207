import { z } from "zod"

import { RoleEnum } from "../../../pages/user/roles"

export const createUserSchema = z.object({
	name: z.string().min(3, "Nome muito curto").max(255, "Nome muito longo"),
	email: z.string().email("Email inválido"),
	organizationId: z.string({ message: "Campo Obrigatório" }).uuid("Organização é obrigatória"),
	phone: z.string().min(8, "Telefone é obrigatório"),
	teamId: z
		.string({
			message: "Campo obrigatório",
		})
		.uuid("Time é obrigatório"),
	role: z.nativeEnum(RoleEnum, { message: "Campo obrigatório" }),
	active: z.boolean().default(true),
})

export const updateUserSchema = z
	.object({
		id: z.string().uuid("Id inválido"),
		apiKey: z.object({
			key: z.string(),
		}),
	})
	.merge(createUserSchema)

export type CreateUserData = z.infer<typeof createUserSchema>
export type UpdateUserData = z.infer<typeof updateUserSchema>
