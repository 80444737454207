import React, { useEffect, useState } from "react"
import { Controller, useForm, UseFormReset, UseFormReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { DialogContentText, Divider, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import FormControl from "@mui/material/FormControl"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"

import { MultiSelectData } from "../../../../apis/dashboard"
import { listOptionsOrganizations } from "../../../../apis/organization"
import { styles } from "../../../../styles/common"
import { handleApiExceptions } from "../../../../utils/handle-errors"
import { ActionContainer, FooterContainer } from "./action-modal.styles"

type InvoiceActionModalProps = {
	onSubmit: (data: InvoiceActionModalDataProps) => void
	show: boolean
	title: string
	onClose: () => void
	LeftSideAction?: React.ElementType<{
		reset: UseFormReset<InvoiceActionModalFormProps>
		form: UseFormReturn<InvoiceActionModalFormProps>
	}>
	RightSideAction?: React.ElementType<{
		reset: UseFormReset<InvoiceActionModalFormProps>
		form: UseFormReturn<InvoiceActionModalFormProps>
	}>
	ExtraContent?: React.ElementType<{
		form: UseFormReturn<InvoiceActionModalFormProps>
	}>
}

type InvoiceActionModalFormProps = {
	organizationId: string
	date: Date
	confirm?: boolean
}

export type InvoiceActionModalDataProps = {
	organizationId: string
	date: Date
	confirm?: boolean
}

export const InvoiceActionModal = ({
	show,
	title,
	LeftSideAction,
	RightSideAction,
	onClose,
	onSubmit,
	ExtraContent,
}: InvoiceActionModalProps) => {
	const { t } = useTranslation()

	const form = useForm<InvoiceActionModalFormProps>()
	const { handleSubmit, control, reset, watch } = form

	const [organizations, setOrganizations] = useState<MultiSelectData["data"]>([])

	const getOrganizations = async () => {
		try {
			const {
				data: { data },
			} = await listOptionsOrganizations()
			setOrganizations(data)
		} catch (error) {
			handleApiExceptions(error)
		}
	}

	useEffect(() => {
		getOrganizations()
	}, [])

	const organizationId = watch("organizationId")
	const date = watch("date")

	useEffect(() => {
		if (organizationId && date) {
			console.log(organizationId, date)
		}
	}, [organizationId, date])

	const submitAndReset = (data: InvoiceActionModalFormProps) => {
		onSubmit(data)
		reset({
			organizationId: "",
			date: null!,
		})
	}
	return (
		<Dialog
			open={show}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth={"sm"}
			fullWidth={true}
		>
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			<Divider />
			<form onSubmit={handleSubmit(submitAndReset)}>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<Grid container rowSpacing={2} columnSpacing={2}>
							<Grid item xs={12}>
								<Controller
									control={control}
									name="organizationId"
									render={({ field: { onChange, name, value } }) => (
										<FormControl fullWidth>
											<InputLabel id="demo-simple-select-label">Organização</InputLabel>
											<Select
												style={styles.fullWidth}
												value={value}
												name={name}
												label={t("consumption.invoicing.organization")}
												onChange={onChange}
											>
												{organizations.map((organization, index) => (
													<MenuItem key={index} value={organization.id}>
														{organization.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
								/>
							</Grid>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<Grid item xs={12}>
									<Controller
										defaultValue={null!}
										control={control}
										name="date"
										render={({ field: { onChange, name, value } }) => (
											<DatePicker
												sx={styles.fullWidth}
												views={["year", "month"]}
												label={t("consumption.invoicing.month")}
												onChange={onChange}
												format="MM/YYYY"
												value={value}
												openTo="year"
												name={name}
											/>
										)}
									/>
								</Grid>
							</LocalizationProvider>
						</Grid>

						{ExtraContent && <ExtraContent form={form} />}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<FooterContainer>
						{LeftSideAction && <LeftSideAction form={form} reset={reset} />}
						<ActionContainer>
							{RightSideAction && <RightSideAction form={form} reset={reset} />}
						</ActionContainer>
					</FooterContainer>
				</DialogActions>
			</form>
		</Dialog>
	)
}
