export const ipStringToInt = (ip: string): number => {
	const parts = ip?.split(".")

	if (!parts) return 0

	return (
		parseInt(parts[0], 10) * 16777216 +
		parseInt(parts[1], 10) * 65536 +
		parseInt(parts[2], 10) * 256 +
		parseInt(parts[3], 10)
	)
}

export const isValidIPRange = (startIp: string, endIp: string): boolean => {
	const endIpValue: number = ipStringToInt(endIp)
	const starIpValue: number = ipStringToInt(startIp)

	return starIpValue <= endIpValue
}

export const isValidIP = (ip: string): boolean => {
	if (!ip || ip === "") return false
	const ipArray = ip.split(".")
	if (ipArray.length !== 4) return false
	return ipArray.every((octet) => {
		if (octet === "") return false
		const octetNumber = Number(octet)
		return octetNumber >= 0 && octetNumber <= 255
	})
}

export const findIPsInRange = (startIp: string, endIp: string): string[] => {
	const startIpValue = ipStringToInt(startIp)
	const endIpValue = ipStringToInt(endIp)

	const ips: string[] = []

	for (let i = startIpValue; i <= endIpValue; i++) {
		const ip = `${(i >> 24) & 0xff}.${(i >> 16) & 0xff}.${(i >> 8) & 0xff}.${i & 0xff}`
		ips.push(ip)
	}

	return ips
}
