import { enqueueSnackbar } from "notistack"

import { getBatchConsultFileSignedURL } from "../../../apis/services"

export enum BatchFileType {
	RESULT = "result",
	ORIGINAL = "original",
}

export const downloadFile = (filetype: BatchFileType) => async (row: any) => {
	try {
		const { data } = await getBatchConsultFileSignedURL(row.id, filetype)
		window.open(data.signedUrl, "_blank")
	} catch (error) {
		enqueueSnackbar("Ops! Ocorreu um erro ao baixar o arquivo. Tente novamente mais tarde.", {
			variant: "error",
		})
	}
}
