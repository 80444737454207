import { Policies } from "../../models/Police"
import { RoleEnum } from "../../pages/user/roles"
import { rootPathByRole } from "../../routes/paths"
import { AuthStorage } from "../../storage/auth-type"
import { getItem } from "../../storage/local-storage"

export type UserInfo = {
	id: string
	userName: string
	role: string
	organizaionBillingType: string
	apiKeyId: string
	organizationId: string
	organizationName: string
	clientId: string
	teamId: string
	apiKey: string
	authorizationToken: string
}

export const useAuthentication = () => {
	const policies = getItem<Policies>(AuthStorage.userPolicy)
	const user = getItem<UserInfo>(AuthStorage.userInformation)
	const token = getItem(AuthStorage.userToken)
	const apiKey = getItem(AuthStorage.userApiKey)

	const rootPath = rootPathByRole[user?.role as RoleEnum]

	const isAdmin = user?.role === RoleEnum.Admin
	const isOperator = user?.role === RoleEnum.Operator
	const isManager = user?.role === RoleEnum.Manager

	const isAuthenticatedUser = isAdmin || isOperator || isManager

	return {
		policies,
		user,
		token,
		isAuthenticated: !!token,
		apiKey,
		rootPath,
		isAdmin,
		isOperator,
		isManager,
		isAuthenticatedUser,
	}
}
