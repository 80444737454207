import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import PaymentIcon from "@mui/icons-material/Payment"
import {
	Accordion,
	Alert,
	AlertTitle,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
} from "@mui/material"
import AccordionDetails from "@mui/material/AccordionDetails"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"

import { AccordionSummary } from "../../../components/accordion/accordion-summary"
import { ControlledTextField } from "../../../components/text-field/controlled-text-field"
import { CreateOrganizationData } from "../../../libs/zod/organization/create-organization"
import { styles } from "../../../styles/common"
import { BillingRecorrence } from "../enum"

export const BillingDataSection = () => {
	const { t } = useTranslation()
	const {
		formState: { errors },
		control,
		getValues,
		watch,
	} = useFormContext<CreateOrganizationData>()

	watch("billingRecorrence")

	const active = watch("active")

	const hasError = !!(errors.billingEmail || errors.contractExpiresAt)

	return (
		<Accordion>
			<AccordionSummary
				id={"panel3a"}
				title={t("organizations.according.billing_information")}
				icon={<PaymentIcon style={{ marginRight: 8 }} color={"secondary"} />}
				error={hasError}
			/>
			<AccordionDetails>
				<Grid item xs={8}>
					<ControlledTextField
						id={"billingEmail"}
						label={t("organizations.label_billing_email")}
						control={control}
						type={"email"}
						disabled={!active}
						errors={errors.billingEmail}
						helperText={errors.billingEmail?.message}
						// eslint-disable-next-line
						validChars={
							/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
						}
						style={styles.textFieldMarginTop}
					/>
				</Grid>
				<Grid item xs={4}>
					<FormControl
						color={"secondary"}
						style={styles.textFieldMarginTop}
						variant="standard"
						disabled={!active}
					>
						<InputLabel style={{ padding: "0 0 10px 10px" }} id={"billingRecorrenceLabel"}>
							{t("organizations.label_charge")}
						</InputLabel>
						<Controller
							control={control}
							name="billingRecorrence"
							defaultValue="monthly"
							render={({ field }) => (
								<Select
									{...field}
									id={"billingRecorrence"}
									labelId={"billingRecorrenceLabel"}
									variant={"filled"}
									size={"small"}
									disabled={!active}
								>
									<MenuItem value={"monthly"}>{t("organizations.monthly")}</MenuItem>
									<MenuItem value={"loose"}>{t("organizations.loose")}</MenuItem>
								</Select>
							)}
						/>
						{getValues("billingRecorrence") === BillingRecorrence.LOOSE && (
							<Controller
								name="contractExpiresAt"
								control={control}
								defaultValue={dayjs(new Date())}
								render={({ field, fieldState: { error } }) => (
									<div style={{ marginTop: 16 }}>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DatePicker
												{...field}
												format="DD/MM/YYYY"
												views={["year", "month", "day"]}
												label={t("organizations.label_contract_expires_at")}
												disabled={!active}
												slotProps={{
													textField: {
														error: !!error,
														helperText: error?.message,
													},
												}}
											/>
										</LocalizationProvider>
									</div>
								)}
							/>
						)}
					</FormControl>
					<Alert style={{ marginTop: 16 }} severity="info">
						<AlertTitle>Entenda os tipos de cobrança</AlertTitle>
						<strong>{t("organizations.monthly")}</strong> - A cobrança mensal refere-se a uma
						quantia fixa que é faturada regularmente a cada mês.
						<br />
						<strong>{t("organizations.loose")}</strong> - A cobrança avulsa refere-se a pagamentos
						pontuais pela utilização de serviços que serão consumidos em quantidade e período
						pré-determinados.
					</Alert>
				</Grid>
			</AccordionDetails>
		</Accordion>
	)
}
