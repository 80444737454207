import { ReactElement } from "react"
import { Navigate, Outlet } from "react-router-dom"

import Header from "../components/header/header"
import { Menu } from "../components/menu/main/menu"
import { AuthStorage } from "../storage/auth-type"
import { getItem } from "../storage/local-storage"
import { Container } from "./routes.styles"

type PrivateRouteProps = {
	children?: ReactElement
	isAuthenticated: boolean
	showMenu?: boolean
	isStandalone?: boolean
}

const PrivateRoute = ({
	children,
	isAuthenticated,
	showMenu = true,
	isStandalone = false,
}: PrivateRouteProps) => {
	const item = getItem(AuthStorage.userToken)

	if (item || isAuthenticated) {
		if (isStandalone) {
			return <Outlet />
		} else {
			return (
				<>
					<Header isLogged={true} />
					<Container>
						{showMenu && <Menu />}
						{children ?? <Outlet />}
					</Container>
				</>
			)
		}
	}

	return <Navigate to="/" />
}

export default PrivateRoute
