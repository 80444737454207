import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { Container, LinearProgress, Stack } from "@mui/material"

import { BoxCenter } from "../../components/container/container.styles"
import { pathNames } from "../../routes/paths"
import { AuthStorage } from "../../storage/auth-type"
import { clearAllItems, getItem } from "../../storage/local-storage"
import { removeChatbot } from "../../utils/helper"

export const SignOut = () => {
	const navigate = useNavigate()

	useEffect(() => {
		signOut()
	})

	const signOut = async () => {
		const userInformation = getItem(AuthStorage.userInformation)
		if (!userInformation || !Object.keys(getItem(AuthStorage.userInformation))?.length) return

		clearAllItems()
		removeChatbot()
		navigate(pathNames.home)
	}

	return (
		<Container>
			<BoxCenter>
				<Stack sx={{ width: "75%", margin: "16px 0" }} spacing={2}>
					<LinearProgress color="primary" />
				</Stack>
			</BoxCenter>
		</Container>
	)
}
