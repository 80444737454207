function lerpColor(color1: string, color2: string, amount: number): string {
	const hexToRgb = (hex: string): number[] => {
		const bigint = parseInt(hex.slice(1), 16)
		const r = (bigint >> 16) & 255
		const g = (bigint >> 8) & 255
		const b = bigint & 255
		return [r, g, b]
	}

	const rgbToHex = (rgb: number[]): string => {
		const [r, g, b] = rgb
		return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
	}

	const rgb1 = hexToRgb(color1)
	const rgb2 = hexToRgb(color2)

	const mixedRgb = rgb1.map((c, i) => Math.round(c + (rgb2[i] - c) * amount))

	return rgbToHex(mixedRgb)
}

export function generateColors(steps: number, color1 = "#309587", color2 = "#1f4077"): string[] {
	const colors = []

	for (let i = 0; i <= steps; i++) {
		colors.push(lerpColor(color1, color2, 0.3 * i))
	}

	return colors
}
