import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { disableTeams, listAllTeams } from "../../apis/team"
import { BodyPage, ContainerPage } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { Filter } from "../../components/filter/filter"
import { HeaderPage } from "../../components/header/page/header-page"
import { LoadingTable } from "../../components/loading/loading"
import { Table } from "../../components/table/table"
import { useAuthentication } from "../../hooks/app/useAuthentication"
import { pathNames } from "../../routes/paths"

const ListTeam = () => {
	const [showDialogConfirmation, setShowDialogConfirmation] = useState<boolean>(false)
	const [selectedItems, setSelectedItems] = useState<any>()

	const [showFilter, setShowFilter] = useState<boolean>(false)
	const [active, setActive] = useState<any>("enable")

	const [rows, setRows] = useState<[]>([])

	const [currentPage, setCurrentPage] = useState<number>(1)
	const [pageSize, setPageSize] = useState<number>(10)
	const [filter, setFilter] = useState<string>("")
	const [pageCount, setPageCount] = useState<number>(0)

	const [loading, setLoading] = useState<boolean>(true)

	const navigate = useNavigate()

	const { policies, isAdmin } = useAuthentication()

	const { t } = useTranslation()

	const columns = [
		{
			label: t("columns.teamName"),
			id: "name",
		},
		{
			label: t("columns.description"),
			id: "description",
		},
		{
			label: t("columns.members"),
			id: "membersCount",
		},
		{
			label: t("menu.services"),
			id: "servicesCount",
		},
		{
			label: t("columns.status"),
			id: "active",
		},
	]

	const keys = ["name", "description", "membersCount", "servicesCount", "active"]

	if (isAdmin) {
		columns.unshift({
			label: t("columns.organization"),
			id: "organizationId",
		})

		keys.unshift({
			isLink: true,
			value: "organizationName",
			path: pathNames.organization.edit,
			backPath: pathNames.team.list,
			id: "organizationId",
		} as any)
	}

	useEffect(() => {
		listTeams()
		// eslint-disable-next-line
	}, [pageSize, filter, currentPage])

	const listTeams = async (status?: any) => {
		try {
			setLoading(true)
			const {
				data: { data },
			} = await listAllTeams({ currentPage, pageSize, filter, active: status })

			const formatData = data.list.map((e: any) => {
				e.organizationName = e.organization.name
				return e
			})

			setPageCount(data.total)

			setRows(formatData)
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const selectActiveState = (state: string) => {
		setShowFilter(false)
		setActive(state)
		if (state === "all") {
			listTeams(null)
		} else if (state === "enable") {
			listTeams(true)
		} else if (state === "disable") {
			listTeams(false)
		}
	}

	const handleSelectedDisableItems = (items: any) => {
		setSelectedItems(items)
		setShowDialogConfirmation(true)
	}

	const handleDisableAllItems = async () => {
		setShowDialogConfirmation(false)
		setLoading(true)

		try {
			await disableTeams(selectedItems)
		} catch (error) {
		} finally {
			setLoading(false)
			listTeams()
		}
	}

	return (
		<ContainerPage>
			<HeaderPage
				searchValue={filter}
				onSearch={(event) => setFilter(event?.target?.value)}
				isCreateButtonVisible={policies?.teamPolices?.create}
				title={t("menu.teams")}
				onOpenFilter={() => setShowFilter(true)}
				buttonTitle="Criar novo time"
				onClickTopButton={() => navigate(pathNames.team.create)}
			/>
			<BodyPage>
				{!loading ? (
					<Table
						page={currentPage - 1}
						rowsPerPage={pageSize}
						changeRowsPerPage={(value) => setPageSize(value)}
						changePage={(page) => setCurrentPage(page + 1)}
						onDelete={() => null}
						onEdit={(element: any) => navigate(pathNames.team.edit + element?.id)}
						columns={columns}
						rows={rows}
						keys={keys}
						isEditVisible={policies?.teamPolices?.update}
						isDeleteVisible={policies?.teamPolices?.delete}
						isReadVisible={policies?.teamPolices?.read}
						isSelectable={true}
						onDisableAll={handleSelectedDisableItems}
						pageCount={pageCount}
					/>
				) : (
					<LoadingTable />
				)}
			</BodyPage>
			<Filter
				show={showFilter}
				apply={(e) => selectActiveState(e)}
				cancel={() => setShowFilter(false)}
				value={active}
			/>
			<DialogAlert
				show={showDialogConfirmation}
				description={t("table.modal_disable_label_description")}
				title={t("table.disable")}
				accept={() => handleDisableAllItems()}
				decline={() => setShowDialogConfirmation(false)}
			/>
		</ContainerPage>
	)
}

export default ListTeam
