import { useRef } from "react"

import AppBlockingIcon from "@mui/icons-material/AppBlocking"
import { Button } from "@mui/material"
import { GridToolbarContainer } from "@mui/x-data-grid"

import { isValidIP } from "../../../../../utils/ip"
import { IPsListItem } from "../ips-list"
import { VisuallyHiddenInput } from "./ips-list-toolbar.styles"

export type IpsListToolbarProps = {
	onFileUpload: (ips: IPsListItem[]) => void
}

export const IpsListToolbar = ({ onFileUpload }: IpsListToolbarProps) => {
	const inputFileRef = useRef<HTMLInputElement>(null)

	const handleIPsListBlock = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0]
		if (!file) return

		const reader = new FileReader()
		reader.onload = (e) => {
			const content = e.target?.result as string
			const line = content.split("\n")
			const ips = line
				.filter((ip) => isValidIP(ip))
				.map((ip) => {
					return {
						id: ip,
						ip,
						active: false,
					}
				})
			onFileUpload(ips)
		}
		reader.readAsText(file)

		// clear files
		if (inputFileRef.current) {
			inputFileRef.current.value = ""
		}
	}

	return (
		<GridToolbarContainer>
			<Button
				component="label"
				role={undefined}
				tabIndex={-1}
				color="secondary"
				startIcon={<AppBlockingIcon />}
			>
				Enviar lista de bloqueios
				<VisuallyHiddenInput
					type="file"
					accept="text/csv"
					onChange={handleIPsListBlock}
					ref={inputFileRef}
				/>
			</Button>
		</GridToolbarContainer>
	)
}
