import { useState } from "react"
import { Controller, useFieldArray, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import { Button, Checkbox, ListItem, TextField, Typography } from "@mui/material"

import { SnackBar } from "../snackbar/snackbar"
import {
	Container,
	ContainerAddiction,
	ContainerList,
	Grid,
	RoundDelete,
} from "./batch-fields.styles"

type BatchFieldsProps = {
	onClickHistoric?: () => void
	readonly?: boolean
}

export const BatchFields = ({ readonly = false }: BatchFieldsProps) => {
	const { t } = useTranslation()
	const [productExists, setProductExists] = useState<boolean>(false)
	const { control, register, watch } = useFormContext()
	const { fields, append, remove, update } = useFieldArray({
		control,
		name: "batchFields",
		keyName: "key",
	})

	const handleAddNewField = () => {
		const newField = {
			name: "",
			example: "",
			description: "",
			required: false,
		}

		append(newField)
	}

	const handleDeleteField = (index: number) => {
		const field: any = fields[index]
		if (field?.id) {
			update(index, {
				...fields[index],
				deletedAt: new Date().toISOString(),
			})
		} else remove(index)
	}

	const watchFields = watch("batchFields")

	const nameHasBeenUsed = (name: string, index: number): boolean => {
		if (!name) return false
		return (
			watchFields.filter((field: any, i: number) => field.name === name && i < index).length > 0
		)
	}

	return (
		<>
			{fields.length > 0 && (
				<Container>
					<ContainerAddiction>
						<Grid margin={"0 0 8px 0"}>
							<Typography color={"secondary"} variant={"h6"}>
								{t("service.batch_fields_title")}
							</Typography>
							{!readonly && (
								<Typography color="textPrimary" variant={"body2"}>
									{t("service.batch_fields_description")}
								</Typography>
							)}
						</Grid>

						{!readonly && (
							<Grid direction={"row"} style={{ justifyContent: "flex-end" }}>
								<Button
									disabled={readonly}
									color={"secondary"}
									onClick={handleAddNewField}
									style={styles.button}
									variant={"outlined"}
								>
									<AddIcon style={{ marginRight: 8 }} />
									{t("service.batch_fields_field_add")}
								</Button>
							</Grid>
						)}
					</ContainerAddiction>
					<ContainerList>
						{fields.map(
							(element: any, index: number) =>
								!element?.deletedAt && (
									<ListItem
										key={element.key}
										style={{ borderBottom: "1px solid #9393935a", width: "100%" }}
										component="div"
										disablePadding
									>
										<TextField
											{...register(`batchFields.${index}.name`)}
											style={styles.flex1}
											variant="standard"
											disabled={readonly}
											label={t("service.batch_fields_field_name")}
											color="secondary"
											error={nameHasBeenUsed(watchFields[index].name, index)}
											helperText={
												nameHasBeenUsed(watchFields[index].name, index) &&
												t("service.batch_fields_field_name_error")
											}
											FormHelperTextProps={{
												style: {
													position: "absolute",
													bottom: -17,
													fontSize: 11,
												},
											}}
										/>
										<TextField
											style={styles.flex1}
											variant="standard"
											label={t("service.batch_fields_field_example")}
											color="secondary"
											disabled={readonly}
											{...register(`batchFields.${index}.example`)}
										/>
										<TextField
											style={styles.flex2}
											variant="standard"
											label={t("service.batch_fields_field_description")}
											color="secondary"
											disabled={readonly}
											{...register(`batchFields.${index}.description`)}
										/>
										<Controller
											control={control}
											name={`batchFields.${index}.required`}
											render={({ field: { onChange, value } }) => (
												<>
													<Checkbox onChange={onChange} checked={value} disabled={readonly} />
													<Typography color={"textPrimary"}>{t("fields.required")}</Typography>
												</>
											)}
										/>

										{!readonly && (
											<RoundDelete>
												<DeleteIcon
													onClick={() => handleDeleteField(index)}
													style={{ cursor: "pointer" }}
													color={"error"}
												/>
											</RoundDelete>
										)}
									</ListItem>
								),
						)}
					</ContainerList>
					<SnackBar
						open={productExists}
						type={"error"}
						message={t("product_select.product_already_exists")}
						handleClose={() => setProductExists(false)}
					/>
				</Container>
			)}
		</>
	)
}

const styles = {
	chip: { minWidth: 200 },
	button: { height: 50, alignSelf: "flex-end" },
	typography: { marginBottom: "-16px" },
	formControl: { margin: "8px 8px 8px 0" },
	flex1: { flex: 1, marginRight: 8 },
	flex2: { flex: 2, marginRight: 8 },
}
