import { Dispatch, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import CloseIcon from "@mui/icons-material/Close"
import {
	Button,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
} from "@mui/material"

import {
	GeneratedInvoiceServiceCostaRange,
	InvoicingData,
	updateInvoicePrice,
} from "../../../../apis/invoicing"
import { PageLoading } from "../../../../components/loading/page-loading"
import { useCurrency } from "../../../../hooks/app/useCurrency"
import { styles } from "../../../../styles/common"
import { handleApiExceptions } from "../../../../utils/handle-errors"
import { Container, Content, Footer, Header } from "./dialog-edit-invoicing-details.styles"

// import { currencyMask } from "text-mask-addons/dist/textMaskAddons"

interface DialogIncoingDetailsProps {
	invoicing: any | null
	setInvoicing: Dispatch<InvoicingData | null>
	refreshInvoicingData: () => void
}

export function DialogIncoingDetails({
	refreshInvoicingData,
	setInvoicing,
	invoicing,
}: DialogIncoingDetailsProps) {
	const { t } = useTranslation()

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [price, setPrice] = useState("0.00")
	const [priceDiff, setPriceDiff] = useState(0)
	const [justification, setJustification] = useState("")

	const { formatCurrency } = useCurrency()

	useEffect(() => {
		setPrice(parseFloat(invoicing?.price).toFixed(2))
		setPriceDiff(0)
		setJustification("")
	}, [invoicing])

	function onCloseDialogIncoingDetails() {
		setInvoicing?.(null)
	}

	async function tryUpdateInvoiceMonthData() {
		if (!invoicing?.id) return

		const payload = {
			priceDiff,
			justification,
			serviceId: invoicing?.serviceId,
		}

		await updateInvoicePrice(invoicing.id, payload)
	}

	async function onSubmitChanges() {
		setIsLoading(true)
		try {
			await tryUpdateInvoiceMonthData()
			onCloseDialogIncoingDetails()
			refreshInvoicingData()
		} catch (error) {
			handleApiExceptions(error)
		} finally {
			setIsLoading(false)
		}
	}

	const costRange = useMemo(() => {
		const costRange = invoicing?.costRanges?.find(
			(c: GeneratedInvoiceServiceCostaRange) => c?.selected,
		) as GeneratedInvoiceServiceCostaRange
		if (!costRange) return ""
		return `De ${costRange.usageStart} a ${costRange.usageEnd} (${formatCurrency(+costRange.cost)})`
	}, [invoicing])

	const priceDiffFormatted = useMemo(() => {
		return priceDiff !== 0
			? `${priceDiff > 0 ? "(+) " : "(-) "}${formatCurrency(Math.abs(priceDiff))}`
			: ""
	}, [priceDiff])

	const handlePriceChange = (e: any) => {
		const value = parseFloat(e.target.value).toFixed(2)
		const diff = +(parseFloat(value) - parseFloat(invoicing?.price)).toFixed(2)
		setPrice(value)
		setPriceDiff(diff)
	}

	return (
		<Container open={Boolean(invoicing)}>
			<Content>
				<PageLoading isLoading={isLoading} />
				<Header>
					<Typography variant="h6">{t("consumption.invoicing.dialogEdit.title")}</Typography>
					<IconButton
						aria-label="close"
						onClick={onCloseDialogIncoingDetails}
						sx={{
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				</Header>
				<Divider sx={{ margin: "20px 0" }} />
				<Grid container spacing={1} columns={2}>
					<Grid item xs={2} md={1}>
						<TextField
							color="secondary"
							size="small"
							id="filled-basic"
							variant="filled"
							disabled
							label={t("consumption.invoicing.dialogEdit.fields.product")}
							style={styles.fullWidth}
							value={invoicing?.name}
						/>
					</Grid>

					<Grid item xs={2} md={1}>
						<TextField
							color="secondary"
							size="small"
							id="filled-basic"
							variant="filled"
							disabled
							label={t("consumption.invoicing.costRangeUsed")}
							style={styles.fullWidth}
							value={costRange}
						/>
					</Grid>

					<Grid item xs={2} md={1}>
						<TextField
							color="secondary"
							size="small"
							id="filled-basic"
							variant="filled"
							disabled
							label={t("consumption.invoicing.dialogEdit.fields.billingType")}
							style={styles.fullWidth}
							value={invoicing?.billingType}
						/>
					</Grid>

					<Grid item xs={2} md={1}>
						<TextField
							color="secondary"
							size="small"
							id="filled-basic"
							variant="filled"
							disabled
							label={t("product_select.tier_prices_type")}
							style={styles.fullWidth}
							value={invoicing?.costRangeModel}
						/>
					</Grid>

					<Grid item xs={2} md={1}>
						<TextField
							color="secondary"
							size="small"
							id="filled-basic"
							variant="filled"
							disabled
							label={t("consumption.invoicing.dialogEdit.fields.totalUsage")}
							style={styles.fullWidth}
							value={invoicing?.total}
						/>
					</Grid>

					<Grid item xs={2} md={1}>
						<TextField
							color="secondary"
							size="small"
							id="filled-basic"
							variant="filled"
							type={"number"}
							label={t("consumption.invoicing.dialogEdit.fields.billingValue")}
							style={styles.fullWidth}
							value={price}
							helperText={priceDiffFormatted}
							FormHelperTextProps={{
								sx: {
									color: priceDiff > 0 ? "primary.main" : "error.main",
								},
							}}
							InputProps={{
								startAdornment: <InputAdornment position="start">R$</InputAdornment>,
							}}
							onChange={handlePriceChange}
						/>
					</Grid>

					{priceDiff !== 0 && (
						<Grid item xs={2}>
							<TextField
								color="secondary"
								size="small"
								id="filled-basic"
								variant="filled"
								label={t("consumption.invoicing.dialogEdit.fields.justification")}
								style={styles.fullWidth}
								value={justification}
								rows={4}
								multiline
								onChange={(e) => setJustification(e.target.value)}
								InputLabelProps={{
									shrink: true,
								}}
								placeholder={
									t("consumption.invoicing.dialogEdit.placeholder.justification") as string
								}
							/>
						</Grid>
					)}
				</Grid>

				<Footer>
					<Button
						disabled={priceDiff === 0 || justification === ""}
						onClick={onSubmitChanges}
						color={"secondary"}
						variant="outlined"
						size="small"
					>
						{t("consumption.invoicing.dialogEdit.save")}
					</Button>
				</Footer>
			</Content>
		</Container>
	)
}
