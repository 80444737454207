import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { zodResolver } from "@hookform/resolvers/zod"
import { Alert, Autocomplete, DialogContentText, Grid, TextField } from "@mui/material"

import { MultiSelectData } from "../../apis/dashboard"
import { listOptionsOrganizations } from "../../apis/organization"
import { listAllTeams, searchTeamByOrganization } from "../../apis/team"
import { createUser } from "../../apis/user"
import { BodyPage, FormContainerPage } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { HeaderPage } from "../../components/header/page/header-page"
import { Loading } from "../../components/loading/loading"
import { ControlledTextField } from "../../components/text-field/controlled-text-field"
import { useAuthentication } from "../../hooks/app/useAuthentication"
import { CreateUserData, createUserSchema } from "../../libs/zod/user/create-user"
import { OrganizationTeam } from "../../models/Team"
import { pathNames } from "../../routes/paths"
import { styles } from "../../styles/common"
import { createErrorMessage } from "../../utils/format"
import { RoleEnum } from "./roles"

type TRoleOption = {
	id: RoleEnum
	label: string
}
export const CreateUser = () => {
	const { t } = useTranslation()

	const [loading, setLoading] = useState<boolean>(false)
	const [organizations, setOrganizations] = useState<MultiSelectData["data"]>([])
	const [dialog, setDialog] = useState<boolean>(false)
	const [dialogTitle, setDialogTitle] = useState<any>("")
	const [dialogDescription, setDialogDescription] = useState<any>("")
	const [isError, setIsError] = useState<boolean>(false)

	const [teams, setTeams] = useState<OrganizationTeam[]>([])

	const { isAdmin } = useAuthentication()

	const roles = Object.values(RoleEnum)
		.filter((role) => isAdmin || (!isAdmin && role !== RoleEnum.Admin))
		.map((role) => ({
			id: role,
			label: t(`roles.${role}`),
		})) as TRoleOption[]

	const navigate = useNavigate()

	const methods = useForm<CreateUserData>({
		resolver: zodResolver(createUserSchema),
	})
	const {
		control,
		formState: { errors },
		handleSubmit,
		watch,
	} = methods

	useEffect(() => {
		getOrganizations()
		if (isAdmin) getTeams()
	}, [])

	const getOrganizations = async () => {
		if (!isAdmin) return
		try {
			const {
				data: { data },
			} = await listOptionsOrganizations()
			setOrganizations(data)
		} catch (error) {
		} finally {
		}
	}

	const getTeams = async () => {
		try {
			const {
				data: { data },
			} = await listAllTeams({ pageSize: 20, currentPage: 1, filter: "" })
			setTeams(data?.list)
		} catch (error) {
		} finally {
		}
	}

	const getTeamsByOrganization = async (organizationId: string) => {
		try {
			const {
				data: { data },
			} = await searchTeamByOrganization(organizationId)
			setTeams(data)
		} catch (error) {}
	}

	const handleAccept = () => {
		setDialog(false)
		if (!isError) {
			navigate(pathNames.users.user.list)
			methods.reset()
		}
	}

	const watchedOrganizationId = watch("organizationId")

	useEffect(() => {
		if (watchedOrganizationId) {
			setTeams([])
			getTeamsByOrganization(watchedOrganizationId)
		}
	}, [watchedOrganizationId])

	const handleCreateUserSubmit = async (data: CreateUserData) => {
		setLoading(true)
		setIsError(false)
		try {
			await createUser(data)
			setDialogTitle(t("request_messages.create_success_title", { name: "Usuário" }))
			setDialogDescription(t("request_messages.success_description", { name: "usuário" }))
		} catch (error: any) {
			setIsError(true)
			setDialogTitle(t("request_messages.500"))
			if (error?.response?.data?.messages) {
				setDialogDescription(createErrorMessage(error?.response?.data?.messages))
			} else {
				setDialogDescription(t("request_messages.400"))
			}
		} finally {
			setDialog(true)
			setLoading(false)
		}
	}

	if (loading) return <Loading />

	return (
		<FormContainerPage onSubmit={handleSubmit(handleCreateUserSubmit)}>
			<HeaderPage
				onClickTopButton={() => navigate(pathNames.users.user.list)}
				description={t("customer.create_user_common_description")}
				isEdit={true}
				title={t("customer.create_user_common_title")}
			/>
			<BodyPage>
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					<Grid item xs={8}>
						<ControlledTextField
							id={"name"}
							style={styles.fullWidth}
							label={t("customer.label_name")}
							control={control}
							rules={{ required: true }}
							errors={errors.name}
							helperText={errors.name?.message}
						/>
					</Grid>
					<Grid item xs={4}>
						<Controller
							control={control}
							name={"role"}
							render={({ field: { onChange, value } }) => (
								<Autocomplete
									disablePortal
									size={"small"}
									options={roles}
									value={roles.find((role) => (role?.id as unknown as RoleEnum) === value)}
									getOptionLabel={(option) => option.label || ""}
									onChange={(_, option) => onChange(option?.id)}
									isOptionEqualToValue={(option, value) => option?.id === value?.id}
									renderInput={(params) => (
										<TextField
											{...params}
											variant={"filled"}
											color={"secondary"}
											label={t("columns.role")}
											error={!!errors.role}
											helperText={errors.role?.message}
										/>
									)}
								/>
							)}
						/>
					</Grid>

					<Grid item xs={6}>
						<ControlledTextField
							id={"email"}
							style={styles.textFieldMarginTop}
							label={t("customer.label_email")}
							control={control}
							rules={{ required: true }}
							errors={errors.email}
							helperText={errors.email?.message}
						/>
					</Grid>
					<Grid item xs={6}>
						<ControlledTextField
							id={"phone"}
							style={styles.textFieldMarginTop}
							label={t("customer.label_phone")}
							control={control}
							rules={{ required: true }}
							mask={"(99) 99999-9999"}
							errors={errors.phone}
							helperText={errors.phone?.message}
						/>
					</Grid>

					{isAdmin && (
						<Grid item xs={12}>
							{organizations.length > 0 && (
								<Controller
									control={control}
									name={"organizationId"}
									render={({ field: { onChange } }) => (
										<Autocomplete
											disablePortal
											size={"small"}
											options={organizations}
											getOptionLabel={(option: any) => option.name || ""}
											onChange={(event, option) => onChange(option?.id || null)}
											renderInput={(params) => (
												<TextField
													{...params}
													variant={"filled"}
													color={"secondary"}
													label={t("customer.label_organization")}
													error={!!errors.organizationId}
													helperText={errors.organizationId?.message}
												/>
											)}
										/>
									)}
								/>
							)}
						</Grid>
					)}

					{watchedOrganizationId && (
						<Grid item xs={12}>
							<Controller
								control={control}
								name={"teamId"}
								render={({ field: { onChange } }) => (
									<Autocomplete
										disablePortal
										size={"small"}
										disabled={!teams.length}
										options={teams}
										getOptionLabel={(option: any) => option.name || ""}
										onChange={(event, option) => onChange(option?.id || null)}
										renderInput={(params) => (
											<TextField
												{...params}
												variant={"filled"}
												color={"secondary"}
												label={t("customer.label_team")}
												error={!!errors.teamId || !teams.length}
												helperText={
													!teams.length ? t("user.label_team_empty") : errors.teamId?.message
												}
											/>
										)}
									/>
								)}
							/>
						</Grid>
					)}
				</Grid>
				<Grid item xs={12} style={{ marginTop: 16 }}>
					<Alert severity={"info"} title="Importante">
						O usuário cadastrado irá receber um e-mail na sua caixa de entrada com o código de
						confirmação e o link para resetar a senha.
					</Alert>
				</Grid>
			</BodyPage>
			<DialogAlert
				props={!isError ? <DialogContent /> : null}
				onlyConfirm={true}
				show={dialog}
				accept={handleAccept}
				title={dialogTitle}
				description={dialogDescription}
			/>
		</FormContainerPage>
	)
}

const DialogContent = () => {
	const { t } = useTranslation()

	return (
		<>
			<br />
			<DialogContentText>
				<b>{t("modal.description_email_steps_1")}</b>
			</DialogContentText>
			<DialogContentText>
				<b>{t("modal.description_email_steps_2")}</b>
			</DialogContentText>
			<br />
			<DialogContentText>{t("modal.description_email_steps_3")}</DialogContentText>
			<img alt="email-example" width={400} src={require("../../assets/images/email-example.png")} />
		</>
	)
}
