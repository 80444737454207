import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { listAllProjects } from "../../apis/projects"
import { BodyPage, ContainerPage } from "../../components/container/container.styles"
import { Filter } from "../../components/filter/filter"
import { HeaderPage } from "../../components/header/page/header-page"
import { LoadingTable } from "../../components/loading/loading"
import { Table } from "../../components/table/table"
import { GlobalContext } from "../../contexts/global-provider"
import { Police } from "../../models/Police"
import { pathNames } from "../../routes/paths"

export const ListProject = () => {
	const [showFilter, setShowFilter] = useState<boolean>(false)
	const [active, setActive] = useState<any>("enable")

	const { userData } = useContext(GlobalContext)
	const [customerPolices, setCustomersPolices] = useState<Police>()

	const [rows, setRows] = useState<[]>([])
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [pageSize, setPageSize] = useState<number>(10)
	const [filter, setFilter] = useState<string>("")

	const [loading, setLoading] = useState<boolean>(true)

	const navigate = useNavigate()

	const { t } = useTranslation()

	const columns = [
		{
			label: t("columns.name"),
			id: "name",
		},
		{
			label: t("columns.description"),
			id: "description",
		},
		{
			label: t("menu.services"),
			id: "description",
		},
		{
			label: t("columns.status"),
			id: "active",
		},
	]

	const keys = ["name", "description", "servicesCount", "active"]

	useEffect(() => {
		listProjects()
		// eslint-disable-next-line
	}, [pageSize, currentPage, filter])

	const listProjects = async (status?: any) => {
		try {
			const {
				data: { data },
			} = await listAllProjects({ currentPage, pageSize, filter, admin: true, active: status })

			setRows(data?.list)
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	const selectActiveState = (state: string) => {
		setShowFilter(false)
		setActive(state)
		if (state === "all") {
			listProjects(null)
		} else if (state === "enable") {
			listProjects(true)
		} else if (state === "disable") {
			listProjects(false)
		}
	}

	return (
		<ContainerPage>
			<HeaderPage
				searchValue={filter}
				onSearch={(event) => setFilter(event?.target?.value)}
				isCreateButtonVisible={customerPolices?.create}
				title={t("menu.projects")}
				onOpenFilter={() => setShowFilter(true)}
				buttonTitle={t("project.button_title_create")}
				onClickTopButton={() => navigate(pathNames.project.create)}
			/>
			<BodyPage>
				{!loading ? (
					<Table
						page={currentPage - 1}
						rowsPerPage={pageSize}
						changeRowsPerPage={(value) => setPageSize(value)}
						changePage={(value) => setCurrentPage(value + 1)}
						onDelete={() => null}
						onEdit={(element: any) => navigate(pathNames.project.edit + element?.id)}
						columns={columns}
						rows={rows}
						keys={keys}
						isEditVisible={customerPolices?.update}
						isDeleteVisible={customerPolices?.delete}
						isReadVisible={customerPolices?.read}
					/>
				) : (
					<LoadingTable />
				)}
			</BodyPage>
			<Filter
				show={showFilter}
				apply={(e) => selectActiveState(e)}
				cancel={() => setShowFilter(false)}
				value={active}
			/>
		</ContainerPage>
	)
}
