import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { disableOrganizations, listAllOrganization } from "../../apis/organization"
import { BodyPage, ContainerPage } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { Filter } from "../../components/filter/filter"
import { HeaderPage } from "../../components/header/page/header-page"
import { LoadingTable } from "../../components/loading/loading"
import { Table } from "../../components/table/table"
import { Police } from "../../models/Police"
import { pathNames } from "../../routes/paths"
import { AuthStorage } from "../../storage/auth-type"
import { getItem } from "../../storage/local-storage"

export const ListOrganization = () => {
	const [showFilter, setShowFilter] = useState<boolean>(false)
	const [active, setActive] = useState<any>("enable")

	const [showDialogConfirmation, setShowDialogConfirmation] = useState<boolean>(false)
	const [selectedItems, setSelectedItems] = useState<any>()

	const [organizationPolice, setOrganizationPolices] = useState<Police>()

	const [rows, setRows] = useState([])

	const [currentPage, setCurrentPage] = useState<number>(1)
	const [pageSize, setPageSize] = useState<number>(10)
	const [filter, setFilter] = useState<string>("")
	const [pageCount, setPageCount] = useState<number>(0)

	const [loading, setLoading] = useState<boolean>(true)

	const navigate = useNavigate()

	const { t } = useTranslation()

	const columns = [
		{
			label: t("columns.logo"),
			id: "imageUrl",
		},
		{
			label: t("columns.organization"),
			id: "name",
		},
		{
			label: t("columns.cnpj"),
			id: "cnpj",
		},
		{
			label: t("columns.email"),
			id: "billingEmail",
		},
		{
			label: t("columns.teams"),
			id: "teamsCount",
		},
		{
			label: t("columns.members"),
			id: "membersCount",
		},
		{
			label: t("menu.services"),
			id: "servicesCount",
		},
		{
			label: t("columns.status"),
			id: "active",
		},
	]

	const keys = [
		"imageUrl",
		"name",
		"cnpj",
		"billingEmail",
		"teamsCount",
		"membersCount",
		"servicesCount",
		"active",
	]

	useEffect(() => {
		const { organizations } = getItem(AuthStorage.userPolicy)
		setOrganizationPolices(organizations)
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		listOrganizations()
		// eslint-disable-next-line
	}, [pageSize, currentPage, filter])

	const listOrganizations = useCallback(
		async (status?: any) => {
			setLoading(true)
			try {
				const {
					data: { data },
				} = await listAllOrganization({ currentPage, pageSize, filter, active: status })
				setRows(data?.list)
				setPageCount(data?.total)
			} catch (error) {
				console.log(error)
			} finally {
				setLoading(false)
			}
		},
		[currentPage, pageSize, filter, active],
	)

	const selectActiveState = (state: string) => {
		setShowFilter(false)
		setActive(state)
		if (state === "all") {
			listOrganizations(null)
		} else if (state === "enable") {
			listOrganizations(true)
		} else if (state === "disable") {
			listOrganizations(false)
		}
	}

	const handleSelectedDisableItems = (items: any) => {
		setSelectedItems(items)
		setShowDialogConfirmation(true)
	}

	const handleDisableAllItems = async () => {
		setShowDialogConfirmation(false)
		setLoading(true)
		try {
			await disableOrganizations(selectedItems)
		} catch (error) {
		} finally {
			setLoading(false)
			listOrganizations()
		}
	}

	return (
		<ContainerPage>
			<HeaderPage
				isCreateButtonVisible={organizationPolice?.create}
				title={t("menu.organizations")}
				buttonTitle={t("organizations.button_title_create")}
				onSearch={(event) => setFilter(event?.target?.value)}
				searchValue={filter}
				onOpenFilter={() => setShowFilter(true)}
				onClickTopButton={() => navigate(pathNames.organization.create)}
			/>
			<BodyPage>
				{!loading ? (
					<Table
						page={currentPage - 1}
						rowsPerPage={pageSize}
						pageCount={pageCount}
						changeRowsPerPage={(value) => setPageSize(value)}
						changePage={(value) => setCurrentPage(value + 1)}
						onDelete={() => null}
						onEdit={(element: any) => navigate(pathNames.organization.edit + element?.id)}
						columns={columns}
						rows={rows}
						keys={keys}
						isEditVisible={organizationPolice?.update}
						isDeleteVisible={organizationPolice?.delete}
						isReadVisible={organizationPolice?.read}
						isSelectable={true}
						onDisableAll={handleSelectedDisableItems}
					/>
				) : (
					<LoadingTable />
				)}
			</BodyPage>
			<Filter
				show={showFilter}
				apply={(e) => selectActiveState(e)}
				cancel={() => setShowFilter(false)}
				value={active}
			/>
			<DialogAlert
				show={showDialogConfirmation}
				description={t("table.modal_disable_label_description")}
				title={t("table.disable")}
				accept={() => handleDisableAllItems()}
				decline={() => setShowDialogConfirmation(false)}
			/>
		</ContainerPage>
	)
}
