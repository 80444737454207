import { useMemo, useState } from "react"
import { Trans, useTranslation } from "react-i18next"

import {
	Article,
	CheckCircle,
	Error,
	Inventory,
	Loop,
	ManageSearch,
	Pending,
} from "@mui/icons-material"
import { TextField, Typography } from "@mui/material"
import { Container } from "@mui/system"

import { InvoicingData, deleteBatchFromInvoice } from "../../../../apis/invoicing"
import DialogAlert from "../../../../components/dialog/dialog"
import { PageLoading } from "../../../../components/loading/page-loading"
import { Table } from "../../../../components/table/table"
import { ServiceBatch } from "../../../../models/Service"
import { styles } from "../../../../styles/common"
import { handleApiExceptions } from "../../../../utils/handle-errors"
import { BatchFileType, downloadFile } from "../../../service/batch/download-file-trigger"
import { tablePrintStyles } from "../invoicing-details.styles"

interface InvoicingMonthlyBatchesProps {
	invoicing: InvoicingData | null
	refreshInvoicingData: () => void
	isPrinting?: boolean
}

export function InvoicingMonthlyBatches({
	invoicing,
	refreshInvoicingData,
	isPrinting,
}: InvoicingMonthlyBatchesProps) {
	const { t } = useTranslation()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [justification, setJustification] = useState("")

	const [paginationQueries, setPaginationQueries] = useState({
		rowsPerPage: 5,
		pageNumber: 1,
	})

	const [batchDelete, setBatchDelete] = useState<ServiceBatch | null>(null)

	function formatNumber(number: number): string {
		return number.toLocaleString("pt-BR")
	}

	function getHitRate(totalAmount: number, totalSuccess: number) {
		return totalAmount > 0 ? ((totalSuccess / totalAmount) * 100).toFixed(1) + "%" : "0.0%"
	}

	const rows = useMemo(() => {
		const status = {
			received: {
				color: "default",
				icon: <Inventory />,
			},
			analyzing: {
				color: "warning",
				icon: <ManageSearch />,
			},
			pending: {
				color: "warning",
				icon: <Pending />,
			},
			in_progress: {
				color: "info",
				icon: <Loop />,
			},
			finished: {
				color: "success",
				icon: <CheckCircle />,
			},
			aborted: {
				color: "error",
				icon: <Error />,
			},
			generating_file: {
				color: "info",
				icon: <Article />,
			},
		}

		return invoicing?.batches
			?.slice(
				(paginationQueries.pageNumber - 1) * paginationQueries.rowsPerPage,
				paginationQueries.pageNumber * paginationQueries.rowsPerPage,
			)
			.map((item: any) => ({
				...item,
				user: item?.createdByUser?.email,
				service: item?.service?.name,
				totalAmount: formatNumber(item?.totalAmount),
				totalSuccess: formatNumber(item?.totalSuccess),
				totalFailed: formatNumber(item?.totalFailed),
				organization: item?.organization?.name,
				organizationId: item?.organization?.id,
				status: t("service_query.status." + item?.status),
				statusPillColor: status[item?.status as keyof typeof status]?.color,
				hit_rate: getHitRate(item?.totalAmount, item?.totalSuccess),
				statusPillIcon: status[item?.status as keyof typeof status]?.icon,
				statusTooltip: item?.observation,
				originalFileName: item?.originalFileName.replace(/^\d+_/, ""),
				originalFileUrl: item.originalFileUrl
					? `${process.env.REACT_APP_SERVICE_URL}/files/download/serviceBatch/${item.id}/original`
					: undefined,
				resultFileUrl: item.resultFileUrl
					? `${process.env.REACT_APP_SERVICE_URL}/files/download/serviceBatch/${item.id}/result`
					: undefined,
			}))
	}, [paginationQueries, invoicing, t])

	const handleChangeRowsPerPage = (rowsPerPage: number) =>
		setPaginationQueries((prevState) => ({ ...prevState, rowsPerPage }))

	const handleChangePage = (crrPage: number) => {
		setPaginationQueries((prevState) => ({ ...prevState, pageNumber: crrPage + 1 }))
	}

	const columns = useMemo(
		() => [
			{
				label: t("columns.posting_date"),
				id: "createdAt",
			},
			{
				label: t("columns.service"),
				id: "service",
			},
			{
				label: t("columns.file"),
				id: "originalFileName",
			},
			{
				label: t("columns.user"),
				id: "user",
			},
			{
				label: t("columns.queries_amount"),
				id: "totalAmount",
			},
			{
				label: t("columns.valid_queries_amount"),
				id: "totalSuccess",
			},
			{
				label: t("columns.invalid_queries_amount"),
				id: "totalFailed",
			},
		],
		[rows],
	)

	const keys = useMemo(
		() => [
			{ isDate: true, value: "createdAt", id: "createdAt" },
			"service",
			{
				isDownload: true,
				value: "originalFileUrl",
				id: "originalFileName",
				onClick: downloadFile(BatchFileType.ORIGINAL),
			},
			"user",
			"totalAmount",
			"totalSuccess",
			"totalFailed",
		],
		[rows, t],
	)

	function handleBatchDelete(batch: ServiceBatch) {
		setBatchDelete(batch)
	}

	async function tryDeleteBatch() {
		if (!batchDelete || !invoicing?.id) return

		const payload = {
			batchId: batchDelete?.id,
			serviceId: batchDelete?.serviceId,
			justification,
		}

		await deleteBatchFromInvoice(invoicing.id, payload)
	}

	async function onSubmitDelete() {
		setIsLoading(true)
		try {
			await tryDeleteBatch()
			refreshInvoicingData()
			setBatchDelete(null)
			setJustification("")
		} catch (error) {
			handleApiExceptions(error)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<>
			<Table
				rowsPerPageOptions={[5, 10, 25]}
				rowsPerPage={isPrinting ? 100 : paginationQueries.rowsPerPage}
				changeRowsPerPage={handleChangeRowsPerPage}
				page={paginationQueries.pageNumber - 1}
				changePage={handleChangePage}
				onDelete={handleBatchDelete}
				columns={columns}
				keys={keys}
				rows={rows as []}
				isDeleteVisible={invoicing?.status === "INVOICED" && !isPrinting}
				onEdit={() => null}
				isEditVisible={false}
				pageCount={invoicing?.batches?.length}
				titleStyles={isPrinting ? tablePrintStyles : {}}
				cellStyles={isPrinting ? tablePrintStyles : {}}
				tableContainerStyles={isPrinting ? { overflowX: "initial" } : {}}
				hidePagination={isPrinting}
			/>

			<PageLoading isLoading={isLoading} />

			<DialogAlert
				accept={onSubmitDelete}
				title={t("consumption.invoicing.consumptionDetailsByBatch.deletionTitle")}
				decline={() => setBatchDelete(null)}
				description=""
				show={!!batchDelete && !isLoading}
				yesDisabled={isLoading || !justification}
			>
				<Container>
					<Typography mb={2}>
						<Trans
							i18nKey="consumption.invoicing.consumptionDetailsByBatch.deletionConfirmation"
							values={{ batch: batchDelete?.originalFileName }}
						/>
					</Typography>

					<TextField
						color="secondary"
						size="small"
						id="filled-basic"
						variant="filled"
						label={t("consumption.invoicing.dialogEdit.fields.justification")}
						style={styles.fullWidth}
						value={justification}
						rows={4}
						multiline
						onChange={(e) => setJustification(e.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
						placeholder={t("consumption.invoicing.dialogEdit.placeholder.justification") as string}
					/>
				</Container>
			</DialogAlert>
		</>
	)
}
