import { useEffect, useMemo, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { Divider, FormControlLabel, FormGroup, Grid, Switch, Typography } from "@mui/material"

import { searchSpecificTeam, updateTeam } from "../../apis/team"
import { BodyPage, ContainerPage } from "../../components/container/container.styles"
import { DialogHistoric } from "../../components/dialog-historic/dialog-historic"
import DialogAlert from "../../components/dialog/dialog"
import { NotFound } from "../../components/erros/not-found"
import { HeaderPage } from "../../components/header/page/header-page"
import { Loading } from "../../components/loading/loading"
import { ProductSelect } from "../../components/product-select/product-select"
import { Table } from "../../components/table/table"
import { ControlledTextField } from "../../components/text-field/controlled-text-field"
import { pathNames } from "../../routes/paths"
import { styles } from "../../styles/common"
import { createErrorMessage } from "../../utils/format"

export const EditTeam = () => {
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<boolean>(false)
	const [showDialogHistoric, setShowDialogHistoric] = useState<boolean>(false)

	const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
	const [active, setActive] = useState<boolean>()

	const [dialog, setDialog] = useState<boolean>(false)
	const [dialogTitle, setDialogTitle] = useState<any>("")
	const [dialogDescription, setDialogDescription] = useState<any>("")

	const [products, setProducts] = useState<any>([])
	const [organizationId, setOrganizationId] = useState("")

	const [teammates, setTeammates] = useState<any>([])

	const [isError, setIsError] = useState<boolean>(false)

	const [paginationQueries, setPaginationQueries] = useState({
		rowsPerPage: 5,
		pageNumber: 1,
	})

	const navigate = useNavigate()

	const { t } = useTranslation()

	const methods = useForm()
	const {
		control,
		getValues,
		reset,
		formState: { isValid },
	} = methods

	const { teamId } = useParams()

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line
	}, [])

	const fetchData = async () => {
		setLoading(true)
		try {
			const {
				data: { data },
			} = await searchSpecificTeam(teamId)
			reset(data)
			setOrganizationId(data?.organization?.id)
			setProducts(data?.services)
			setTeammates(data?.users)
			setActive(data?.active)
		} catch (error: any) {
			setError(true)
		} finally {
			setLoading(false)
		}
	}

	const handleSubmit = async () => {
		const formValues = getValues()
		setIsError(true)

		if (isValid) {
			const objTeam = {
				...formValues,
				services: products,
				active,
			} as any // AJUSTE AS ANY PARA DELETAR ORGANIZATION DO CONTEXTO
			setLoading(true)
			try {
				delete objTeam.organization

				await updateTeam(objTeam)
				setDialogTitle(t("request_messages.edit_success_title", { name: "Time" }))
				setDialogDescription(t("request_messages.edit_success_description", { name: "Time" }))
				setIsError(false)
			} catch (error: any) {
				setDialogTitle(t("request_messages.500"))
				if (error?.response?.data?.messages) {
					setDialogDescription(createErrorMessage(error?.response?.data?.messages))
				} else {
					setDialogDescription(t("request_messages.400"))
				}
			} finally {
				setIsError(true)
				setDialog(true)
				setLoading(false)
			}
		}
	}

	const handleAddProduct = (product: any) => {
		setProducts([...products, product])
	}

	const handleDeleteProduct = (product: any) => {
		const objectProducts = getValues("services").map((item: any) => {
			if (item.serviceId === product.serviceId) item.deleted = true
			return item
		})
		setProducts(objectProducts)
	}

	const handleAccept = () => {
		setDialog(false)
		if (!isError) {
			navigate(pathNames.team.list)
		}
	}

	const handleUpdateValue = (data: any) => {
		setProducts(data)
	}

	const handleChangeRowsPerPage = (rowsPerPage: number) =>
		setPaginationQueries((prevState) => ({ ...prevState, rowsPerPage }))

	const handleChangePage = (crrPage: number) => {
		setPaginationQueries((prevState) => ({ ...prevState, pageNumber: crrPage + 1 }))
	}

	const columns = [
		{
			label: t("columns.name"),
			id: "id",
		},
		{
			label: t("columns.email"),
			id: "email",
		},
		{
			label: t("columns.status"),
			id: "active",
		},
	]

	const keys = [
		{
			isLink: true,
			value: "name",
			path: pathNames.users.user.edit,
			id: "id",
			backPath: pathNames.team.edit + teamId,
		},
		"email",
		"active",
	]

	const rows = useMemo(
		() =>
			teammates?.slice(
				(paginationQueries.pageNumber - 1) * paginationQueries.rowsPerPage,
				paginationQueries.pageNumber * paginationQueries.rowsPerPage,
			),
		[teammates, paginationQueries],
	)

	if (loading) return <Loading />

	return (
		<ContainerPage>
			<HeaderPage
				onClickSubmit={handleSubmit}
				disabledButton={!isValid}
				onClickTopButton={() => navigate(-1)}
				description={t("team.description_edit_team")}
				isEdit={true}
				title={t("team.title_edit_team")}
			/>
			<BodyPage>
				{!error ? (
					<FormProvider {...methods}>
						<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
							<Grid item xs={4}>
								<ControlledTextField
									id={"name"}
									style={{ width: "100%" }}
									label={t("team.label_team")}
									control={control}
									rules={{ required: true }}
								/>
							</Grid>
							<Grid item xs={6}>
								<ControlledTextField
									id={"description"}
									style={{ width: "100%" }}
									label={t("team.label_description")}
									control={control}
									rules={{ required: true }}
								/>
							</Grid>
							<Grid style={styles.active} item xs={2}>
								<FormGroup>
									<FormControlLabel
										control={
											<Switch
												onChange={(e) =>
													!e.target.checked
														? setShowDeleteDialog(true)
														: setActive(e.target.checked)
												}
												color={"secondary"}
												checked={active}
											/>
										}
										label={
											<Typography color={"secondary"}>
												{active ? t("status.active") : t("status.inactive")}
											</Typography>
										}
									/>
								</FormGroup>
							</Grid>
							<Grid style={styles.textFieldMarginTop} item xs={12}>
								<ControlledTextField
									id={"organization.name"}
									style={{ width: "100%" }}
									label={t("profile.organization")}
									control={control}
									readOnly={true}
									rules={{ required: true }}
								/>
							</Grid>
							<Grid item xs={12}>
								<Divider style={styles.divider} />
							</Grid>
						</Grid>

						<ProductSelect
							handleUpdateValue={handleUpdateValue}
							organizationId={organizationId}
							onClickHistoric={() => setShowDialogHistoric(true)}
							isHistoricVisible={true}
							dataProducts={products}
							handleAddProduct={handleAddProduct}
							handleDeleteProduct={handleDeleteProduct}
						/>

						<Grid item xs={12} mt={3}>
							<Divider style={styles.divider} />
						</Grid>

						<Grid item xs={12} pb={4}>
							<Typography variant="h5" color={"secondary"} mb={2}>
								Membros do time
							</Typography>
							<Table
								rows={rows}
								columns={columns}
								keys={keys}
								isEditVisible={false}
								rowsPerPage={paginationQueries.rowsPerPage}
								rowsPerPageOptions={[5, 10, 15]}
								page={paginationQueries.pageNumber - 1}
								pageCount={teammates?.length}
								changePage={handleChangePage}
								changeRowsPerPage={handleChangeRowsPerPage}
							/>
						</Grid>
					</FormProvider>
				) : (
					<NotFound />
				)}
			</BodyPage>
			<DialogAlert
				title={t("team.disable_message_title")}
				description={t("team.disable_message_description", {
					team: getValues("name"),
				})}
				show={showDeleteDialog}
				accept={() => {
					setActive(false)
					setShowDeleteDialog(false)
				}}
				decline={() => {
					setShowDeleteDialog(false)
					setActive(true)
				}}
			/>
			<DialogAlert
				onlyConfirm={true}
				show={dialog}
				accept={handleAccept}
				title={dialogTitle}
				description={dialogDescription}
			/>
			<DialogHistoric
				id={teamId}
				handleClose={() => setShowDialogHistoric(false)}
				open={showDialogHistoric}
			/>
		</ContainerPage>
	)
}
